import { useEffect, useState } from "react";
import PrimaryTable from "../library/Table/PrimaryTable/PrimaryTable";
import style from "./MyActivities.module.scss";
import Paginator from "../library/Paginator/Paginator";
import { Field } from "@/components/Section/redux/section.models";
import { useTableConfigHook } from "./useTableConfigHook";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectLoading,
  selectMassiveActionDocuments,
  selectPaginator,
  selectSectionDocuments,
} from "@/components/Section/redux/section.selector";
import Spinner from "../library/Spinner/Spinner";
import {
  setDocuments,
  setHasAttachmentsPermission,
  setMassiveActionDocuments,
  setPaginator,
} from "./redux/section.slice";
import {
  // setLanguageLoaded,
  setShowPanelDetails,
} from "@/app/redux/common.slice";
import { selectShowPanelDetails } from "@/app/redux/common.selector";
import { PAGE_SIZE } from "./redux/section.constants";
import { AuthGroupUsersDialog } from "./AuthGroupUsersDialog";

export interface MyActivitiesProps {
  configuration: Field[];
  sectionId: string;
}

const MyActivities = ({ configuration, sectionId }: MyActivitiesProps) => {
  const dispatch = useAppDispatch();

  const [showAuthGroupUsersDialog, setShowAuthGroupUsersDialog] =
    useState<boolean>(false);
  const [authGroupData, setAuthGroupData] = useState<any>({});

  const { columns, lazyState, onRowClick, onSort, onPageChange } =
    useTableConfigHook(
      configuration,
      sectionId,
      true,
      setShowAuthGroupUsersDialog,
      setAuthGroupData
    );
  const data = useAppSelector(selectSectionDocuments);
  const loading = useAppSelector(selectLoading);
  const paginator = useAppSelector(selectPaginator);
  const massiveActionDocuments = useAppSelector(selectMassiveActionDocuments);
  const showPanelDetails = useAppSelector(selectShowPanelDetails);

  const onRowClickHandler = (e: any) => {
    const newData = [...data];
    dispatch(
      setDocuments(
        newData.map((d) => ({
          ...d,
          selected: d.id === e.data.id,
        }))
      )
    );
    onRowClick(e);
  };

  useEffect(() => {
    dispatch(setHasAttachmentsPermission(true));
    return () => {
      dispatch(setHasAttachmentsPermission(false));
      dispatch(setShowPanelDetails(false));
      // dispatch(setLanguageLoaded(true));
      dispatch(
        setPaginator({
          page: 1,
          pageCount: 0,
          total: 0,
          reload: false,
          first: 1,
          size: PAGE_SIZE,
        })
      );
    };
  }, [dispatch]);

  return (
    <div className={style.container}>
      {loading && <Spinner />}
      {!loading &&
        ((!showPanelDetails && window.innerWidth <= 480) ||
          window.innerWidth > 480) && (
          <PrimaryTable
            columns={columns}
            data={data}
            tableProps={{
              selectionMode: "checkbox" as any,
              onSelectionChange: (e: any) => {
                e.originalEvent.preventDefault();
                e.originalEvent.stopPropagation();
                if (e.value.length < 10) dispatch(setMassiveActionDocuments(e.value));
              },
              selection: massiveActionDocuments,
              onRowClick: onRowClickHandler,
              onSort,
              rowClassName: (rowData: any) => {
                if (rowData.selected) return "custom-highlight";
              },
              sortField: lazyState.sortField,
              sortOrder: lazyState.sortOrder,
            }}
          />
        )}
      {!loading && (
        <Paginator
          first={paginator.first === 1 ? 0 : paginator.first}
          rows={paginator.size || 25}
          totalRecords={paginator.total}
          onPageChange={onPageChange}
        />
      )}
      {showAuthGroupUsersDialog && (
        <AuthGroupUsersDialog
          isVisible={showAuthGroupUsersDialog}
          onHide={() => setShowAuthGroupUsersDialog(false)}
          data={authGroupData}
        />
      )}
    </div>
  );
};

export default MyActivities;
