import {
  Dropdown as PRDropdown,
  DropdownChangeEvent,
  DropdownProps as PRDropdownProps,
} from "primereact/dropdown";
import InputLabel from "../Input/InputLabel";
import style from "./Dropdown.module.scss";

export interface DropdownProps extends PRDropdownProps {
  locale: {
    label?: string;
    placeholder: string;
  };
  className?: string;
  onChangeCallback?: (value: any) => void;
}

const Dropdown = ({ locale, className, onChangeCallback, ...props }: DropdownProps) => {
  const onChangeHandler = ({ value }: DropdownChangeEvent) => {
    onChangeCallback && onChangeCallback(value);
  };
  return (
    <div className={style.container}>
      {locale?.label && (
        <InputLabel locale={locale} required={props.required} />
      )}
      <PRDropdown
        className={`${style.wrapper} ${className}`}
        onChange={onChangeHandler}
        panelClassName={style.panelWrapper}
        placeholder={locale.placeholder}
        {...props}
      />
    </div>
  );
};

export default Dropdown;
