import style from "./SecondaryTable.module.scss";
import Table, { TableProps } from "../Table";
import { DataTableProps } from "primereact/datatable";

export interface SecondaryTableProps extends TableProps {
  tableProps?: DataTableProps<any>;
  customClass?: string;
}

const SecondaryTable = ({
  columns,
  data,
  tableProps,
  customClass,
  columnExpanderBody,
}: SecondaryTableProps) => {
  return (
    <Table
      columns={columns}
      data={data}
      columnExpanderBody={columnExpanderBody}
      customProps={{ ...tableProps, className: `${style.wrapper} ${customClass || ""}` }}
    />
  );
};

export default SecondaryTable;
