import { PropsWithChildren, useEffect, useState } from "react";
import style from "./Accordion.module.scss";

export interface AccordionProps extends PropsWithChildren {
  title: string;
  ctaLabel?: string;
  iconButton?: string;
  isOpen?: boolean;
  onCta?: () => void;
  onIconButtonClick?: (e: any) => void;
}

const Accordion = ({
  title,
  ctaLabel,
  iconButton,
  isOpen,
  children,
  onCta,
  onIconButtonClick,
}: AccordionProps) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  const onToggle = () => {
    setShowContent((value) => !value);
  };

  const onCtaHandler = (e: any) => {
    e.stopPropagation();
    onCta && onCta();
  };

  const showIconButton = () => {
    return (
      <>
        <div className={style.iconButtonTitleContainer}>
          <span className={style.title}>{title}</span>
          <div
            role="button"
            onClick={onIconButtonClick}
            className={style.iconButtonContainer}
          >
            <i className={`pi pi-${iconButton}`} />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    setShowContent(isOpen ?? false)
  }, [isOpen])

  return (
    <div className={style.container}>
      <div className={style.header} onClick={onToggle}>
        {onIconButtonClick ? (
          showIconButton()
        ) : (
          <span className={style.title}>{title}</span>
        )}

        <span className={style.headerRight}>
          {onCta && (
            <span className={style.cta} onClick={onCtaHandler}>
              {ctaLabel}
            </span>
          )}
          <i className={`pi pi-${showContent ? "angle-up" : "angle-down"}`} />
        </span>
      </div>
      <div className={`${style.content} ${!showContent ? style.hide : ""}`}>
        <div className={style.children}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
