import http from "@/utils/http";
import { ManualsTree } from "./manuals.models";

export async function getManualsList(companyId: string) {
  const response = await http.get<ManualsTree>(
    "/document-manager-api/user/profile/manuals?companyId=" + companyId
  );
  return response.data;
}

export async function downloadManual(
  params: Record<string, string>
): Promise<any> {
  const response = await http.get<any>(
    `/document-manager-api/user/profile/manual/${params.manualId}?companyId=${params.companyId}&sectionId=${params.sectionId}`,
    { responseType: "blob" }
  );

  return response.data;
}
