import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import style from "./InitializationFormDialog.module.scss";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectInitializationFormList } from "./redux/section.selector";
import Dropdown from "../library/Dropdown/Dropdown";
import { useEffect, useState } from "react";
import { Field, FilterBody, FilterMap } from "./redux/section.models";
import Button from "../library/Button/Button";
import InputLabel from "../library/Input/InputLabel";
import { fetchCreateDocumentInitlThunk } from "./redux/section.thunk";
import { selectSelectedCompany } from "@/app/redux/common.selector";
import { useFilterComponentHook } from "./useFilterComponentHook";
import Spinner from "../library/Spinner/Spinner";
import { useTranslation } from "react-i18next";

export const InitializationFormDialog = (props: {
  isVisible: boolean;
  onHide: VoidFunction;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [warningHtml, setWarningHtml] = useState<string>("");
  const [process, setProcess] = useState<any>(null);
  const [valueMap, setValueMap] = useState<FilterMap>({});
  const [attachmentMap, setAttachmentMap] = useState<any>({});
  const formList = useAppSelector(selectInitializationFormList);
  const company = useAppSelector(selectSelectedCompany);

  const onValueChange = (filterBody: FilterBody) => {
    setValueMap((value: any) => ({
      ...value,
      [filterBody.field_column_name]: {
        ...value[filterBody.field_column_name],
        ...filterBody.value,
        operation: filterBody.operation,
        value: filterBody.value,
      },
    }));
  };

  const getValue = (field: Field) => {
    if (!valueMap[field.field_column_name]) {
      setValueMap((valueMap: FilterMap) => ({
        ...valueMap,
        [field.field_column_name]: {
          field_id: field.id,
          filter_id: field.field_detail_type?.id || "",
          filter_type: field.field_detail_type?.form_type || "",
          value: "",
          multiselect: [],
          from: "",
          to: "",
          options:
            field.field_detail_type?.data_value?.indexOf("ARRAY") > -1
              ? JSON.parse(
                  field.field_detail_type?.data_value
                    .split("ARRAY")[1]
                    .replace(/'/g, '"')
                ).map((o: string) => ({ label: o, code: o }))
              : [],
        },
      }));
    }
    return valueMap[field.field_column_name];
  };

  const { getFilterComponent } = useFilterComponentHook({
    valueMap,
    getValue,
    onValueChange,
    setValueMap,
    hideIcons: true,
  });

  const isFormValid = () => {
    const attachmentsValid = !process?.attachmentFolders.filter((a: any) => {
      return a.mandatory && attachmentMap[a.code]?.length !== a.multiplicity;
    }).length;
    const fieldsValid = !process?.fields.filter((f: any) => {
      return !valueMap[f.field_column_name]?.value;
    }).length;
    return attachmentsValid && fieldsValid;
  };

  const getBase64 = (file: File, code: string) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setAttachmentMap((am: any) => ({
        ...am,
        [code]: [
          ...(am[code] ?? []),
          {
            file: reader.result?.toString().split("base64,")[1],
            name: file.name,
          },
        ],
      }));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onSave = async (force?: boolean) => {
    setLoading(true);
    const body = {
      company_id: company?.companyId ?? "",
      process_id: process.id,
      fields: process.fields.reduce((acc: any[], curr: any) => {
        return {
          ...acc,
          [curr.field_column_name]: valueMap[curr.field_column_name].value,
        };
      }, {}),
      force: force ?? false,
      attachments: process.attachmentFolders?.reduce(
        (acc: any[], curr: any) => {
          return [
            ...acc,
            ...Array.from(attachmentMap[curr.code] ?? []).map((f: any) => ({
              id: curr.id,
              code: curr.code,
              name: f.name,
              file: f.file,
            })),
          ];
        },
        []
      ),
    };
    const response = await dispatch(fetchCreateDocumentInitlThunk(body));
    if (response.payload?.status === "WARNING") {
      setWarningHtml(response.payload?.message);
    } else {
      props.onHide();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formList.length === 1) setProcess(formList[0]);
  }, [formList]);

  return (
    <Dialog
      style={{ width: !!warningHtml ? "auto" : "70vw" }}
      className={style.container}
      header={
        !warningHtml ? t("UI_INIT_CREATE_DOCUMENT") : t("UI_INIT_WARNING")
      }
      headerClassName={style.header}
      visible={props.isVisible}
      onHide={() => {
        if (props.onHide) {
          props.onHide();
        }
      }}
      closeIcon={<i className="pi pi-times-circle" />}
      footer={
        <div
          className={`${style.customFooterContainer} ${
            !!warningHtml ? style.warning : ""
          }`}
        >
          {!warningHtml && (
            <Button
              label={t("UI_INIT_SAVE")}
              onClick={() => onSave()}
              disabled={!isFormValid()}
              isLoading={loading}
            />
          )}
          {!!warningHtml && (
            <>
              <Button
                label={t("UI_INIT_REJECT")}
                onClick={props.onHide}
                isLoading={loading}
                secondaryMode
              />
              <Button
                label={t("UI_INIT_CONFIRM")}
                onClick={onSave.bind(null, true)}
                isLoading={loading}
              />
            </>
          )}
        </div>
      }
    >
      <div className={`${style.content} ${!!warningHtml ? style.warning : ""}`}>
        {loading && (
          <div className={style.spinner}>
            <div className={style.spinner_content}>
              <Spinner />
            </div>
          </div>
        )}
        {!!warningHtml && (
          <div
            dangerouslySetInnerHTML={{
              __html: warningHtml,
            }}
          />
        )}
        {!warningHtml && (
          <>
            <div className={style.dropdownWrapper}>
              <Dropdown
                locale={{
                  label: t("UI_INIT_SELECT_PROCESS"),
                  placeholder: t("UI_FILTERS_PLACEHOLDER_SELECT"),
                }}
                options={formList}
                value={process}
                onChangeCallback={setProcess}
                optionLabel="name"
                // optionValue="id"
              />
            </div>
            <div className={style.fieldGrid}>
              {process?.fields.map((c: any) => (
                <div className={style.fieldWrapper} key={c.id}>
                  {getFilterComponent({
                    code: c.code,
                    label: c.label,
                    field_column_name: c.field_column_name,
                    filter: {
                      form_type: c.field_detail_type.form_type,
                      data_value: c.field_detail_type.data_value,
                    },
                  } as Field)}
                </div>
              ))}
            </div>
            <Divider />
            <div className={style.fieldGrid}>
              {process?.attachmentFolders.map((c: any) => (
                <div className={style.inputWrapper} key={c.id}>
                  <InputLabel locale={{ label: c.description }} />
                  <div style={{ marginTop: "8px" }}>
                    <label className={style.inputFile} htmlFor={"attachment_" + c.code}>
                      <i className="pi pi-upload" />
                      <span>
                        {attachmentMap[c.code]
                          ? attachmentMap[c.code]?.length > 1
                            ? attachmentMap[c.code]?.length +
                              " " +
                              t("UI_INIT_SELECTED_FILES")
                            : attachmentMap[c.code][0]?.name
                          : t("UI_INIT_SELECT_FILES")}
                      </span>
                    </label>
                  </div>
                  <input
                    id={"attachment_" + c.code}
                    style={{ visibility: "hidden" }}
                    type="file"
                    value={undefined}
                    onChange={(e) => {
                      Array.from(e.target?.files ?? []).forEach((f) =>
                        getBase64(f, c.code)
                      );
                    }}
                    multiple={true}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
