import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { Field } from "@/components/Section/redux/section.models";
import {
  selectLoading,
  selectPaginator,
  selectSectionDocuments,
} from "@/components/Section/redux/section.selector";
import Paginator from "../library/Paginator/Paginator";
import PrimaryTable from "../library/Table/PrimaryTable/PrimaryTable";
import { useTableConfigHook } from "./useTableConfigHook";
import style from "./MyDocuments.module.scss";
import Spinner from "../library/Spinner/Spinner";
import { useEffect, useState } from "react";
import { selectShowPanelDetails } from "@/app/redux/common.selector";
import {
  // setLanguageLoaded,
  setShowPanelDetails,
} from "@/app/redux/common.slice";
import { setPaginator } from "./redux/section.slice";
import { PAGE_SIZE } from "./redux/section.constants";
import { AuthGroupUsersDialog } from "./AuthGroupUsersDialog";

export interface MyDocumentsProps {
  configuration: Field[];
  sectionId: string;
}

const MyDocuments = ({ configuration, sectionId }: MyDocumentsProps) => {
  const dispatch = useAppDispatch();

  const [showAuthGroupUsersDialog, setShowAuthGroupUsersDialog] =
    useState<boolean>(false);
  const [authGroupData, setAuthGroupData] = useState<any>({});

  const { columns, lazyState, onRowClick, onSort, onPageChange } =
    useTableConfigHook(
      configuration,
      sectionId,
      false,
      setShowAuthGroupUsersDialog,
      setAuthGroupData
    );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const data = useAppSelector(selectSectionDocuments);
  const loading = useAppSelector(selectLoading);
  const paginator = useAppSelector(selectPaginator);
  const showPanelDetails = useAppSelector(selectShowPanelDetails);

  useEffect(() => {
    if (!showPanelDetails) setSelectedProduct(null);
  }, [showPanelDetails]);

  useEffect(() => {
    return () => {
      dispatch(setShowPanelDetails(false));
      // dispatch(setLanguageLoaded(true));
      dispatch(
        setPaginator({
          page: 1,
          pageCount: 0,
          total: 0,
          reload: false,
          first: 1,
          size: PAGE_SIZE,
        })
      );
    };
  }, [dispatch]);

  return (
    <div className={style.container}>
      {loading && <Spinner />}
      {!loading &&
        ((!showPanelDetails && window.innerWidth <= 480) ||
          window.innerWidth > 480) && (
          <div className={style.tableWrapper}>
            <PrimaryTable
              columns={columns}
              data={data}
              tableProps={{
                selectionMode: "single" as any,
                onSelectionChange: (e: any) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  setSelectedProduct(e.value);
                },
                onRowClick,
                selection: selectedProduct,
                onSort,
                sortField: lazyState.sortField,
                sortOrder: lazyState.sortOrder,
              }}
            />
          </div>
        )}
      {!loading && (
        <Paginator
          first={paginator.first === 1 ? 0 : paginator.first}
          rows={paginator.size || 25}
          totalRecords={paginator.total}
          onPageChange={onPageChange}
        />
      )}
      {showAuthGroupUsersDialog && (
        <AuthGroupUsersDialog
          isVisible={showAuthGroupUsersDialog}
          onHide={() => setShowAuthGroupUsersDialog(false)}
          data={authGroupData}
        />
      )}
    </div>
  );
};

export default MyDocuments;
