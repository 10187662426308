import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_SETTINGS_NAMESPACE } from "./user-settings.selector";
import {
  createUserSignature,
  getUserSettings,
  updateUserBlackList,
  updateUserSignature,
} from "./user-settings.api";
import {
  UserSettingsBlacklistBodyRequest,
  UserSettingsCreateSignatureBody,
  UserSettingsSignatureBody,
} from "./user-settings.models";

export const USER_SETTINGS_ACTIONS = {
  FETCH_GET_USER_SETTINGS: "fetchGetUserSettings",
  FETCH_UPDATE_USER_SIGNATURE: "fetchUpdateUserSignature",
  FETCH_CREATE_USER_SIGNATURE: "fetchCreateUserSignature",
  FETCH_UPDATE_USER_BLACKLIST: "fetchUpdateUserBlackList",
};

export const fetchGetUserSettingsThunk = createAsyncThunk(
  `${USER_SETTINGS_NAMESPACE}/${USER_SETTINGS_ACTIONS.FETCH_GET_USER_SETTINGS}`,
  async (companyId: string) => {
    const response: any = await getUserSettings(companyId);
    return response;
  }
);

export const fetchUpdateUserSignatureThunk = createAsyncThunk(
  `${USER_SETTINGS_NAMESPACE}/${USER_SETTINGS_ACTIONS.FETCH_UPDATE_USER_SIGNATURE}`,
  async (params: UserSettingsSignatureBody, {dispatch}) => {
    const response: any = await updateUserSignature(params);
    dispatch(fetchGetUserSettingsThunk(params.companyId));
    return response;
  }
);
export const fetchCreateUserSignatureThunk = createAsyncThunk(
  `${USER_SETTINGS_NAMESPACE}/${USER_SETTINGS_ACTIONS.FETCH_CREATE_USER_SIGNATURE}`,
  async (params: UserSettingsCreateSignatureBody, {dispatch}) => {
    const response: any = await createUserSignature(params);
    dispatch(fetchGetUserSettingsThunk(params.company_id));
    return response;
  }
);

export const fetchUpdateUserBlackListThunk = createAsyncThunk(
  `${USER_SETTINGS_NAMESPACE}/${USER_SETTINGS_ACTIONS.FETCH_UPDATE_USER_BLACKLIST}`,
  async (params: UserSettingsBlacklistBodyRequest, {dispatch}) => {
    const response: any = await updateUserBlackList(params);
    dispatch(fetchGetUserSettingsThunk(params.company_id));
    return response;
  }
);
