import { Calendar, CalendarProps } from "primereact/calendar";
import InputLabel from "../Input/InputLabel";
import style from "./Datepicker.module.scss";

export interface DatepickerFromTo {
  from?: string;
  to?: string;
}
export interface DatepickerProps extends CalendarProps {
  label?: string;
  isFromTo?: {
    pickerFrom: DatepickerProps;
    pickerTo: DatepickerProps;
  };
  onChangeCallback?: (value: string) => void;
  onChangeCallbackFromTo?: (value: DatepickerFromTo) => void;
}

const Datepicker = ({
  label,
  isFromTo,
  onChangeCallback,
  onChangeCallbackFromTo,
  ...props
}: DatepickerProps) => {
  const onChangeHandler = (e: any) => {
    onChangeCallback && onChangeCallback(e.value || "");
  };
  const onChangeHandlerFromTo = (value: DatepickerFromTo) => {
    onChangeCallbackFromTo && onChangeCallbackFromTo(value);
  };
  if (isFromTo) {
    return (
      <div className={style.container}>
        {!!label && <InputLabel locale={{ label }} required={props.required} />}
        <div className={style.fromToContainer}>
          <Calendar
            inputClassName={style.inputWrapper}
            showIcon
            {...isFromTo.pickerFrom}
            onChange={(e: any) => onChangeHandlerFromTo({ from: e.value.getTime() })}
            panelClassName={style.panelWrapper}
          />
          <Calendar
            inputClassName={style.inputWrapper}
            showIcon
            {...isFromTo.pickerTo}
            onChange={(e: any) => onChangeHandlerFromTo({ to: e.value.getTime() })}
            panelClassName={style.panelWrapper}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={style.container}>
      {!!label && <InputLabel locale={{ label }} required={props.required} />}
      <Calendar
        inputClassName={style.inputWrapper}
        showIcon
        {...props}
        onChange={onChangeHandler}
        panelClassName={style.panelWrapper}
      />
    </div>
  );
};

export default Datepicker;
