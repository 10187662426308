import {
  TabPanel,
  TabView,
  TabViewProps,
  TabPanelProps as PRTabPanelProps,
} from "primereact/tabview";
import style from "./Tab.module.scss";

export interface TabPanelProps extends PRTabPanelProps {
  element: (props?: any) => JSX.Element;
  elementProps?: any;
}

export interface TabProps extends TabViewProps {
  panels: TabPanelProps[];
  tabViewNavContainerFull?: boolean
}

const Tab = ({ panels, tabViewNavContainerFull, ...props }: TabProps) => {
  return (
    <div className={`${style.container} ${tabViewNavContainerFull ? style.tabViewNavContainerFull : ""}`}>
      <TabView {...props}>
        {panels.map(({ element, elementProps, ...panelProps }, i) => {
          const Component = element as any;
          return (
            <TabPanel {...panelProps} key={i}>
              <Component {...elementProps} />
            </TabPanel>
          );
        })}
      </TabView>
    </div>
  );
};

export default Tab;
