import { saveAs } from "file-saver";
import style from "./ManualsComponent.module.scss";
import SecondaryTable from "../library/Table/SecondaryTable/SecondaryTable";
import BoldCell from "../library/Table/custom-components/BoldCell";
import { CrudButton, CrudButtonType } from "../library/CrudButton/CrudButton";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchDownloadManualThunk,
  fetchGetManualsListThunk,
} from "./redux/manuals.thunk";
import { selectSelectedCompany } from "@/app/redux/common.selector";
import { ManualsTree } from "./redux/manuals.models";
import Spinner from "../library/Spinner/Spinner";
import { addToastMessage } from "@/app/redux/common.slice";
import { getIcon } from "../Section/DocumentAttachments";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";

const ManualsComponent = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState<
    Record<string, boolean>
  >({});
  const [manualTree, setManualTree] = useState<ManualsTree>();
  const company = useAppSelector(selectSelectedCompany);
  const { t, i18n } = useTranslation();

  const onDownload = async (rowData: {
    id: string;
    title: string;
    extension: string;
    section_id: string;
  }) => {
    setDownloadLoading((value) => ({ ...value, [rowData.id]: true }));
    const response = await dispatch(
      fetchDownloadManualThunk({
        manualId: rowData.id,
        companyId: company?.companyId || "",
        sectionId: rowData.section_id,
      })
    );
    setDownloadLoading((value) => ({ ...value, [rowData.id]: false }));

    try {
      const fileName = `${decodeURIComponent(rowData.title)}${
        rowData.extension
      }`;
      saveAs(response.payload, fileName);
    } catch (error) {
      dispatch(
        addToastMessage({
          title: t("UI_MANUALS_DOWNLOAD_ERROR_TITLE"),
          message: t("UI_MANUALS_DOWNLOAD_ERROR_MESSAGE"),
          type: "error",
        })
      );
    }
  };

  const columns = [
    {
      field: "extension",
      header: "",
      template: getIcon,
      style: { width: "25px" },
    },
    {
      field: "title",
      header: t("UI_MANUALS_TABLE_TITLE"),
      template: (rowData: any) => (
        <BoldCell value={decodeURIComponent(rowData.title)} />
      ),
    },
    {
      field: "download",
      header: "",
      style: { width: "100px" },
      template: (rowData: any) => (
        <div className={style.crudContainer}>
          {!downloadLoading[rowData.id] && (
            <CrudButton
              type={CrudButtonType.download}
              onClick={() => onDownload(rowData)}
              value={{ ext: rowData.ext }}
            />
          )}
          {downloadLoading[rowData.id] && <Spinner className={style.spinner} />}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getManualsList = async () => {
      setLoading(true);
      const res = await dispatch(
        fetchGetManualsListThunk(company?.companyId || "")
      );
      setManualTree(res.payload);
      setLoading(false);
    };
    getManualsList();
  }, [dispatch, company?.companyId]);

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.title}>{t("UI_MANUALS_TITLE")}</div>
      </div>
      <div className={style.content}>
        {loading && <Spinner />}
        {!loading && (
          <>
            <div className={style.manualSection_description}>
              {getTranslation(
                t,
                manualTree?.disclaimer.code ?? "",
                manualTree?.disclaimer.label ?? "",
                i18n
              )}
            </div>
            {manualTree?.areas.map((a) => (
              <div className={style.manualSection} key={a.name}>
                <div className={`${style.manualSection_title} ${style.area}`}>
                  {getTranslation(t, a.code, a.name, i18n)}
                </div>
                <div className={style.manualSection_description}>
                  {getTranslation(t, a.code_description, a.description, i18n)}
                </div>
                {a.sections.map((s) => (
                  <div className={style.manualSection} key={s.name}>
                    <div className={style.manualSection_title}>
                      {getTranslation(t, s.code, s.name, i18n)}
                    </div>
                    <div className={style.manualSection_description}>
                      {getTranslation(
                        t,
                        s.code_description,
                        s.description,
                        i18n
                      )}
                    </div>
                    {!s.manuals?.length && (
                      <div className={style.manualSection_description_empty}>
                        {t("UI_MANUALS_TABLE_EMPTY")}
                      </div>
                    )}
                    {!!s.manuals && s.manuals.length > 0 && (
                      <div className={style.manualSection_table}>
                        <SecondaryTable
                          columns={columns}
                          data={s.manuals || []}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ManualsComponent;
