import { Steps } from "primereact/steps";
import style from "./PanelDetails.module.scss";
import Tab from "../library/Tab/Tab";
import DocumentInfo from "./DocumentInfo";
import DocumentAttachments from "./DocumentAttachments";
import Button from "../library/Button/Button";
import { useEffect, useRef, useState } from "react";
import { HistoryDialog } from "./HistoryDialog";
import { HistoryCard } from "./HistoryCard";
import { CommentDialog } from "./CommentDialog";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectDocumentDetails,
  selectDocumentDetailsLoading,
  selectDocumentHistory,
  selectDocumentStepsLoading,
  selectDocumentWorkflow,
  selectHasAttachmentsPermission,
  selectSelectedDocument,
} from "@/components/Section/redux/section.selector";
import {
  fetchBumpStatusThunk,
  fetchEditDocumentDetailThunk,
  fetchGetDocumentDetailsThunk,
  fetchGetDocumentHistoryThunk,
  fetchGetDocumentWorkflowThunk,
} from "@/components/Section/redux/section.thunk";
import {
  selectLanguageLoaded,
  selectProfiles,
  selectSelectedCompany,
} from "@/app/redux/common.selector";
import Spinner from "../library/Spinner/Spinner";
import { DigitalSignaturePopup } from "../ActionsPopups/DigitalSignaturePopup";
import { DocumentRejectionPopup } from "../ActionsPopups/DocumentRejectionPopup";
import { DocumentApprovalPopup } from "../ActionsPopups/DocumentApprovalPopup";
import { DocumentConfirmPopup } from "../ActionsPopups/DocumentConfirmPopup";
import {
  setActionDialogLoading,
  setDocumentInstanceCode,
  setDocumentWorkflow,
  setLoading,
} from "./redux/section.slice";
import { EProfileType, IHistory } from "./redux/section.models";
import { addToastMessage, setShowPanelDetails } from "@/app/redux/common.slice";
import { fetchGetMenuActivityCountThunk } from "@/app/redux/common.thunk";
import StepBar from "../library/StepBar/StepBar";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";

enum ActionType {
  SIGNATURE = "SIGNATURE", // firma con richiesta otp
  REJECT = "REJECT", // rifiuto
  CHANGE_STATUS_WITH_ACTION = "CHANGE_STATUS_WITH_ACTION", // cambio stato con modale SI/NO
  CHANGE_STATUS_WITH_CONFIRM = "CHANGE_STATUS_WITH_CONFIRM", // cambio stato con modale "OK"
}
export interface PanelDetailsProps {
  isOpen: boolean;
  onClose: () => void;
}

const PanelDetails = ({ isOpen, onClose }: PanelDetailsProps) => {
  const dispatch = useAppDispatch();
  const stepRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [showMobileSteps, setShowMobileSteps] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const [actionDialog, setActionDialog] = useState(<></>);
  const [actionLoading, setActionLoading] = useState<Record<string, boolean>>(
    {}
  );
  const [localSteps, setLocalSteps] = useState<any[]>([]);
  const [changesBody, setChangesBody] = useState<Record<string, any>>({});
  const [documentHistoryVersionId, setDocumentHistoryVersionId] = useState("");
  const [documentHistoryVersion, setDocumentHistoryVersion] = useState("");
  const [tabContainerStyle, setTabContainerStyle] = useState({
    height: "calc(100% - 185px)",
  });

  const company = useAppSelector(selectSelectedCompany);
  const document = useAppSelector(selectSelectedDocument);
  const steps = useAppSelector(selectDocumentWorkflow);
  const documentStepsLoading = useAppSelector(selectDocumentStepsLoading);
  const documentDetailsLoading = useAppSelector(selectDocumentDetailsLoading);
  const documentHistory = useAppSelector(selectDocumentHistory);
  const hasAttachmentsPermission = useAppSelector(
    selectHasAttachmentsPermission
  );
  const profiles = useAppSelector(selectProfiles);
  const details = useAppSelector(selectDocumentDetails);
  const languageLoaded = useAppSelector(selectLanguageLoaded);

  const panels = [
    {
      element: DocumentInfo,
      header: t("UI_PANEL_DETAIL_TAB_DOCUMENT"),
      elementProps: {
        onSaveField: (code: string, value: any) => {
          setChangesBody((changesBody) => ({
            ...changesBody,
            [code]: value?.code || value,
          }));
        },
      },
    },
    {
      element: DocumentAttachments,
      header: t("UI_PANEL_DETAIL_TAB_ATTACHMENTS"),
      elementProps: {
        selectedDocumentHistoryId: documentHistoryVersionId,
      },
    },
  ];

  const onMoreActions = () => setShowMoreActions((value) => !value);

  const [isHistoryDialogVisible, setIsHistoryDialogVisible] = useState(false);
  const [isCommentsDialogVisible, setIsCommentsDialogVisible] = useState(false);
  const [isAddCommentDialogVisible, setIsAddCommentDialogVisible] =
    useState(false);

  const onMobileStep = () => setShowMobileSteps((value) => !value);

  const onHideActionDialog = () => setActionDialog(<></>);

  const onSubmitAction = async (action: any, params?: string[]) => {
    dispatch(setActionDialogLoading(true));
    let res;
    try {
      res = await dispatch(
        fetchBumpStatusThunk({
          classCode: action.actionCode,
          execParams: [[document.id], ...(params || [])],
        })
      );
      if (res.payload?.data.filter((m: any) => m.statusCode !== 200).length > 0)
        return;
      dispatch(setShowPanelDetails(false));
      dispatch(setDocumentWorkflow([]));
      dispatch(setLoading(true));
      dispatch(
        fetchGetMenuActivityCountThunk({
          companyId: company?.companyId || "",
          callback: () => null,
        })
      );
      dispatch(setActionDialogLoading(false));
      setActionDialog(<></>);
    } finally {
      res?.payload?.data?.forEach((m: any) =>
        dispatch(
          addToastMessage({
            title:
              m.statusCode === 200
                ? t("UI_SECTION_OPERATION_DONE")
                : t("UI_SECTION_OPERATION_FAILED"),
            message: m.message,
            type: m.statusCode === 200 ? "success" : "error",
          })
        )
      );
    }
  };

  const onAction = async (action: any) => {
    if (action.popup) {
      switch (action.actionType) {
        case ActionType.SIGNATURE:
          setActionDialog(
            <DigitalSignaturePopup
              isVisible
              title={action.popupDescription}
              onSubmit={(pin, otp, certificate) =>
                onSubmitAction(action, [pin, otp, certificate])
              }
              onHide={onHideActionDialog}
            />
          );
          return;
        case ActionType.REJECT:
          setActionDialog(
            <DocumentRejectionPopup
              isVisible
              onSubmit={(note) => onSubmitAction(action, [note])}
              onHide={onHideActionDialog}
            />
          );
          return;
        case ActionType.CHANGE_STATUS_WITH_ACTION:
          setActionDialog(
            <DocumentApprovalPopup
              isVisible
              description={action.popupDescription}
              onApprove={onSubmitAction.bind(null, action)}
              onReject={onHideActionDialog}
              onHide={onHideActionDialog}
            />
          );
          return;
        case ActionType.CHANGE_STATUS_WITH_CONFIRM:
          setActionDialog(
            <DocumentConfirmPopup
              isVisible
              description={action.popupDescription}
              onApprove={onSubmitAction.bind(null, action)}
              onHide={onHideActionDialog}
            />
          );
          return;
        default:
          setActionLoading((value) => ({
            ...value,
            [action.actionCode]: true,
          }));
          await onSubmitAction(action);
          setActionLoading((value) => ({
            ...value,
            [action.actionCode]: false,
          }));
          return;
      }
    }
    setActionLoading((value) => ({
      ...value,
      [action.actionCode]: true,
    }));
    await onSubmitAction(action);
    setActionLoading((value) => ({
      ...value,
      [action.actionCode]: false,
    }));
  };

  const onVersionHistory = (item: IHistory) => {
    if (item.document_instance_id !== document.id) {
      setDocumentHistoryVersionId(item.document_instance_id);
      setDocumentHistoryVersion(item.version);
    }
    setIsHistoryDialogVisible(false);
  };

  const hasDetailEditableField = () => {
    const hasEditableField = details.document_metadata?.reduce(
      (acc: boolean, curr: any) => {
        return acc || curr.children?.filter((d: any) => d.editable)?.length > 0;
      },
      false
    );

    return details.document_metadata?.length > 0 && hasEditableField;
    // return false && hasEditableField;
  };

  const getCtaButtons = () => {
    const existingButtons: string[] = [];
    return steps
      .find((s) => s.actualStatus)
      ?.actions?.map((action: any) => {
        if (existingButtons.indexOf(action.actionCode) > -1) return null;
        existingButtons.push(action.actionCode);
        return (
          <Button
            key={action.actionCode}
            label={
              action?.actionButton
                ? getTranslation(
                    t,
                    action?.actionCode,
                    action?.actionButton,
                    i18n
                  )
                : "BTN"
            }
            onClick={onAction.bind(null, action)}
            isLoading={actionLoading[action.actionCode]}
          />
        );
      });
  };

  const onHistory = () => {
    setIsHistoryDialogVisible(true);
    dispatch(
      fetchGetDocumentHistoryThunk({
        documentId: document.id,
        companyId: company?.companyId ?? "",
        sectionId: window.location.pathname.split("/")[2] || "",
      })
    );
  };

  const onSaveDetailsChanges = async () => {
    await dispatch(
      fetchEditDocumentDetailThunk({
        documentId: document.id,
        fields: Object.keys(changesBody).map((id: string) => ({
          process_template_field_id: id,
          value: changesBody[id],
        })),
      })
    );
    setChangesBody({});
    dispatch(
      fetchGetDocumentDetailsThunk({
        documentId: documentHistoryVersionId || document.id,
        companyId: company?.companyId || "",
        sectionId: window.location.pathname.split("/")[2] || "",
      })
    );
  };

  useEffect(() => {
    if (steps.length && !languageLoaded) {
      setLocalSteps(
        steps.map((s) => ({
          label: getTranslation(t, s.statusCode, s.statusName, i18n),
          className: s.actualStatus ? "highlight" : s.isDone ? "isDone" : "",
        }))
      );
    }
  }, [steps, t, i18n, languageLoaded]);

  useEffect(() => {
    if (!!documentHistoryVersionId) {
      dispatch(
        fetchGetDocumentWorkflowThunk({
          company_id: company?.companyId || "",
          section_id: window.location.pathname.split("/")[2] || "",
          document_id: documentHistoryVersionId,
        })
      );
      dispatch(
        fetchGetDocumentDetailsThunk({
          documentId: documentHistoryVersionId,
          companyId: company?.companyId || "",
          sectionId: window.location.pathname.split("/")[2] || "",
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, company, documentHistoryVersionId]);

  useEffect(() => {
    if (!!document.id) {
      setDocumentHistoryVersion("");
      setDocumentHistoryVersionId("");
      dispatch(
        fetchGetDocumentWorkflowThunk({
          company_id: company?.companyId || "",
          section_id: window.location.pathname.split("/")[2] || "",
          document_id: document.id,
        })
      );
      dispatch(
        fetchGetDocumentDetailsThunk({
          documentId: document.id,
          companyId: company?.companyId || "",
          sectionId: window.location.pathname.split("/")[2] || "",
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, company, document]);

  useEffect(() => {
    if (!stepRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (window.innerWidth < 480) {
        setTabContainerStyle({
          height: `calc(100% - ${
            (stepRef.current as any)?.clientHeight + 16 + 40
          }px)`,
        });
      }
    });
    resizeObserver.observe(stepRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  if (isOpen && languageLoaded)
    return (
      <div className={`${style.container} ${isOpen ? style.isOpen : ""}`}>
        <Spinner />
      </div>
    );

  return (
    <div className={`${style.container} ${isOpen ? style.isOpen : ""}`}>
      <div className={style.close}>
        <div className={style.document_title}>
          {t("UI_PANEL_DETAIL_TITLE")}:{" "}
          {!!details?.document_metadata &&
            details?.document_metadata[0]?.children[0]?.value}
        </div>
        <i
          className="pi pi-times-circle"
          onClick={() => {
            onClose();
            setDocumentHistoryVersionId("");
            setDocumentHistoryVersion("");
            dispatch(setDocumentInstanceCode(""));
          }}
        />
      </div>
      <div className={style.steps} ref={stepRef}>
        {!showMobileSteps && (
          <div className={style.steps_mobile_wrapper}>
            <div className={style.steps_mobile}>
              <div className={style.step}>
                {steps.findIndex((s) => s.actualStatus) + 1}
              </div>
              <div className={style.step_description}>
                {steps.find((s) => s.actualStatus)?.statusName}
              </div>
              <div className={style.step_count} onClick={onMobileStep}>
                {steps.findIndex((s) => s.actualStatus) + 1}{" "}
                {t("UI_PANEL_DETAIL_STEP_OF")} {steps.length}
              </div>
              <i className="pi pi-chevron-down" onClick={onMobileStep} />

              {/* <div className={style.step_borderContainer}>
              <div className={style.step_border} />
            </div>
            <div className={style.step_count} onClick={onMobileStep}>
              {steps.findIndex((s) => s.actualStatus) + 1} di {steps.length}
            </div> */}
            </div>
          </div>
        )}
        {showMobileSteps && (
          <div className={style.steps_container_mobile}>
            <div className={style.steps_container_mobile_wrapper}>
              <Steps model={localSteps} />
            </div>

            <i className="pi pi-chevron-up" onClick={onMobileStep} />
            {/* <div className={style.step_count} onClick={onMobileStep}>
              {steps.findIndex((s) => s.actualStatus) + 1} di {steps.length}
            </div> */}
          </div>
        )}
      </div>
      {!documentStepsLoading && (
        <div className={style.steps}>
          <div className={style.steps_container}>
            <StepBar steps={localSteps} />
          </div>
        </div>
      )}
      {documentStepsLoading && <Spinner />}

      {!documentDetailsLoading && (
        <div className={style.tab_container} style={tabContainerStyle}>
          <Tab panels={panels} className={style.tab} />
        </div>
      )}
      {documentDetailsLoading && <Spinner />}
      {actionDialog}
      {!!documentHistoryVersionId && (
        <div className={style.historyFooter}>
          <span className={style.historyFooter__left}>
            {t("UI_HISTORY_WARNING")}:
          </span>
          &nbsp;
          <span className={style.historyFooter__right}>
            {t("UI_HISTORY_VERSION", { version: documentHistoryVersion })}
          </span>
        </div>
      )}
      {!documentDetailsLoading && (
        <div className={style.footer}>
          <div className={style.footer_left}>
            {!documentHistoryVersionId &&
              (hasAttachmentsPermission ||
                profiles.indexOf(EProfileType.BACKOFFICE) > -1) && (
                <>
                  {getCtaButtons()}
                  {hasDetailEditableField() && (
                    <Button
                      label="Salva"
                      onClick={onSaveDetailsChanges}
                      secondaryMode
                      disabled={!Object.keys(changesBody).length}
                    />
                  )}
                </>
              )}
            {!!documentHistoryVersionId && (
              <Button
                label={t("UI_HISTORY_BACK")}
                onClick={() => {
                  setDocumentHistoryVersionId("");
                  setDocumentHistoryVersion("");
                  dispatch(
                    fetchGetDocumentWorkflowThunk({
                      company_id: company?.companyId || "",
                      section_id: window.location.pathname.split("/")[2] || "",
                      document_id: document.id,
                    })
                  );
                  dispatch(
                    fetchGetDocumentDetailsThunk({
                      documentId: document.id,
                      companyId: company?.companyId || "",
                      sectionId: window.location.pathname.split("/")[2] || "",
                    })
                  );
                }}
              />
            )}
          </div>
          <div className={style.footer_right}>
            <Button
              label={t("UI_PANEL_DETAIL_CTA_COMMENTS")}
              onClick={() => setIsCommentsDialogVisible(true)}
              secondaryMode
            />
            <Button
              label={t("UI_PANEL_DETAIL_CTA_HISTORY")}
              onClick={onHistory}
              secondaryMode
            />
          </div>
          <HistoryDialog
            title={documentHistory.instanceCode}
            isVisible={isHistoryDialogVisible}
            onHide={() => setIsHistoryDialogVisible(false)}
          >
            {documentHistory.history.map((item, idx) => (
              <HistoryCard
                key={idx}
                leftActionLabel={item.actionLabel}
                actionTypeLabel={item.action}
                executionerLabel={item.user_name}
                endDate={item.creation_date}
                versionLabel={item.version}
                note={item.note}
                onVersion={onVersionHistory.bind(null, item)}
              />
            ))}
          </HistoryDialog>
          {isCommentsDialogVisible && (
            <CommentDialog
              isVisible={isCommentsDialogVisible}
              addCommentEnabled={isAddCommentDialogVisible}
              onHide={() => {
                setIsCommentsDialogVisible(false);
                setIsAddCommentDialogVisible(false);
              }}
              onViewHistory={onHistory}
            />
          )}

          <div className={style.footer_right_mobile}>
            <Button
              label={t("UI_PANEL_DETAIL_CTA_ACTIONS")}
              onClick={onMoreActions}
              iconLeft="ellipsis-h"
              secondaryMode
            />
            {showMoreActions && (
              <div className={style.footer_moreActions}>
                {/* <div
                className={style.footer_action}
                onClick={() => setIsAddCommentDialogVisible(true)}
              >
                <span>Aggiungi Commento</span>
              </div> */}
                <div className={style.footer_action}>
                  <span onClick={() => setIsCommentsDialogVisible(true)}>
                    {t("UI_PANEL_DETAIL_CTA_COMMENTS")}
                  </span>
                </div>
                <div className={style.footer_action}>
                  <span onClick={() => setIsHistoryDialogVisible(true)}>
                    {t("UI_PANEL_DETAIL_CTA_HISTORY")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PanelDetails;
