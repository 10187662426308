import { Dialog } from "primereact/dialog";
import style from "./CommentDialog.module.scss";
import Button from "../library/Button/Button";
import { CommentCard } from "./CommentCard";
import { useCallback, useEffect, useState } from "react";
import { Comment } from "./redux/section.models";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchCreateCommentThunk,
  fetchGetCommentsThunk,
} from "./redux/section.thunk";
import { selectSelectedCompany } from "@/app/redux/common.selector";
import { selectSelectedDocument } from "./redux/section.selector";
import Spinner from "../library/Spinner/Spinner";
import TextArea from "../library/TextArea/TextArea";

export const CommentDialog = (props: {
  isVisible: boolean;
  addCommentEnabled: boolean;
  onHide: VoidFunction;
  onViewHistory?: VoidFunction;
}) => {
  const dispatch = useAppDispatch();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAddComment, setShowAddComment] = useState(props.addCommentEnabled);
  const [inputTextAreaValue, setInputTextAreaValue] = useState("");
  const [title, setTitle] = useState("");

  const company = useAppSelector(selectSelectedCompany);
  const document = useAppSelector(selectSelectedDocument);

  const customHeader = <div className={style.header}>{title}</div>;

  const customFooter = (
    <div className={style.customFooterContainer}>
      <div>
        <Button
          label={"Vedi Cronologia"}
          iconLeft="book"
          onClick={() => {
            if (props.onViewHistory) props.onViewHistory();
          }}
          secondaryMode
        />
      </div>
      <div>
        <Button
          style={{ marginRight: "0px" }}
          label={"Aggiungi Commento"}
          iconLeft="plus"
          onClick={() => setShowAddComment(true)}
        />
      </div>
    </div>
  );

  const onSaveComment = async () => {
    setLoading(true);
    await dispatch(
      fetchCreateCommentThunk({
        company_id: company?.companyId || "",
        section_id: window.location.pathname.split("/")[2] || "",
        document_instance_id: document.id,
        text: inputTextAreaValue,
      })
    );
    setShowAddComment(false);
    setInputTextAreaValue("");
    getComments();
  };

  const getComments = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(
      fetchGetCommentsThunk({
        company_id: company?.companyId || "",
        section_id: window.location.pathname.split("/")[2] || "",
        documentId: document.id,
      })
    );
    setComments(res.payload?.comments || []);
    setTitle(
      "Commenti" +
        (res.payload?.instance_code ? " - " + res.payload?.instance_code : "")
    );
    setLoading(false);
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  return (
    <Dialog
      style={{ width: "56vw" }}
      className={style.container}
      headerClassName={style.header}
      header={customHeader}
      visible={props.isVisible}
      onHide={() => props.onHide()}
      footer={customFooter}
      closeIcon={<i className="pi pi-times-circle" />}
    >
      {showAddComment && (
        <div className={style.addComment}>
          <TextArea
            autoResize
            locale={{
              label: "Inserisci commento",
              placeholder: "Scrivi qui...",
            }}
            value={inputTextAreaValue}
            onChangeCallback={setInputTextAreaValue}
          />
          <div className={style.customFooterContainer}>
            <div>
              <Button
                label="Salva"
                iconLeft="pi pi-check"
                onClick={onSaveComment}
              />
            </div>
            <div>
              <Button
                style={{ marginRight: "0px" }}
                label="Elimina"
                iconLeft="pi pi-times"
                onClick={() => {
                  setShowAddComment(false);
                  setInputTextAreaValue("");
                }}
                secondaryMode
              />
            </div>
          </div>
        </div>
      )}
      {loading && <Spinner />}
      {!loading &&
        comments.map((c, index) => (
          <CommentCard
            key={index}
            authorLabel={c.author}
            dateLabel={c.creation_date}
            description={c.text}
          />
        ))}
      {!loading && !comments.length && (
        <span className={style.empty}>Nessun commento</span>
      )}
    </Dialog>
  );
};
