interface EnvVariables {
    BE_HOST: string,
    AZURE_CLIENT_ID: string,
    REDIRECT_URI: string
}

const Environment: Record<string, EnvVariables> = {
    'localhost:3000': {
        BE_HOST: 'https://api-app.eni.com/sd-dms-cp',
        AZURE_CLIENT_ID: 'f1104394-fba4-40f1-92ed-092b2fbca00e',
        REDIRECT_URI: 'https://localhost:3000'
    },
    'sd-dms-cp.eni.com': {
        BE_HOST: 'https://api-app.eni.com/sd-dms-cp',
        AZURE_CLIENT_ID: 'f1104394-fba4-40f1-92ed-092b2fbca00e',
        REDIRECT_URI: 'https://sd-dms-cp.eni.com'
    },
    'st-dms-cp.eni.com': {
        BE_HOST: 'https://api-app.eni.com/st-dms-cp',
        AZURE_CLIENT_ID: '4c5f1388-36cb-47b4-9140-a6331205fe99',
        REDIRECT_URI: 'https://st-dms-cp.eni.com'
    },
    //TODO PROD
    'dms-cp.eni.com': {
        BE_HOST: 'https://api-app.eni.com/pr-dms-cp',
        AZURE_CLIENT_ID: '80ecfdee-565a-4278-a230-5e90feb81a27',
        REDIRECT_URI: 'https://dms-cp.eni.com'
    }
}

export const Variables = {
    BE_HOST: Environment[window.location.host]?.BE_HOST || Environment[0].BE_HOST,
    AZURE_CLIENT_ID: Environment[window.location.host]?.AZURE_CLIENT_ID || Environment[0].AZURE_CLIENT_ID,
    REDIRECT_URI: Environment[window.location.host]?.REDIRECT_URI || Environment[0].REDIRECT_URI,
};