import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./LeftSideMenu.module.scss";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  setAlertDialog,
  setProfiles,
  setShowMenu,
} from "@/app/redux/common.slice";
import {
  selectAlertDialog,
  selectMenuActivityCount,
  selectMenuConfiguration,
  selectSelectedCompany,
} from "@/app/redux/common.selector";
import SectionComponent from "../Section/SectionComponent";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";
import { fetchGetBannersThunk } from "@/app/redux/common.thunk";

const LeftSideMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const match = useLocation();

  const { t, i18n } = useTranslation();

  const menuConfiguration = useAppSelector(selectMenuConfiguration);
  const alertDialog = useAppSelector(selectAlertDialog);

  const onClickBars = () => {
    dispatch(setShowMenu());
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.main_container}>
          <div className={style.header_container}>
            <i className="pi pi-times" onClick={onClickBars} />
          </div>
          <div className={style.content_container}>
            {menuConfiguration.length > 0 &&
              menuConfiguration.map((element, index) => {
                return (
                  <AccordionSectionMenu
                    mainRoute={{
                      title: getTranslation(
                        t,
                        element.code,
                        element.name,
                        i18n
                      ),
                      expanded: false,
                      path: "/",
                      element: () => null,
                      children: element.sections.map((s) => ({
                        title: getTranslation(
                          t,
                          s.code,
                          s.name,
                          i18n
                        ),
                        expanded: false,
                        path: `/list/${s.id}`,
                        search: `?processIds=${s.processes.map(
                          (p) => p.id
                        )}&areaId=${element.id}`,
                        element: SectionComponent,
                        profiles: s.profiles,
                        badge: s.activityCount,
                        sectionCode: s.code,
                        sectionId: s.id
                      })),
                    }}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
        <div className={style.footer_container}>
          {!!alertDialog.text && (
            <div
              className={`${style.footer_item} ${style.highlight}`}
              onClick={() => {
                dispatch(setAlertDialog({ visible: true }));
                dispatch(setShowMenu());
              }}
            >
              <i
                className={`pi pi-exclamation-triangle ${style.footer_icon}`}
              ></i>
              <span className={style.footer_title}>
                {t("UI_ALERT_DIALOG_TITLE")}
              </span>
            </div>
          )}
          <div
            className={style.footer_item}
            onClick={() => {
              navigate("manuals");
              onClickBars();
            }}
          >
            <div
              className={style.accordion_section_indicator}
              style={{
                backgroundColor: match.pathname.includes("manuals")
                  ? "#FFD300"
                  : "transparent",
              }}
            ></div>
            <i className={`pi pi-book ${style.footer_icon}`}></i>
            <span className={style.footer_title}>{t("UI_MENU_MANUALS")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const AccordionSectionMenu = ({ mainRoute }: { mainRoute: any }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const match = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const company = useAppSelector(selectSelectedCompany);

  const onClickRow = (route: any) => {
    navigate(
      route.path + (route.search || "") + "&companyId=" + company?.companyId
    );
    dispatch(
      fetchGetBannersThunk({
        companyId: company?.companyId || "",
        sectionId: route.sectionId,
      })
    );
    dispatch(setProfiles(route.profiles));
    dispatch(setShowMenu());
  };

  useEffect(() => {
    setIsExpanded(
      mainRoute?.children.filter((r: any) => match.pathname.includes(r.path))
        .length > 0
    );
    // eslint-disable-next-line
  }, [window.location.href]);

  return (
    <>
      <div
        className={`${style.accordion_container} ${
          isExpanded ? style.isExpanded : ""
        }`}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className={style.accordion_header}>{mainRoute.title}</div>
        <div className={style.accordion_icon_container}>
          <i
            className={`pi pi-angle-down ${style.accordion_icon}`}
            style={{
              rotate: isExpanded ? "180deg" : "0deg",
              transition: "0.4s",
            }}
          />
        </div>
      </div>
      {mainRoute?.children?.map((route: any, i: number) => {
        return (
          !route.hide && (
            <MenuRow
              key={i}
              route={route}
              isExpanded={isExpanded}
              onClickRow={() => onClickRow(route)}
            />
          )
        );
      })}
    </>
  );
};

const MenuRow = ({
  route,
  isExpanded,
  onClickRow,
}: {
  route: any;
  isExpanded: boolean;
  onClickRow: any;
}) => {
  const menuActivityCount = useAppSelector(selectMenuActivityCount);
  const match = useLocation();
  const isSelected = match.pathname.includes(route.path);
  return (
    <div
      className={style.accordion_section_container}
      style={{ maxHeight: isExpanded ? "50px" : "0px" }}
      onClick={onClickRow}
    >
      <div
        className={style.accordion_section_indicator}
        style={{
          backgroundColor: isSelected ? "#FFD300" : "transparent",
        }}
      ></div>
      <div className={style.accordion_section_title}>
        {route.title}
        {menuActivityCount[route.sectionCode] > 0 && (
          <div className={style.accordion_section_icon_badge}>
            {menuActivityCount[route.sectionCode]}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftSideMenu;
