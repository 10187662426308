import { useAppSelector } from "@/app/hooks";
import { selectUsername } from "@/app/redux/common.selector";
import style from "./UserSettings.module.scss";

const UserSettings = () => {
  const username = useAppSelector(selectUsername);

  return (
    <div className={style.container}>
      <i className="pi pi-cog" />
      {window.innerWidth > 480
        ? username
        : `${username?.split(" ")[0]?.charAt(0)}${username
            ?.split(" ")[1]
            ?.charAt(0)}`}
    </div>
  );
};

export default UserSettings;
