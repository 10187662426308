import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import Button from "../library/Button/Button";
import style from "./DigitalSignaturePopup.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectActionDialogLoading,
  selectCertificates,
} from "../Section/redux/section.selector";
import Input from "../library/Input/Input";
import {
  fetchGetCertificatesThunk,
  fetchGetOtpThunk,
} from "../Section/redux/section.thunk";
import Dropdown from "../library/Dropdown/Dropdown";
import { ICertificate } from "../Section/redux/section.models";
import { addToastMessage } from "@/app/redux/common.slice";
import { useTranslation } from "react-i18next";

export const DigitalSignaturePopup = (props: {
  isVisible: boolean;
  title: string;
  onSubmit: (pinValue: string, OTPValue: string, certificate: string) => void;
  onHide: VoidFunction;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [pinValue, setPinValue] = useState("");
  const [OTPValue, setOTPValue] = useState("");
  const [certificateValue, setCertificateValue] = useState<ICertificate | null>(
    null
  );
  const [signLoading, setSignLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [showOtpDisclaimer, setShowOtpDisclaimer] = useState(false);
  const [certificatesLoading, setCertificatesLoading] = useState(false);

  const loading = useAppSelector(selectActionDialogLoading);
  const certificates = useAppSelector(selectCertificates);

  const onOtpRequest = async () => {
    setOtpLoading(true);
    try {
      await dispatch(
        fetchGetOtpThunk({
          pin: pinValue,
          partitionName: certificateValue?.partitionName || "",
        })
      );
      setShowOtpDisclaimer(true);
    } catch (e) {
      dispatch(
        addToastMessage({
          message: t("UI_OTP_ERROR"),
          type: "error",
        })
      );
    } finally {
      setOtpLoading(false);
    }
  };

  const footerContent = (
    <div className={style.customFooterContainer}>
      <div>
        <Button
          label="Richiedi OTP"
          onClick={onOtpRequest}
          secondaryMode
          isLoading={otpLoading}
          disabled={!pinValue || !certificateValue}
        />
      </div>
      <div>
        <Button
          style={{ marginRight: "0px" }}
          label="Firma Documento"
          onClick={() => {
            setSignLoading(true);
            if (props.onSubmit)
              props.onSubmit(
                pinValue,
                OTPValue,
                certificateValue?.partitionName || ""
              );
          }}
          disabled={!OTPValue || !pinValue || !certificateValue}
          isLoading={signLoading}
        />
      </div>
    </div>
  );

  const getCertificateLabel = (value: ICertificate) => {
    return !!value
      ? `Certificato ${value?.partitionName.split("|")[0]} attivo dal ${
          value?.createdOn
        }`
      : "Seleziona Certificato...";
  };

  useEffect(() => {
    if (!loading) {
      setOtpLoading(false);
      setSignLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementsByClassName("p-password-input")[0];
      el.setAttribute("data-js-disable-browser-autofill", "on");
    }, 200);
  }, []);

  useEffect(() => {
    const getCertificates = async () => {
      setCertificatesLoading(true);
      await dispatch(fetchGetCertificatesThunk());
      setCertificatesLoading(false);
    };
    getCertificates();
  }, [dispatch]);

  useEffect(() => {
    if (certificates.length === 1) setCertificateValue(certificates[0]);
  }, [certificates]);

  return (
    <>
      <Dialog
        style={{ width: "45vw" }}
        className={style.container}
        headerClassName={style.header}
        header={props.title}
        visible={props.isVisible}
        onHide={() => {
          if (props.onHide) {
            props.onHide();
          }
        }}
        footer={footerContent}
        closeIcon={<i className="pi pi-times-circle" />}
      >
        <Dropdown
          options={certificates}
          locale={{
            label: "Certificati",
            placeholder: "Seleziona certificato",
          }}
          value={certificateValue}
          disabled={certificates.length === 1}
          dropdownIcon={
            certificatesLoading ? "pi pi-spin pi-spinner" : "pi pi-chevron-down"
          }
          optionLabel="partitionName"
          itemTemplate={getCertificateLabel}
          valueTemplate={getCertificateLabel}
          onChangeCallback={setCertificateValue}
        />
        <div className={style.descriptionContainer}>
          <p>
            {
              "Inserisci il PIN e l'OTP (One Time Password) ricevuto sul tuo numero di telefono tramite SMS"
            }
          </p>
        </div>
        <div className={style.inputsContainer}>
          <div>
            <Password
              inputClassName={style.passwordWrapper}
              placeholder="Inserisci PIN"
              value={pinValue}
              keyfilter={/^[0-9]*$/}
              onChange={(e) => setPinValue(e.target.value)}
              feedback={false}
              toggleMask
            />
          </div>
          <div className={style.inputWrapper}>
            <Input
              locale={{ placeholder: "Inserisci OTP" }}
              value={OTPValue}
              onChangeCallback={setOTPValue}
            />
            {showOtpDisclaimer && (
              <div className={style.otpDisclaimer}>
                OTP inviato con successo
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
