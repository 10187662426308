import { useState } from "react";
import style from "./CrudButton.module.scss";
import { DeleteDialog } from "./DeleteDialog";

export enum CrudButtonType {
  clone = "clone",
  edit = "edit",
  delete = "delete",
  download = "download",
  preview = "preview"
}

export const CrudButton = (props: {
  type: CrudButtonType;
  secondaryMode?: boolean;
  onClick?: VoidFunction;
  value?: { ext: string };
}) => {
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  return (
    <>
      <div
        role="button"
        className={`rounded-icon ${style.container} ${
          props.secondaryMode ? style.secondary : ""
        }`}
        onClick={() => {
          if (props.type === CrudButtonType.delete) {
            setDeleteDialogVisible(true);
            return;
          }

          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        {props.type === CrudButtonType.clone && (
          <i
            className={`${"pi pi-copy"} ${
              props.secondaryMode ? style.secondary : ""
            }`}
          ></i>
        )}
        {props.type === CrudButtonType.edit && (
          <i
            className={`${"pi pi-pencil"} ${
              props.secondaryMode ? style.secondary : ""
            }`}
          ></i>
        )}
        {props.type === CrudButtonType.delete && (
          <i
            className={`${"pi pi-trash"} ${
              props.secondaryMode ? style.secondary : ""
            }`}
          ></i>
        )}
        {props.type === CrudButtonType.download && (
          <i
            className={`${`pi pi-download`} ${
              props.secondaryMode ? style.secondary : ""
            }`}
          ></i>
        )}
        {props.type === CrudButtonType.preview && (
          <i
            className={`${`pi pi-search`} ${
              props.secondaryMode ? style.secondary : ""
            }`}
          ></i>
        )}
      </div>
      {props.type === CrudButtonType.delete && (
        <DeleteDialog
          visible={deleteDialogVisible}
          onConfirm={() => {
            if (props.onClick) {
              props.onClick();
            }
            setDeleteDialogVisible(false);
          }}
          onHide={() => setDeleteDialogVisible(false)}
        />
      )}
    </>
  );
};
