import { InputText, InputTextProps } from "primereact/inputtext";
import { ChangeEvent } from "react";
import style from "./Input.module.scss";
import InputLabel from "./InputLabel";

export interface InputFromTo {
  from?: string;
  to?: string;
}
export interface InputProps extends InputTextProps {
  locale: {
    label?: string;
    placeholder: string;
  };
  iconLeft?: string;
  isFromTo?: {
    inputFrom: Partial<InputProps>;
    inputTo: Partial<InputProps>;
  };
  isErrorMessage?: boolean;
  onChangeCallback?: (value: string) => void;
  onChangeCallbackFromTo?: (value: InputFromTo) => void;
}

const Input = ({
  locale,
  type,
  iconLeft,
  isErrorMessage,
  isFromTo,
  onChangeCallback,
  onChangeCallbackFromTo,
  ...props
}: InputProps) => {
  const preventNonNumericalInput = (e: any) => {
    e = e || window.event;
    var charCode = typeof e.which == "number" ? e.which : e.keyCode;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]/)) e.preventDefault();
  };

  const getWrapperClasses = () => {
    if (iconLeft) return "p-input-icon-left";
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeCallback && onChangeCallback(e.target.value);
  };
  const onChangeHandlerFromTo = (value: InputFromTo) => {
    onChangeCallbackFromTo && onChangeCallbackFromTo(value);
  };

  const inputWrapperClassName = isErrorMessage
    ? `${style.inputWrapper} ${style.errorWrapper}`
    : style.inputWrapper;

  return (
    <div className={style.container}>
      {!!locale?.label && (
        <InputLabel
          locale={locale}
          required={props.required}
          isErrorMessage={isErrorMessage}
        />
      )}
      <div className={getWrapperClasses()}>
        {iconLeft && <i className={`${style.icon} pi pi-${iconLeft}`} />}
        {!isFromTo && (
          <InputText
            className={inputWrapperClassName}
            placeholder={locale.placeholder}
            onChange={onChangeHandler}
            keyfilter={type === "number" ? /^[^e+,E!]+$/ : undefined}
            onKeyPress={(e) =>
              type === "number" ? preventNonNumericalInput(e) : {}
            }
            {...props}
          />
        )}
        {isFromTo && (
          <div className={style.fromToContainer}>
            <InputText
              className={inputWrapperClassName}
              keyfilter={type === "number" ? /^[^e+,E!]+$/ : undefined}
              onKeyPress={(e) =>
                type === "number" ? preventNonNumericalInput(e) : {}
              }
              {...isFromTo.inputFrom}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeHandlerFromTo({ from: e.target.value })
              }
            />
            <InputText
              className={inputWrapperClassName}
              keyfilter={type === "number" ? /^[^e+,E!]+$/ : undefined}
              onKeyPress={(e) =>
                type === "number" ? preventNonNumericalInput(e) : {}
              }
              {...isFromTo.inputTo}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeHandlerFromTo({ to: e.target.value })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
