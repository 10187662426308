import { msalInstance } from "@/authConfig";
import axios from "axios";
import { Variables } from "./envVariables";

export const HTTP_STATUS_TOAST = [400, 401, 500]

export enum GatewayPrefix {
  MASTER_DATA = 'master-data',
  WORKFLOW = 'workflow/model',
  ENGINE = 'workflow/engine'
}

const http = axios.create({
  baseURL: `${Variables.BE_HOST}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

// Add a request interceptor
http.interceptors.request.use(
  async (config) => {
    const accounts = msalInstance.getAllAccounts() ?? [];
    const account = accounts.at(0);

    const accessTokenRequest = {
      scopes: ["user.read"],
      account: account,
    };

    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );

    let accessToken = accessTokenResponse.idToken;
    config.headers["Authorization"] = "Bearer " + accessToken;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default http;
