import { Dialog } from "primereact/dialog";
import Button from "../Button/Button";
import style from "./DeleteDialog.module.scss";

export const DeleteDialog = (props: {
  visible: boolean;
  onHide: VoidFunction;
  onConfirm: VoidFunction;
}) => {
  const footerContent = (
    <div className={style.footerContainer}>
      <Button
        label={"Annulla"}
        iconLeft={"times"}
        onClick={props.onHide}
        secondaryMode
      />
      <Button
        style={{ marginRight: "0px" }}
        label={"Elimina"}
        iconLeft={"check"}
        onClick={props.onConfirm}
      />
    </div>
  );

  return (
    <Dialog
      className={style.container}
      headerClassName={style.header}
      header="Conferma eliminazione"
      footer={footerContent}
      visible={props.visible}
      modal={true}
      onHide={props.onHide}
    >
      <p className={style.description}>
        Vuoi davvero eliminare questo elemento?
      </p>
    </Dialog>
  );
};
