import style from "./Filters.module.scss";
import Button from "../library/Button/Button";
import { useFilterComponentHook } from "./useFilterComponentHook";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  setForceReloadData,
  setIsFilterExpanded,
  setShowPanelDetails,
} from "@/app/redux/common.slice";
import {
  selectIsFilterExpanded,
  selectSelectedCompany,
} from "@/app/redux/common.selector";
import { Field, FilterItem, FilterMap } from "./redux/section.models";
import { fetchGetDocumentsThunk } from "./redux/section.thunk";
import { setDocFilters, setPaginator } from "./redux/section.slice";
import { useTranslation } from "react-i18next";
import {
  selectLoadingConfiguration,
  selectPaginator,
} from "./redux/section.selector";
import Spinner from "../library/Spinner/Spinner";
import { PropsWithChildren, useEffect, useState } from "react";
import { PAGE_SIZE } from "./redux/section.constants";
import { useSearchParams } from "react-router-dom";

export interface FiltersProps extends PropsWithChildren {
  configuration: Field[];
  userDocuments: boolean;
}

const Filters = ({ configuration, userDocuments, children }: FiltersProps) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const isFilterExpanded = useAppSelector(selectIsFilterExpanded);
  const company = useAppSelector(selectSelectedCompany);
  const loadingConfiguration = useAppSelector(selectLoadingConfiguration);
  const paginator = useAppSelector(selectPaginator);

  const [filledFilterMap, setFilledFilterMap] = useState<FilterMap>({});
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [filtersCounter, setFiltersCounter] = useState<number>(0);

  const { t } = useTranslation();

  const { filterMap, formInvalid, setFilterMap, getFilterComponent } =
    useFilterComponentHook({ setHasFilters });

  const toggleFilterExpanded = () => {
    dispatch(setShowPanelDetails(false));
    dispatch(setIsFilterExpanded(!isFilterExpanded));
    setFilledFilterMap(filledFilterMap);
  };

  /*
  // This object is a sequelize operator
  // here sequelize documentation 
  // https://sequelize.org/docs/v6/core-concepts/model-querying-basics/#operators
  */
  const getFilterValue = (filter: FilterItem) => {
    // range
    if (!!filter.from && !!filter.to) {
      return { between: [filter.from, filter.to] };
    }
    // Greater than
    if (!!filter.from && !filter.to) {
      return { gte: filter.from };
    }
    // Less than
    if (!filter.from && !!filter.to) {
      return { lte: filter.to };
    }
    // Values in array
    if (!!filter.multiselect?.length) {
      return { [filter.operation || ""]: filter.multiselect };
    }
    // Equal (select) or substring (text input)
    if (!!filter.value) {
      return { [filter.operation || "substring"]: filter.value };
    }
    return null;
  };

  const onApplyFilter = async () => {
    setHasFilters(true);
    let counter = 0;
    const filterBody = Object.keys(filterMap).map((f) => {
      const value = getFilterValue(filterMap[f]);
      counter = !!value ? counter + 1 : counter;
      return {
        field_name: f,
        id: filterMap[f].field_id,
        value,
        custom_value: filterMap[f].custom_value,
      };
    });
    setFiltersCounter(counter);
    if (paginator.page === 1) {
      await dispatch(
        fetchGetDocumentsThunk({
          companyId: company?.companyId || "",
          sectionId: window.location.pathname.split("/")[2] || "",
          page: 1,
          docFilters: filterBody,
          userDocuments,
        })
      );
      const url = new URL(window.location.href);
      url.searchParams.delete("documentCode");
      window.history.replaceState(null, "", url);
    }
    dispatch(setDocFilters(filterBody));
    dispatch(
      setPaginator({
        page: 1,
        pageCount: 0,
        total: 0,
        reload: false,
        first: 1,
        size: PAGE_SIZE,
      })
    );
    // toggleFilterExpanded();
  };

  const onReset = () => {
    setHasFilters(false);
    const newFilterMap = { ...filterMap };
    Object.keys(newFilterMap).forEach((f) => {
      newFilterMap[f] = {
        ...newFilterMap[f],
        value: "",
        from: "",
        to: "",
        multiselect: [],
      };
    });
    setFiltersCounter(0);
    setFilterMap(newFilterMap);
    dispatch(setDocFilters([]));
    if (paginator.page === 1) {
      dispatch(
        fetchGetDocumentsThunk({
          companyId: company?.companyId || "",
          sectionId: window.location.pathname.split("/")[2] || "",
          page: 1,
          docFilters: [],
          userDocuments,
        })
      );
    }
    dispatch(
      setPaginator({
        page: 1,
        pageCount: 0,
        total: 0,
        reload: true,
        first: 1,
        size: PAGE_SIZE,
      })
    );
    dispatch(setForceReloadData(true));
    toggleFilterExpanded();
  };

  const onFilterMobile = () => dispatch(setIsFilterExpanded(true));
  const onCloseFilters = () => dispatch(setIsFilterExpanded(false));

  useEffect(() => {
    if (!!searchParams.get("documentCode")) {
      toggleFilterExpanded();
      onApplyFilter();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.filter_container}>
      <div className={style.filter_header_mobile}>
        <Button
          label={t("UI_FILTERS_HEADER")}
          iconLeft="filter"
          secondaryMode
          badge={!!filtersCounter && (
            <span className={style.filter_badge}>{filtersCounter}</span>
          )}
          onClick={onFilterMobile}
        />
        <div className={style.filter_header_mobile_cta}>{children}</div>
      </div>
      <div
        className={style.filter_header_container}
        onClick={toggleFilterExpanded}
      >
        <div className={style.filter_title}>
          <i
            className={`pi pi-filter ${style.filter_title_icon} ${
              hasFilters ? style.hasFilters : ""
            }`}
          />
          <span>
            {t("UI_FILTERS_HEADER")}
            {/* {!!filtersCounter ? ` (${filtersCounter})` : ""} */}
          </span>
          {!!filtersCounter && (
            <span className={style.filter_badge}>{filtersCounter}</span>
          )}
        </div>
        <div className={style.filter_icon_container}>
          <i
            className={`pi pi-angle-down ${style.filter_icon}`}
            style={{
              rotate: isFilterExpanded ? "180deg" : "0deg",
              transition: "0.4s",
            }}
          />
        </div>
      </div>
      <div
        className={style.filter_content}
        style={{
          display: isFilterExpanded ? "block" : "none",
          transition: "0.8s",
        }}
      >
        <div className={style.filter_body}>
          <div className={style.filter_body_title}>
            <span>{t("filter.header")}</span>
            <i className="pi pi-times" onClick={onCloseFilters} />
          </div>
          {loadingConfiguration && <Spinner />}
          {!loadingConfiguration &&
            configuration.map((field) => {
              return getFilterComponent(field);
            })}
        </div>
        <div className={style.filter_footer}>
          <Button
            label={t("UI_FILTERS_RESET")}
            iconLeft="refresh"
            secondaryMode
            onClick={onReset}
          />
          <Button
            label={t("UI_FILTERS_APPLY")}
            iconLeft="check"
            onClick={onApplyFilter}
            disabled={formInvalid}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
