import ManualsComponent from "@/components/Manuals/ManualsComponent";
import NotEnabledComponent from "@/components/NotEnabled/NotEnabledComponent";
import SectionComponent from "@/components/Section/SectionComponent";
import UserSettings from "@/components/UserSettings/UserSettings";

export interface Path {
  title: string;
  children?: Path[];
  expanded?: boolean;
  path: string;
  element?: () => JSX.Element | null;
  selected?: boolean;
  hide?: boolean;
  search?: string;
}

export const paths: Path[] = [
  {
    title: "",
    children: [],
    expanded: false,
    path: "/",
    element: () => null,
    hide: true,
  },
  {
    title: "List",
    selected: false,
    path: "/list/:sectionId",
    search: "",
    element: SectionComponent,
  },
  {
    title: "Manuali",
    children: [],
    expanded: false,
    path: "/manuals",
    element: ManualsComponent,
    hide: true,
  },
  {
    title: "UserSettings",
    children: [],
    expanded: false,
    path: "/user-settings",
    element: UserSettings,
    hide: true,
  },
  {
    title: "Not Enabled",
    children: [],
    expanded: false,
    path: "/not-enabled",
    element: NotEnabledComponent,
    hide: true,
  }
];
