import buttonStyle from "./Button.module.scss";

export interface ButtonProps {
  label: string;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  secondaryMode?: boolean;
  style?: Record<string, string>;
  isLoading?: boolean;
  badge?: any;
  onClick: () => void;
}

const Button = ({
  label,
  secondaryMode,
  style,
  iconLeft,
  iconRight,
  disabled,
  isLoading,
  badge,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={`${buttonStyle.container} ${
        secondaryMode ? buttonStyle.secondary : ""
      } ${disabled ? buttonStyle.disabled : ""}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {!isLoading && (
        <>
          {iconLeft && <i className={`pi pi-${iconLeft}`} />}
          {label}
          {iconRight && <i className={`pi pi-${iconRight}`} />}
          {badge}
        </>
      )}
      {isLoading && <i className="pi pi-spin pi-spinner" />}
    </button>
  );
};

export default Button;
