import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { ChangeEvent } from 'react';
import InputLabel from "../Input/InputLabel";
import style from "./TextArea.module.scss";

export interface TextAreaProps extends InputTextareaProps {
  locale: {
    label?: string;
    placeholder: string;
  };
  ref?: any
  onChangeCallback?: (value: string) => void
}

const TextArea = ({ locale, onChangeCallback, ...props }: TextAreaProps) => {
  const onChangeHandler = ({target}: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeCallback && onChangeCallback(target.value)
  }
  return (
    <div className={style.container}>
      {!!locale?.label && (
        <InputLabel locale={locale} required={props.required} />
      )}
      <InputTextarea
        className={style.inputWrapper}
        placeholder={locale.placeholder}
        rows={3}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  );
};

export default TextArea;
