import LeftSideMenu from "../../components/LeftSideMenu/LeftSideMenu";
import Navbar from "@/components/Navbar/Navbar";
import style from "./PrivateRoute.module.scss";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectAlertDialog,
  // selectLanguageLoaded,
  selectReloadMenuTranslation,
  selectShowMenu,
  selectShowPanelDetails,
} from "@/app/redux/common.selector";
import PanelDetails from "@/components/Section/PanelDetails";
import { setAlertDialog, setShowPanelDetails } from "@/app/redux/common.slice";
import { setSelectedDocument } from "@/components/Section/redux/section.slice";
import { AlertDialog } from "@/components/LeftSideMenu/AlertDialog";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";

const PrivateRoute = (props: any) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const showMenu = useAppSelector(selectShowMenu);
  const showPanelDetails = useAppSelector(selectShowPanelDetails);
  const alertDialog = useAppSelector(selectAlertDialog);
  // const languageLoaded = useAppSelector(selectLanguageLoaded);
  const reloadMenuTranslation = useAppSelector(selectReloadMenuTranslation);

  const onPanelDetailsClose = () => {
    dispatch(setShowPanelDetails(false));
    dispatch(setSelectedDocument({ id: "" }));
  };

  return (
    <div className={style.app_container}>
      <div className={style.app_navbar_container}>
        <Navbar />
      </div>

      <div className={style.app_main_container}>
        <div
          className={`${style.app_left_side_menu_container} ${
            showMenu ? style.isOpen : ""
          }`}
        >
          {!reloadMenuTranslation && <LeftSideMenu />}
        </div>

        <div className={style.app_body_container}>
          {props.children}
          <Suspense>
            <AlertDialog
              title={t("UI_ALERT_DIALOG_TITLE")}
              isVisible={alertDialog.visible}
              isBlocking={!alertDialog.closable}
              text={getTranslation(
                t,
                alertDialog.code ?? "",
                alertDialog.text ?? "",
                i18n
              )}
              onHide={() => dispatch(setAlertDialog({ visible: false }))}
              onClose={() => dispatch(setAlertDialog({ visible: false }))}
            />
          </Suspense>
          <PanelDetails
            isOpen={showPanelDetails}
            onClose={onPanelDetailsClose}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute;
