import style from "./CommentCard.module.scss";
import { CrudButtonType, CrudButton } from "../library/CrudButton/CrudButton";

export const CommentCard = (props: {
  authorLabel: string;
  dateLabel: string;
  description: string;
  onEditComment?: VoidFunction;
  onDeleteComment?: VoidFunction;
}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.headerContainer}>
          <div className={style.authorInfoContainer}>
            <p className={style.infoLabel}>Autore: </p>
            <p className={style.infoValue}>{props.authorLabel}</p>
          </div>
          <div className={style.dateInfoContainer}>
            <p className={style.infoLabel}>Data fine / ora: </p>
            <p className={style.infoValue}>{`${new Date(
              props.dateLabel
            ).toLocaleDateString()} ore ${new Date(
              props.dateLabel
            ).getHours()}:${new Date(props.dateLabel).getMinutes()}`}</p>
          </div>
        </div>
        <div className={style.subHeaderContainer}>
          {props.onDeleteComment && props.onEditComment && (
            <div className={style.crudButtonsContainer}>
              <div style={{ paddingLeft: "8px", paddingRight: "4px" }}>
                <CrudButton
                  secondaryMode={true}
                  type={CrudButtonType.edit}
                  onClick={() => {
                    if (props.onEditComment) {
                      props.onEditComment();
                    }
                  }}
                />
              </div>
              <div>
                <CrudButton
                  secondaryMode={true}
                  type={CrudButtonType.delete}
                  onClick={() => {
                    if (props.onDeleteComment) {
                      props.onDeleteComment();
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={style.descriptionContainer}>
          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
};
