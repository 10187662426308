import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import commonReducer from "@/app/redux/common.slice";
import sectionReducer from "@/components/Section/redux/section.slice";

export const store = configureStore({
  reducer: {
    commons: commonReducer,
    section: sectionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
