import style from "./CompanySelection.module.scss";
import Dropdown from "../library/Dropdown/Dropdown";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectCompanyList,
  selectMenuConfiguration,
  selectSelectedCompany,
} from "@/app/redux/common.selector";
import { Company, setMenuConfiguration, setSelectedCompany, setShowPanelDetails } from "@/app/redux/common.slice";
import { setConfiguration, setSelectedDocument } from "../Section/redux/section.slice";

const CompanySelection = () => {
  const dispatch = useAppDispatch();

  const companyList = useAppSelector(selectCompanyList);
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const menuConfiguration = useAppSelector(selectMenuConfiguration);

  const onChangeCallback = (value: Company) => {
    dispatch(setShowPanelDetails(false));
    dispatch(setSelectedDocument({id: ""}));
    dispatch(setMenuConfiguration([]));
    dispatch(setConfiguration([]));
    dispatch(setSelectedCompany(value));
  };

  return (
    <div className={`${style.container}  ${style.languagesEnabled}`}>
      <Dropdown
        // filter
        value={selectedCompany}
        onChangeCallback={onChangeCallback}
        options={companyList}
        locale={{ placeholder: "Seleziona azienda" }}
        className={style.dropdown}
        filterPlaceholder="Cerca azienda"
        optionLabel="companyName"
        dropdownIcon={
          menuConfiguration.length > 0 && !companyList.length
            ? "pi pi-spin pi-spinner"
            : "pi pi-chevron-down"
        }
      />
    </div>
  );
};

export default CompanySelection;
