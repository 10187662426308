import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectLastToastMessage } from "@/app/redux/common.selector";
import { addToastMessage, removeToastMessage } from "@/app/redux/common.slice";
import style from "./ToastMessage.module.scss";

const ToastMessage = () => {
  const toast = useRef<Toast>(null);
  const lastToastMessage = useAppSelector(selectLastToastMessage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (lastToastMessage) {
      toast.current?.show({
        severity: lastToastMessage.type,
        summary:
          lastToastMessage.title ||
          (lastToastMessage.type === "success" ? "Success" : "Error"),
        detail: lastToastMessage.message,
        life: 5000,
      });

      dispatch(removeToastMessage(lastToastMessage.id!));
    }
  }, [lastToastMessage, dispatch]);

  return <Toast ref={toast} className={style.toastMessageContainer} />;
};

export const useToastMessage = () => {
  const dispatch = useAppDispatch();

  return {
    showToastSuccess: (message: string) => {
      dispatch(addToastMessage({ type: "success", message }));
    },
    showToastError: (message: string) => {
      dispatch(addToastMessage({ type: "error", message }));
    },
  };
};

export default ToastMessage;
