import { Dialog } from "primereact/dialog";
import Button from "../library/Button/Button";
import style from "./SessionExpiredDialog.module.scss";
import { useMsal } from "@azure/msal-react";

export const SessionExpiredDialog = (props: { isVisible: boolean }) => {
  const { instance } = useMsal();

  const footerContent = (
    <div className={style.footerContainer}>
      <Button
        style={{ marginRight: "0px" }}
        label="Logout"
        iconLeft="pi pi-ban"
        onClick={() => {
          instance.logout();
        }}
      />
    </div>
  );

  return (
    <>
      <Dialog
        style={{ width: "30vw" }}
        className={style.container}
        headerClassName={style.header}
        header={"Sessione scaduta"}
        visible={props.isVisible}
        closable={false}
        footer={footerContent}
        onHide={() => null}
      >
        <p className={style.description}>
          {"La sessione è scaduta, è necessario eseguire nuovamente l'autenticazione."}
        </p>
      </Dialog>
    </>
  );
};
