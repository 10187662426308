import style from "./AlertDialog.module.scss";
import { Dialog } from "primereact/dialog";
import { AlertDialogFooter } from "./AlertDialogFooter";

export const AlertDialog = (props: {
  title: string;
  isVisible: boolean;
  onHide?: VoidFunction;
  onClose: VoidFunction;
  isBlocking?: boolean;
  text: string;
}) => {
  return (
    <div className={style.containerDialog}>
      <Dialog
        style={{ width: "40vw" }}
        appendTo="self"
        className={style.container}
        headerClassName={style.header}
        header={props.title}
        footer={
          !props.isBlocking && <AlertDialogFooter onClose={props.onClose} />
        }
        draggable={false}
        visible={props.isVisible}
        closable={!props.isBlocking}
        onHide={() => {
          if (!props.isBlocking && props.onHide) {
            props.onHide();
          }
        }}
        closeIcon={<i className="pi pi-times-circle" />}
      >
        <div className={style.content}>{props.text}</div>
      </Dialog>
    </div>
  );
};
