import style from "./NotEnabledComponent.module.scss";

const NotEnabledComponent = () => {

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.title}>Utente non abilitato</div>
      </div>
      <div className={style.content}>
        <div className={style.section}>
          <div className={style.section_title}>Nessuna sezione abilitata per questa utenza</div>
          <div className={style.section_description}>description</div>
        </div>
      </div>
    </div>
  );
};

export default NotEnabledComponent;
