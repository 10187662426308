import { createAsyncThunk } from "@reduxjs/toolkit";
import { COMMON_NAMESPACE } from "./common.selector";
import { getBanners, getCompanies, getLanguages, getMenu, getUserInfo, postLogin, saveLanguage } from "./common.api";

export const COMMON_ACTIONS = {
  FETCH_COMPANIES: "fetchCompanies",
  FETCH_MENU: "fetchMenu",
  FETCH_MENU_ACTIVITY_COUNT: "fetchMenuActivityCount",
  FETCH_POST_LOGIN: "fetchPostLogin",
  FETCH_BANNERS: "fetchBanners",
  FETCH_LANGUAGES: "fetchLanguages",
  FETCH_SAVE_LANGUAGE: "fetchSaveLanguage",
  FETCH_USER_INFO: "fetchUserInfo"
};

export const fetchGetCompaniesThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_COMPANIES}`,
  async () => {
    const response = await getCompanies();
    return response;
  }
);

export const fetchGetMenuThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_MENU}`,
  async ({
    companyId,
    callback,
  }: {
    companyId: string;
    callback: (isEmptyUser: boolean) => void;
  }) => {
    const response = await getMenu(companyId);
    callback(!response.length);
    return response;
  }
);
export const fetchGetMenuActivityCountThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_MENU_ACTIVITY_COUNT}`,
  async ({
    companyId,
    callback,
  }: {
    companyId: string;
    callback: (isEmptyUser: boolean) => void;
  }) => {
    const response = await getMenu(companyId);
    callback(!response.length);
    return response;
  }
);

export const fetchPostLoginThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_POST_LOGIN}`,
  async () => {
    const response = await postLogin();
    return response;
  }
);

export const fetchGetBannersThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_BANNERS}`,
  async (params: {companyId: string, sectionId: string}) => {
    const response = await getBanners(params);
    return response;
  }
);

export const fetchGetLanguagesThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_LANGUAGES}`,
  async (companyId: string, {dispatch}) => {
    await dispatch(fetchGetUserInfoThunk());
    const response = await getLanguages(companyId);
    return response;
  }
);

export const fetchSaveLanguageThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_SAVE_LANGUAGE}`,
  async (language: string) => {
    const response = await saveLanguage(language);
    return response;
  }
);

export const fetchGetUserInfoThunk = createAsyncThunk(
  `${COMMON_NAMESPACE}/${COMMON_ACTIONS.FETCH_USER_INFO}`,
  async () => {
    const response = await getUserInfo();
    return response;
  }
);