import { RootState } from "@/app/store";

export const COMMON_NAMESPACE = "commons";

export const selectToastMessages = (state: RootState) =>
  state[COMMON_NAMESPACE].toastMessages;

export const selectLastToastMessage = (state: RootState) => {
  const lastIndex = state[COMMON_NAMESPACE].toastMessages.length - 1;
  if (lastIndex < 0) {
    return null;
  }
  return state[COMMON_NAMESPACE].toastMessages[lastIndex];
};
export const selectCheckOnRouteChangeEnabled = (state: RootState) =>
  state[COMMON_NAMESPACE].checkOnRouteChangeEnabled;
export const selectTargetPath = (state: RootState) =>
  state[COMMON_NAMESPACE].targetPath;
export const selectUsername = (state: RootState) =>
  state[COMMON_NAMESPACE].username;
export const selectShowMenu = (state: RootState) =>
  state[COMMON_NAMESPACE].showMenu;
export const selectCompanyList = (state: RootState) =>
  state[COMMON_NAMESPACE].companyList;
export const selectCompanyListLoaded = (state: RootState) =>
  state[COMMON_NAMESPACE].companyListLoaded;
export const selectSelectedCompany = (state: RootState) =>
  state[COMMON_NAMESPACE].selectedCompany;
export const selectMenuConfiguration = (state: RootState) =>
  state[COMMON_NAMESPACE].menuConfiguration;
export const selectPageName = (state: RootState) =>
  state[COMMON_NAMESPACE].pageName;
export const selectShowPanelDetails = (state: RootState) =>
  state[COMMON_NAMESPACE].showPanelDetails;
export const selectIsFilterExpanded = (state: RootState) =>
  state[COMMON_NAMESPACE].isFilterExpanded;
export const selectForceReloadData = (state: RootState) =>
  state[COMMON_NAMESPACE].forceReloadData;
export const selectProfiles = (state: RootState) =>
  state[COMMON_NAMESPACE].profiles;
export const selectAlertDialog = (state: RootState) =>
  state[COMMON_NAMESPACE].alertDialog;
export const selectCurrentLanguage = (state: RootState) =>
  state[COMMON_NAMESPACE].currentLanguage;
export const selectLanguages = (state: RootState) =>
  state[COMMON_NAMESPACE].languages;
export const selectLanguageLoaded = (state: RootState) =>
  state[COMMON_NAMESPACE].languageLoaded;
export const selectReloadMenuTranslation = (state: RootState) =>
  state[COMMON_NAMESPACE].reloadMenuTranslation;
export const selectMenuActivityCount = (state: RootState) =>
  state[COMMON_NAMESPACE].menuActivityCount;
