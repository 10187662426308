import { createAsyncThunk } from "@reduxjs/toolkit";
import { MANUALS_NAMESPACE } from "./manuals.selector";
import { downloadManual, getManualsList } from "./manuals.api";

export const MANUALS_ACTIONS = {
  FETCH_GET_MANUALS_LIST: "fetchGetManualsList",
  FETCH_DOWNLOAD_MANUAL: "fetchDownloadManual",
};

export const fetchGetManualsListThunk = createAsyncThunk(
  `${MANUALS_NAMESPACE}/${MANUALS_ACTIONS.FETCH_GET_MANUALS_LIST}`,
  async (companyId: string) => {
    const response: any = await getManualsList(companyId);
    return response;
  }
);

export const fetchDownloadManualThunk = createAsyncThunk(
  `${MANUALS_NAMESPACE}/${MANUALS_ACTIONS.FETCH_DOWNLOAD_MANUAL}`,
  async (params: Record<string, string>) => {
    const response: any = await downloadManual(params);
    return response;
  }
);
