import style from "./Spinner.module.scss";

const Spinner = (props: any) => {
  return (
    <div className={style.loading + " " + props.className}>
      <i className="pi pi-spin pi-spinner" />
    </div>
  );
};

export default Spinner;
