import http from "@/utils/http";
import { Company } from "./common.slice";

export async function getCompanies() {
  const { data } = await http.get<{ companies: Company[] }>(
    `/document-manager-api/user/profile/user/companies`
  );
  return data.companies;
}

export async function getMenu(companyId: string) {
  const { data } = await http.get(
    `/document-manager-api/user/profile/area-tree/${companyId}`
  );
  return data.data;
}

export async function postLogin() {
  const { data } = await http.post(
    `/document-manager-api/user/profile/user/login`
  );
  return data.data;
}

export async function getBanners(params: {
  companyId: string;
  sectionId: string;
}) {
  const { data } = await http.get(
    `/document-manager-api/user/profile/user/banners/company/${params.companyId}/section/${params.sectionId}`
  );
  return data.data;
}

export async function getLanguages(companyId: string) {
  const { data } = await http.get(
    `/model-manager-api/master-data/languages/company/${companyId}`
  );
  return data.data;
}

export async function saveLanguage(language_id: string) {
  const { data } = await http.post(
    `/document-manager-api/user/profile/user/language`,
    { language_id }
  );
  return data;
}

export async function getUserInfo() {
  const { data } = await http.get(`/document-manager-api/user/profile/user/me`);
  return data;
}
