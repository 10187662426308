import http from "@/utils/http";
import {
  IDocumentAttachment,
  Field,
  ICertificate,
  IHistory,
  Comment,
  DocumentInitBodyRequest,
} from "./section.models";
import { PAGE_SIZE } from "./section.constants";

export async function getConfiguration(companyId: string, sectionId: string) {
  const { data } = await http.get<{ data: Field[] }>(
    `/model-manager-api/workflow/model/company/${companyId}/section/${sectionId}/fields`
  );
  return data.data;
}

export async function getInitializationFormList(
  companyId: string,
  sectionId: string
) {
  const { data } = await http.get<{ data: Field[] }>(
    `/model-manager-api/workflow/model/company/${companyId}/section/${sectionId}/popup`
  );
  return data;
}

export async function createDocumentInit(body: DocumentInitBodyRequest) {
  const { data } = await http.post(
    `/model-manager-api/workflow/engine/document/init`,
    body
  );
  return data;
}

export async function getDocuments({
  companyId,
  sectionId,
  userDocuments,
  order,
  page,
  docFilters,
}: Record<string, any>) {
  const body = {
    companyId,
    sectionId,
    myDocuments: userDocuments,
    page,
    docFilters: docFilters || [],
    order: [...(order || []), ["updated_at", "DESC"]] || [
      ["updated_at", "DESC"],
    ],
  };
  if (!!page) (body as any).pageSize = PAGE_SIZE;
  const { data } = await http.post<Record<string, any>>(
    `/document-manager-api/dms-document/documents`,
    body
  );
  return data;
}

export async function getValues({
  company_id,
  section_id,
  filter_code,
  partial_search,
  document_instance,
}: Record<string, string>) {
  const { data } = await http.post<Record<string, string>>(
    `/document-manager-api/dms-document/document/filter/values`,
    {
      company_id,
      section_id,
      filter_code,
      partial_search,
      document_instance,
    }
  );
  return data.data_select;
}

export async function getDocumentWorkflow(params: Record<string, string>) {
  const { data } = await http.get<Record<string, string>>(
    `/document-manager-api/dms-document/workflow`,
    { params }
  );
  return data.documentWorkflow;
}

export async function getDocumentDetails(
  documentId: string,
  companyId: string,
  sectionId: string
) {
  const response = await http.get<any>(
    `/model-manager-api/document-repo/document/${documentId}?companyId=${companyId}&sectionId=${sectionId}`
  );
  return response.data;
}

export async function getDocumentAttachment(
  document_id: string,
  company_id: string,
  section_id: string
): Promise<IDocumentAttachment[]> {
  const responseFromWeb = await http.get<any>(
    "/model-manager-api/document-repo/attachments",
    { params: { document_id, company_id, section_id } }
  );

  const documentsAttachments = responseFromWeb.data
    .data as IDocumentAttachment[];

  return documentsAttachments;
}

export async function uploadAttachment(formData: any) {
  const responseFromWeb = await http.post<any>(
    "/model-manager-api/document-repo/attachment",
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return responseFromWeb;
}

export async function editAttachment(formData: any) {
  const responseFromWeb = await http.put<any>(
    "/model-manager-api/document-repo/attachment",
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return responseFromWeb;
}

export async function deleteAttachment(params: Record<string, string>) {
  const id = params.id;
  delete params.id;
  const responseFromWeb = await http.delete<any>(
    "/model-manager-api/document-repo/attachment/" + id,
    { params }
  );
  return responseFromWeb;
}

export async function getDocuentAttachmentDownload(
  attachmentId: string,
  document_id: string,
  company_id: string,
  section_id: string
): Promise<any> {
  const response = await http.get<any>(
    `/model-manager-api/document-repo/attachment/${attachmentId}?document_id=${document_id}&company_id=${company_id}&section_id=${section_id}`,
    { responseType: "blob" }
  );

  return response.data;
}

export async function getOtp(pin: string, partitionName: string) {
  const response = await http.post<{ message: string }>(
    "/document-manager-api/digital/sign/otp",
    { pin, partitionName }
  );
  return response.data.message;
}

export async function bumpStatus(params: Record<string, any>) {
  const response = await http.post(
    `/model-manager-api/workflow/engine/execute/action`,
    params
  );
  return response.data;
}

export async function getDocumentHistory(params: {
  documentId: string;
  companyId: string;
  sectionId: string;
}): Promise<any> {
  const response = await http.get<{
    history: IHistory[];
    instanceCode: string;
  }>(`/model-manager-api/document-repo/document/history/${params.documentId}`, {
    params: {
      companyId: params.companyId,
      sectionId: params.sectionId,
    },
  });
  return response?.data;
}

export async function getCertificates() {
  const response = await http.get<{ certificates: ICertificate[] }>(
    "/document-manager-api/digital/sign/certificates"
  );
  return response.data.certificates;
}

export async function getComments({
  documentId,
  ...params
}: Record<string, string>) {
  const response = await http.get<{
    comments: Comment[];
    instance_code: string;
  }>(`/model-manager-api/document-repo/document/${documentId}/comments`, {
    params,
  });
  return response.data;
}
export async function createComment(body: Record<string, string>) {
  const response = await http.post(
    `/model-manager-api/document-repo/document/comment`,
    body
  );
  return response.data;
}
export async function editDocumentDetail(body: {
  documentId: string;
  fields: Record<string, string>[];
}) {
  const response = await http.put(
    `/model-manager-api/document-repo/document/${body.documentId}`,
    { fields: body.fields }
  );
  return response.data;
}

export async function getDocumentPlaceholders({
  attachmentId,
  ...params
}: Record<string, string>) {
  const response = await http.get(
    `/document-manager-api/dms-document/placeholders-attachment/${attachmentId}`,
    { params }
  );
  return response.data;
}

export async function createDocumentPlaceholders(body: Record<string, string>) {
  const response = await http.post(
    `/document-manager-api/dms-document/placeholders-attachments`,
    body
  );
  return response.data;
}

export async function editDocumentPlaceholders(body: Record<string, string>) {
  const response = await http.put(
    `/document-manager-api/dms-document/placeholders-attachments`,
    body
  );
  return response.data;
}

export async function deleteDocumentPlaceholders({
  ids,
  ...params
}: Record<string, any>) {
  let idsParams = "";
  ids.forEach((id: string) => (idsParams += "ids=" + id + "&"));
  const response = await http.delete(
    `/document-manager-api/dms-document/placeholders-attachments?${idsParams}`,
    { params }
  );
  return response.data;
}

export async function getAuthGroupUsers({
  companyId,
  authGroupId,
}: {
  companyId: string;
  authGroupId: string;
}) {
  const response = await http.post(
    `/document-manager-api/dms-document/document/assignees`,
    {
      company_id: companyId,
      authGroupCode: authGroupId,
    }
  );
  return response.data;
}
