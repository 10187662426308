import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import EniLogo from "../../logo.svg";
import Button from "../library/Button/Button";
import style from "./Unauthenticated.module.scss";
import { isMobile } from "react-device-detect";

const Unauthenticated = (props: any) => {
  const { instance } = useMsal();

  const handleLoginPopup = () => {
    if (isMobile) {
      instance.loginRedirect({
        ...loginRequest,
        redirectUri: "/",
      })
      return
    }
    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: "/",
      })
  };

  return (
    <div className={style.container}>
      <div className={style.imgContainer}>
        <img
          className={style.img}
          src={EniLogo}
          alt="Eni Logo"
          width={208}
          height={54}
        />
      </div>
      <div className={style.loginContainer}>
        <div className={style.button}>
          <Button label="Sign in using Popup" onClick={handleLoginPopup} />
        </div>
      </div>
    </div>
  );
};

export default Unauthenticated;
