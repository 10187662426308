import { useCallback, useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";

import { b2cPolicies } from "./authConfig";
import { compareIssuingPolicy } from "./utils/claimUtils";

import PrivateRoute from "./router/PrivateRoute/PrivateRoute";
import Unauthenticated from "./components/Unauthenticated/Unauthenticated";
import { Path, paths } from "./app/utils";
import http, { HTTP_STATUS_TOAST } from "./utils/http";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  addToastMessage,
  setForceReloadData,
  setLanguageLoaded,
  setProfiles,
  setReloadMenuTranslation,
  setSelectedCompany,
  setUsername,
} from "./app/redux/common.slice";
import ToastMessage from "./components/library/ToastMessage/ToastMessage";
import {
  fetchGetBannersThunk,
  fetchGetCompaniesThunk,
  fetchGetLanguagesThunk,
  fetchGetMenuThunk,
  fetchPostLoginThunk,
} from "./app/redux/common.thunk";
import {
  selectCompanyList,
  selectCompanyListLoaded,
  selectCurrentLanguage,
  selectLanguages,
  selectMenuConfiguration,
  selectSelectedCompany,
} from "./app/redux/common.selector";
import Spinner from "./components/library/Spinner/Spinner";
import { SessionExpiredDialog } from "./components/Unauthenticated/SessionExpiredDialog";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import it from "./app/i18n/it.json";
import en from "./app/i18n/en.json";

const i18nMap: Record<string, Record<string, string>> = {
  it,
  en,
};

const flatPaths = (pathList: Path[]) => {
  let result: Path[] = [];
  pathList.forEach((a) => {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flatPaths(a.children));
    }
  });
  return result;
};

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const [pathList] = useState<Path[]>(flatPaths(paths));
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const menuConfiguration = useAppSelector(selectMenuConfiguration);
  const companyList = useAppSelector(selectCompanyList);
  const companyListLoaded = useAppSelector(selectCompanyListLoaded);
  const company = useAppSelector(selectSelectedCompany);

  useEffect(() => {
    if (companyList.length > 0) {
      const companyId =
        searchParams.get("companyId") || companyList[0].companyId;
      dispatch(
        setSelectedCompany(
          companyList.find((c) => c.companyId === companyId) || companyList[0]
        )
      );
    }
    // eslint-disable-next-line
  }, [companyList, dispatch]);

  useEffect(() => {
    if (companyListLoaded && !companyList.length) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [companyListLoaded, companyList]);

  useEffect(() => {
    if (!!company) {
      dispatch(fetchGetLanguagesThunk(company?.companyId || ""));
      dispatch(
        fetchGetMenuThunk({
          companyId: company?.companyId || "",
          callback: (isEmptyUser: boolean) => {
            if (isEmptyUser) navigate("/not-enabled");
            setLoading(false);
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [company?.companyId, dispatch]);

  useEffect(() => {
    if (
      menuConfiguration.length > 0 &&
      (window.location.pathname.indexOf("list") > -1 ||
        window.location.pathname === "/")
    ) {
      const menu = menuConfiguration[0].sections[0];
      const location = window.location;
      const companyId = searchParams.get("companyId") || company?.companyId;
      const sectionLocationPath = location.pathname.split("/")[2];
      const areaId =
        companyId === company?.companyId
          ? searchParams.get("areaId") || menuConfiguration[0].id
          : menuConfiguration[0].id;
      const sectionId =
        companyId === company?.companyId
          ? sectionLocationPath || menu.id
          : menu.id;
      const processIds =
        companyId === company?.companyId
          ? searchParams.get("processIds") || menu.processes.map((p) => p.id)
          : menu.processes.map((p) => p.id);
      const documentCode = !!searchParams.get("documentCode")
        ? `&documentCode=${searchParams.get("documentCode")}`
        : "";
      navigate(
        `/list/${sectionId}?processIds=${processIds}&areaId=${areaId}&companyId=${company?.companyId}${documentCode}`
      );
      dispatch(
        fetchGetBannersThunk({
          companyId: company?.companyId || "",
          sectionId,
        })
      );
      dispatch(
        setProfiles(
          menuConfiguration
            .find((a) => a.id === areaId)
            ?.sections.find((s) => s.id === sectionId)?.profiles || []
        )
      );
      // Change company with same section, force reload documents in useTableConfigHook
      dispatch(
        setForceReloadData(
          !!sectionLocationPath &&
            sectionLocationPath === sectionId &&
            companyId !== company?.companyId
        )
      );
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [menuConfiguration, dispatch]);

  useEffect(() => {
    const fetchPostLogin = async () => await dispatch(fetchPostLoginThunk());

    if (!!instance && !!instance.getAllAccounts()[0]) {
      dispatch(setUsername(instance.getAllAccounts()[0].name || ""));
      fetchPostLogin().then(() => dispatch(fetchGetCompaniesThunk()));
    }

    const callbackId = instance.addEventCallback(async (event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        dispatch(setUsername(event.payload.account.name));
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.editProfile
          )
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account: any) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                compareIssuingPolicy(
                  account.idTokenClaims,
                  b2cPolicies.names.signUpSignIn
                )
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Routes>
      {pathList.map((item) => {
        const Component = item.element as any;
        return (
          <Route key={item.path} path={item.path} element={<Component />} />
        );
      })}
    </Routes>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: any) => {
  const dispatch = useAppDispatch();
  const [loadingI18n, setLoadingI18n] = useState<boolean>(true);

  const [isSessionExpiredVisible, setIsSessionExpiredVisible] = useState(false);
  const languages = useAppSelector(selectLanguages);
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (HTTP_STATUS_TOAST.indexOf(error.response?.status) > -1) {
        dispatch(
          addToastMessage({
            // title: error.response.data.error,
            message: error.response.data.message,
            type: "error",
          })
        );
        throw new Error();
      }
      if (error.response && error.response.status === 421) {
        setIsSessionExpiredVisible(true);
      }
    }
  );

  const getTranslation = useCallback(
    async (lng: string) => {
      const language_id = languages.find(
        (l) => l.code === lng.toUpperCase()
      )?.id;
      let res;
      let dictionary = { ...i18nMap[lng] };
      if (!!language_id) {
        res = await http.get<any>(
          `/model-manager-api/master-data/dictionaries`,
          {
            params: {
              language_id,
            },
          }
        );
        dictionary = { ...(res?.data ?? {}), ...dictionary };
      }
      return dictionary;
    },
    [languages]
  );

  useEffect(() => {
    i18next
      .use(initReactI18next) // passes i18n down to react-i18next
      .use(Backend)
      .init({
        backend: {
          loadPath: "{{lng}}",
          request: (options: any, url: string, payload: any, callback: any) => {
            callback(null, {
              data: {},
              status: 200,
            });
            getTranslation(url).then((res: any) => {
              callback(null, {
                data: res,
                status: 200,
              });
              dispatch(setLanguageLoaded(true));
              dispatch(setReloadMenuTranslation(true));
              setTimeout(() => {
                dispatch(setLanguageLoaded(false));
                dispatch(setReloadMenuTranslation(false));
              }, 1000);
              setLoadingI18n(false);
            });
          },
          crossDomain: true,
        },
        react: {
          wait: true,
        },
        lng: currentLanguage ?? "it", // if you're using a language detector, do not define the lng option
        fallbackLng: "it",
        interpolation: {
          escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
      } as any);
    // eslint-disable-next-line
  }, [getTranslation, languages]);

  return (
    <>
      <MsalProvider instance={instance}>
        <UnauthenticatedTemplate>
          <Unauthenticated />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          {!loadingI18n && (
            <PrivateRoute>
              <Pages />
              <SessionExpiredDialog isVisible={isSessionExpiredVisible} />
            </PrivateRoute>
          )}
        </AuthenticatedTemplate>
        <ToastMessage />
      </MsalProvider>
    </>
  );
};

export default App;
