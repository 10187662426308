import { Dialog } from "primereact/dialog";
import { ReactNode } from "react";
import style from "./HistoryDialog.module.scss";

export const HistoryDialog = (props: {
  title: string;
  isVisible: boolean;
  onHide: VoidFunction;
  children: ReactNode;
}) => {
  return (
    <Dialog
      style={{ width: "45vw" }}
      className={style.container}
      header={props.title}
      headerClassName={style.header}
      visible={props.isVisible}
      onHide={() => {
        if (props.onHide) {
          props.onHide();
        }
      }}
      closeIcon={<i className="pi pi-times-circle" />}
    >
      {props.children}
    </Dialog>
  );
};
