import { Tooltip } from "primereact/tooltip";
import style from "./StepBar.module.scss";
import { useLayoutEffect, useState } from "react";

export interface StepBarProps {
  steps: { label: string; className: string }[];
}

const StepBar = ({ steps }: StepBarProps) => {
  const [width, setWidth] = useState(window.innerWidth);
  const hideLabel = (className: string) => {
    if (window.innerWidth > 1280)
      return steps.length > 7 && className !== "highlight";
    if (window.innerWidth < 1280)
      return steps.length > 4 && className !== "highlight";
  };

  useLayoutEffect(() => {
    const checkWidth = () => {
      setWidth(window.innerWidth);
    };
    // if (window.innerWidth > 1280)
    //   return steps.length > 7 && className !== "highlight";
    // if (window.innerWidth < 1280)
    //   return steps.length > 4 && className !== "highlight";
    window.addEventListener("resize", checkWidth);
    checkWidth();
    return () => window.removeEventListener("resize", checkWidth);
  }, []);
  return (
    <div className={style.container}>
      {steps.map((s, i) => (
        <div className={`${style.itemContainer} ${style[s.className]}`} key={i}>
          <div className={style.item}>
            <div className={`${style.number} tooltip-${i + 1}`}>{i + 1}</div>

            <div
              className={style.label}
              style={{
                display:
                  s.className !== "highlight" &&
                  ((width > 1280 && steps.length > 7) ||
                    (width < 1280 && steps.length > 4))
                    ? "none"
                    : "block",
              }}
            >
              {s.label}
            </div>
          </div>
          {hideLabel(s.className) && (
            <Tooltip
              className={style.tooltip}
              target={`.tooltip-${i + 1}`}
              content={s.label}
              position="bottom"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default StepBar;
