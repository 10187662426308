import { useAppDispatch, useAppSelector } from "@/app/hooks";
import EniLogo from "../../logo.svg";
import CompanySelection from "./CompanySelection";
import style from "./Navbar.module.scss";
import UserSettings from "./UserSettings";
import { setCurrentLanguage, setShowMenu } from "@/app/redux/common.slice";
import { Tooltip } from "primereact/tooltip";
import { useMsal } from "@azure/msal-react";
import ReactFlagsSelect from "react-flags-select";
import { selectCurrentLanguage, selectLanguages } from "@/app/redux/common.selector";
import { useTranslation } from "react-i18next";
import { fetchSaveLanguageThunk } from "@/app/redux/common.thunk";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const {i18n} = useTranslation();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const languages = useAppSelector(selectLanguages);

  const onChangeLanguage = (code: string) => {
    dispatch(setCurrentLanguage(code));
    dispatch(fetchSaveLanguageThunk(languages.find(l => l.code === code).id))
    i18n.changeLanguage(code)
  };

  const onClickBars = () => {
    dispatch(setShowMenu());
  };

  const onLogout = () => {
    instance.logout();
  };

  const goToUserSettings = () => {
    navigate('/user-settings')
  }

  return (
    <div className={style.navbar_container}>
      <div className={style.navbar_container_left}>
        <i className="pi pi-bars" onClick={onClickBars} />
        <img src={EniLogo} alt="Eni Logo" width={104} height={27} />
      </div>

      <div className={`${style.navbar_container_right} ${style.languagesEnabled}`}>
        <CompanySelection />
        <div className={style.user} onClick={goToUserSettings}>
          <UserSettings />
        </div>
        {languages.length > 1 && <ReactFlagsSelect
          className={style.flagSelect}
          selected={currentLanguage.toUpperCase()}
          onSelect={onChangeLanguage}
          showOptionLabel={false}
          showSelectedLabel={false}
          countries={[...languages].map(l => l.code)}
          placeholder=" "
        />}
        <div className={`${style.logout} ${style.languagesEnabled}`} onClick={onLogout}>
          <Tooltip target=".custom-target-icon" className={style.tooltip} />
          <i
            className="custom-target-icon pi pi-sign-out"
            data-pr-position="left"
            data-pr-tooltip="Logout"
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
