import { RootState } from "@/app/store";

export const SECTION_NAMESPACE = "section";

export const selectSectionConfig = (state: RootState) =>
  state[SECTION_NAMESPACE].configuration;
export const selectSectionDocuments = (state: RootState) =>
  state[SECTION_NAMESPACE].documents;
export const selectDocumentWorkflow = (state: RootState) =>
  state[SECTION_NAMESPACE].documentWorkflow;
export const selectSelectedDocument = (state: RootState) =>
  state[SECTION_NAMESPACE].selectedDocument;
export const selectDocumentDetails = (state: RootState) =>
  state[SECTION_NAMESPACE].documentDetails;
export const selectDocumentDetailsLoading = (state: RootState) =>
  state[SECTION_NAMESPACE].documentDetailsLoading;
export const selectDocumentStepsLoading = (state: RootState) =>
  state[SECTION_NAMESPACE].documentStepsLoading;
export const selectLoading = (state: RootState) =>
  state[SECTION_NAMESPACE].loading;
export const selectDocumentAttachment = (state: RootState) =>
  state[SECTION_NAMESPACE].documentAttachment;
export const selectPaginator = (state: RootState) =>
  state[SECTION_NAMESPACE].paginator;
export const selectDocFilters = (state: RootState) =>
  state[SECTION_NAMESPACE].docFilters;
export const selectActionDialogLoading = (state: RootState) =>
  state[SECTION_NAMESPACE].actionDialogLoading;
export const selectCertificates = (state: RootState) =>
  state[SECTION_NAMESPACE].certificates;
export const selectDocumentHistory = (state: RootState) =>
  state[SECTION_NAMESPACE].documentHistory;
export const selectHasAttachmentsPermission = (state: RootState) =>
  state[SECTION_NAMESPACE].hasAttachmentsPermission;
export const selectLoadingConfiguration = (state: RootState) =>
  state[SECTION_NAMESPACE].loadingConfiguration;
export const selectMassiveActionDocuments = (state: RootState) =>
  state[SECTION_NAMESPACE].massiveActionDocuments;
export const selectColumnTranslationsMap = (state: RootState) =>
  state[SECTION_NAMESPACE].columnTranslationsMap;
export const selectPlaceholderCoordMap = (state: RootState) =>
  state[SECTION_NAMESPACE].placeholderCoordMap;
export const selectInitializationFormList = (state: RootState) =>
  state[SECTION_NAMESPACE].initializationFormList;
export const selectDocumentIntanceCode = (state: RootState) =>
  state[SECTION_NAMESPACE].documentInstanceCode;
