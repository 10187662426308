import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useEffect, useState } from "react";
import {
  selectDocumentAttachment,
  selectHasAttachmentsPermission,
  selectSelectedDocument,
} from "@/components/Section/redux/section.selector";
import {
  fetchGetDocumentAttachmentDownloadThunk,
  fetchGetDocumentAttachmentThunk,
  fetchDocumentAttachmentUploadThunk,
  fetchDocumentAttachmentDeleteThunk,
  fetchDocumentAttachmentEditThunk,
} from "@/components/Section/redux/section.thunk";
import {
  selectMenuConfiguration,
  selectSelectedCompany,
} from "@/app/redux/common.selector";
import { saveAs } from "file-saver";
import Accordion from "../library/Accordion/Accordion";
import { CrudButton, CrudButtonType } from "@library/CrudButton/CrudButton";
import SecondaryTable from "../library/Table/SecondaryTable/SecondaryTable";
import docIcon from "../../assets/docIcon.png";
import pdfIcon from "../../assets/pdfIcon.png";
import pdfSignedIcon from "../../assets/pdfSignedIcon.png";
import xlsxIcon from "../../assets/xlsxIcon.png";
import genericFileIcon from "../../assets/genericFileIcon.png";
import style from "./DocumentAttachments.module.scss";
import { addToastMessage } from "@/app/redux/common.slice";
import { IAttachmentTableData } from "./redux/section.models";
import { DocumentAttachmentsUploadDialog } from "./DocumentAttachmentsUploadDialog";
import Spinner from "../library/Spinner/Spinner";
import { DocumentPlaceholderDialog } from "./DocumentPlacehoderDialog";
import { Tooltip } from "primereact/tooltip";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const fileIconMap: Record<string, any> = {
  doc: docIcon,
  docx: docIcon,
  pdf: pdfIcon,
  xls: xlsxIcon,
  xlsx: xlsxIcon,
};

export const getIcon = (rowData: any) => {
  return (
    <div className={style.extIconContainer}>
      <img
        src={
          rowData.signed
            ? pdfSignedIcon
            : fileIconMap[rowData.extension.toLowerCase()] || genericFileIcon
        }
        alt=""
        width={"25px"}
      />
    </div>
  );
};

export interface DocumentAttachmentsProps {
  selectedDocumentHistoryId: string;
}

const DocumentAttachments = ({
  selectedDocumentHistoryId,
}: DocumentAttachmentsProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [crudLoading, setCrudLoading] = useState<Record<string, boolean>>({});
  const [documentPreviewId, setDocumentPreviewId] = useState<string>("");
  const [isPreviewReadOnly, setIsPreviewReadOnly] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showDocumentPlacholdersDialog, setShowDocumentPlacholdersDialog] =
    useState<boolean>(false);
  const [uploadCategories, setUploadCategories] = useState<any[]>([]);
  const documentSelector = useAppSelector(selectSelectedDocument);
  const documentAttachmentSelector = useAppSelector(selectDocumentAttachment);
  const company = useAppSelector(selectSelectedCompany);
  const hasAttachmentsPermission = useAppSelector(
    selectHasAttachmentsPermission
  );
  const menuConfiguration = useAppSelector(selectMenuConfiguration);
  const [isSystemUser] = useState<boolean>(
    !!menuConfiguration
      .find((a) => a.id === searchParams.get("areaId"))
      ?.sections.find((s) => s.id === window.location.pathname.split("/")[2])
      ?.processes.find((p) => p.id === searchParams.get("processIds"))
      ?.profiles.find((p) => p.code === "SYSTEM")
  );

  useEffect(() => {
    if (!!documentSelector.id || !!selectedDocumentHistoryId) {
      dispatch(
        fetchGetDocumentAttachmentThunk({
          documentId: selectedDocumentHistoryId || documentSelector.id,
          companyId: company?.companyId || "",
          sectionId: window.location.pathname.split("/")[2] || "",
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, selectedDocumentHistoryId]);

  const onDownload = async (rowData: IAttachmentTableData) => {
    setCrudLoading((value) => ({ ...value, [rowData.id]: true }));
    const response = await dispatch(
      fetchGetDocumentAttachmentDownloadThunk({
        attachmentId: rowData.id,
        document_id: documentSelector.id,
        company_id: company?.companyId || "",
        section_id: window.location.pathname.split("/")[2] || "",
      })
    );
    setCrudLoading((value) => ({ ...value, [rowData.id]: false }));

    try {
      const fileName = `${rowData.title}.${rowData.extension}`;
      saveAs(response.payload, fileName);
    } catch (error) {
      dispatch(
        addToastMessage({
          title: "Errore",
          message: "Errore nel download del file",
          type: "error",
        })
      );
    }
  };

  const getFormData = (e: any) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("companyId", company?.companyId || "");
    formData.append("sectionId", window.location.pathname.split("/")[2] || "");
    formData.append("documentId", documentSelector.id || "");
    return formData;
  };

  const onUploadFile = async ({
    e,
    attachmentFolderId,
  }: {
    e: any;
    attachmentFolderId: string;
  }) => {
    const formData = getFormData(e);
    formData.append("attachmentFolderId", attachmentFolderId || "");
    setCrudLoading((value) => ({
      ...value,
      [attachmentFolderId]: true,
    }));
    await dispatch(fetchDocumentAttachmentUploadThunk(formData));
    await dispatch(
      fetchGetDocumentAttachmentThunk({
        documentId: documentSelector.id,
        companyId: company?.companyId || "",
        sectionId: window.location.pathname.split("/")[2] || "",
      })
    );
    setShowUploadDialog(false);
    setCrudLoading((value) => ({
      ...value,
      [attachmentFolderId]: false,
    }));
  };

  const onEditFile = async ({
    e,
    attachmentId,
    attachmentFolderId,
  }: {
    e: any;
    attachmentId: string;
    attachmentFolderId: string;
  }) => {
    const formData = getFormData(e);
    formData.append("attachmentId", attachmentId || "");
    setCrudLoading((value) => ({
      ...value,
      [attachmentFolderId]: true,
    }));
    await dispatch(fetchDocumentAttachmentEditThunk(formData));
    await dispatch(
      fetchGetDocumentAttachmentThunk({
        documentId: documentSelector.id,
        companyId: company?.companyId || "",
        sectionId: window.location.pathname.split("/")[2] || "",
      })
    );
    setCrudLoading((value) => ({
      ...value,
      [attachmentFolderId]: false,
    }));
  };

  const onPreview = (rowData: IAttachmentTableData) => {
    setDocumentPreviewId(rowData.id);
    setIsPreviewReadOnly(
      rowData.placeholder_attachment_signed_models.length > 0 ||
        rowData.placeholder_attachment_models.filter((p) => p.signed).length > 0
    );
    setShowDocumentPlacholdersDialog(true);
  };

  const columns = [
    { field: "extension", header: "", template: getIcon },
    {
      field: "title",
      header: "",
      template: (rowData: IAttachmentTableData) => (
        <span>
          {rowData.title}.{rowData.extension.toLowerCase()}
        </span>
      ),
    },
    // {
    //   field: "placeholder",
    //   header: "",
    //   template: (rowData: IAttachmentTableData) => (
    //     <span>
    //       {rowData.placeholder_attachment_models.length} Segnaposto
    //     </span>
    //   ),
    // },
    {
      field: "action",
      header: "",
      template: (rowData: IAttachmentTableData) => {
        return (
          <div className={style.crudContainer}>
            {(hasAttachmentsPermission || isSystemUser) &&
              rowData.hasPlaceholder && (
                <>
                  {rowData.placeholder_attachment_models.length > 0 && (
                    <div className={style.crudSpacing}>
                      <span style={{ fontStyle: "oblique" }}>
                        {rowData.placeholder_attachment_models.length}{" "}
                        {t("UI_PLACEHOLDERS_LABEL")}
                      </span>
                    </div>
                  )}
                  {rowData.placeholder_attachment_signed_models.length > 0 && (
                    <div className={style.crudSpacing}>
                      <Tooltip
                        target=".target-tooltip"
                        className={style.tooltip}
                      />
                      <div
                        className={`${style.placholderPercentage} target-tooltip`}
                        data-pr-tooltip={`${
                          rowData.placeholder_attachment_signed_models?.filter(
                            (p) => p.signed
                          )?.length
                        }/${
                          rowData.placeholder_attachment_signed_models?.length
                        }`}
                        data-pr-position="top"
                      >
                        {(
                          (rowData.placeholder_attachment_signed_models?.filter(
                            (p) => p.signed
                          )?.length /
                            rowData.placeholder_attachment_signed_models
                              ?.length) *
                          100
                        ).toFixed(0)}
                        %
                      </div>
                    </div>
                  )}
                  <div className={style.crudSpacing}>
                    <CrudButton
                      type={CrudButtonType.preview}
                      onClick={() => onPreview(rowData)}
                    />
                  </div>
                </>
              )}
            <div className={style.crudSpacing}>
              {!crudLoading[rowData.id] && (
                <CrudButton
                  type={CrudButtonType.download}
                  onClick={() => onDownload(rowData)}
                  value={{ ext: rowData.extension }}
                />
              )}
              {crudLoading[rowData.id] && <Spinner className={style.spinner} />}
            </div>
            {((hasAttachmentsPermission && rowData.editable) ||
              isSystemUser) && (
              <>
                <div className={style.crudSpacing}>
                  <CrudButton
                    type={CrudButtonType.edit}
                    onClick={() => {
                      const input = document.createElement("input");
                      input.type = "file";
                      input.onchange = (e) =>
                        onEditFile({
                          e,
                          attachmentId: rowData.id,
                          attachmentFolderId: rowData.attachment_folder_id,
                        });
                      input.click();
                    }}
                  />
                </div>
                <div className={style.crudSpacing}>
                  <CrudButton
                    type={CrudButtonType.delete}
                    onClick={async () => {
                      setCrudLoading((value) => ({
                        ...value,
                        [rowData.attachment_folder_id]: true,
                      }));
                      await dispatch(
                        fetchDocumentAttachmentDeleteThunk({
                          id: rowData.id,
                          document_id: documentSelector.id,
                          company_id: company?.companyId || "",
                          section_id:
                            window.location.pathname.split("/")[2] || "",
                        })
                      );
                      await dispatch(
                        fetchGetDocumentAttachmentThunk({
                          documentId: documentSelector.id,
                          companyId: company?.companyId || "",
                          sectionId:
                            window.location.pathname.split("/")[2] || "",
                        })
                      );
                      setCrudLoading((value) => ({
                        ...value,
                        [rowData.attachment_folder_id]: false,
                      }));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const onHideUploadDialog = () => setShowUploadDialog(false);
  const onHideDocumentPlacholdersDialog = () =>
    setShowDocumentPlacholdersDialog(false);

  return (
    <div className={style.container}>
      {documentAttachmentSelector.map((group, index) => {
        const folder = group.folder;
        let enableUpload = folder.editable;
        let attachments = folder.document_attachment_models.map((d) => ({
          ...d,
          title: decodeURIComponent(d.title),
          editable: folder.editable,
          hasPlaceholder:
            folder.placeholder_folder_models[0]?.type === "ENHANCED" ||
            folder.placeholder_folder_models[0]?.type === "ENHANCED_FINAL",
        }));
        group.children?.forEach((c) => {
          enableUpload = enableUpload || c.folder.editable;
          attachments = attachments.concat(
            c.folder.document_attachment_models.map((d) => ({
              ...d,
              title: decodeURIComponent(d.title),
              editable: c.folder.editable,
              hasPlaceholder:
                c.folder.placeholder_folder_models[0]?.type === "ENHANCED" ||
                c.folder.placeholder_folder_models[0]?.type ===
                  "ENHANCED_FINAL",
            }))
          );
        });

        return (
          <Accordion
            key={folder.id}
            title={folder.description}
            isOpen={true}
            iconButton={
              (hasAttachmentsPermission && enableUpload) || isSystemUser
                ? crudLoading[folder.id]
                  ? "spin pi-spinner"
                  : "upload"
                : ""
            }
            onIconButtonClick={(e) => {
              if ((hasAttachmentsPermission && enableUpload) || isSystemUser) {
                e.stopPropagation();
                if (!!group.children?.length) {
                  setUploadCategories([
                    {
                      label: folder.name,
                      value: folder.id,
                      editable: folder.editable,
                    },
                    ...group.children.map((c) => ({
                      label: c.folder.name,
                      value: c.folder.id,
                      editable: c.folder.editable,
                    })),
                  ]);
                  setShowUploadDialog(true);
                  return;
                }
                const input = document.createElement("input");
                input.type = "file";
                input.onchange = (e) =>
                  onUploadFile({
                    e,
                    attachmentFolderId: folder.id,
                  });
                input.click();
              }
            }}
          >
            <div
              style={{ overflowY: "scroll", maxHeight: 400 }}
              className={style.accordionContent}
            >
              <SecondaryTable
                columns={columns}
                data={attachments}
                customClass={style.table}
              />
            </div>
          </Accordion>
        );
      })}
      {showUploadDialog && (
        <DocumentAttachmentsUploadDialog
          onHide={onHideUploadDialog}
          visible={showUploadDialog}
          categories={uploadCategories}
          onUpload={onUploadFile}
        />
      )}
      {showDocumentPlacholdersDialog && (
        <DocumentPlaceholderDialog
          isVisible={showDocumentPlacholdersDialog}
          onHide={onHideDocumentPlacholdersDialog}
          companyId={company?.companyId || ""}
          documentId={documentSelector.id}
          attachmentId={documentPreviewId}
          readOnly={isPreviewReadOnly}
        />
      )}
    </div>
  );
};

export default DocumentAttachments;
