import { Paginator as PRPaginator } from "primereact/paginator";
import { useState } from "react";
import style from "./Paginator.module.scss";

export interface PaginatorChange {
  rows: number;
  page: number;
  first?: number;
}
export interface PaginatorProps {
  rows: number;
  totalRecords: number;
  first?: number;
  onPageChange: (changes: PaginatorChange) => void;
}

const Paginator = ({
  rows,
  first,
  onPageChange,
  ...params
}: PaginatorProps) => {
  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRows, setLocalRows] = useState(rows);

  const onPageChangeHandler = ({
    rows,
    first,
    pageCount,
    page,
    ...params
  }: any) => {
    setLocalFirst(first);
    setLocalRows(rows);
    onPageChange({ rows, page, first });
  };

  const template = {
    layout:
      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    FirstPageLink: (options: any) => {
      return (
        <button
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className={`${style.buttonIcon} pi pi-angle-double-left`} />
        </button>
      );
    },
    PrevPageLink: (options: any) => {
      return (
        <button
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className={`${style.buttonIcon} pi pi-angle-left`} />
          {/* <span className={style.buttonLabel}>Prev</span> */}
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          {/* <span className={style.buttonLabel}>Next</span> */}
          <i className={`${style.buttonIcon} pi pi-angle-right`} />
        </button>
      );
    },
    LastPageLink: (options: any) => {
      return (
        <button
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <i className={`${style.buttonIcon} pi pi-angle-double-right`} />
        </button>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span className={style.currentPageReport}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };
  return (
    <div className={style.container}>
      <PRPaginator
        className={style.wrapper}
        {...params}
        rows={localRows}
        template={template}
        pageLinkSize={5}
        first={localFirst}
        onPageChange={onPageChangeHandler}
      />
    </div>
  );
};

export default Paginator;
