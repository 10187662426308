import { Dialog } from "primereact/dialog";
import Button from "../library/Button/Button";
import style from "./DocumentConfirmPopup.module.scss";
import { useAppSelector } from "@/app/hooks";
import { selectActionDialogLoading } from "../Section/redux/section.selector";

export const DocumentConfirmPopup = (props: {
  isVisible: boolean;
  description: string;
  onApprove: VoidFunction;
  onHide: VoidFunction;
}) => {
  const lineBreakDescription = props.description.split(/\.|\?|!/);

  const loading = useAppSelector(selectActionDialogLoading);

  const footerContent = (
    <div className={style.customFooterContainer}>
      <div>
        <Button
          label="Ok"
          onClick={props.onApprove}
          isLoading={loading}
        />
      </div>
    </div>
  );

  return (
    <>
      <Dialog
        style={{ width: "40vw" }}
        className={style.container}
        headerClassName={style.header}
        header={"Attenzione"}
        visible={props.isVisible}
        onHide={() => {
          if (props.onHide) {
            props.onHide();
          }
        }}
        footer={footerContent}
        closeIcon={<i className="pi pi-times-circle" />}
      >
        <div className={style.descriptionContainer}>
          {lineBreakDescription.map((newLine, index) => {
            //preserve punctuation
            if (index !== lineBreakDescription.length - 1) {
              newLine += props.description.charAt(
                props.description.indexOf(newLine) + newLine.length
              );
            }

            return (
              <div key={index} className={style.descriptionLine}>
                <br />
                {newLine}
              </div>
            );
          })}
        </div>
      </Dialog>
    </>
  );
};
