import { createAsyncThunk } from "@reduxjs/toolkit";
import { SECTION_NAMESPACE } from "./section.selector";
import {
  getConfiguration,
  getDocumentDetails,
  getDocuments,
  getDocumentWorkflow,
  getValues,
  getDocumentAttachment,
  getDocuentAttachmentDownload,
  bumpStatus,
  getOtp,
  getDocumentHistory,
  getCertificates,
  uploadAttachment,
  deleteAttachment,
  editAttachment,
  getComments,
  createComment,
  editDocumentDetail,
  getDocumentPlaceholders,
  createDocumentPlaceholders,
  deleteDocumentPlaceholders,
  editDocumentPlaceholders,
  getInitializationFormList,
  createDocumentInit,
  getAuthGroupUsers,
} from "./section.api";
import { DocumentInitBodyRequest } from "./section.models";
import { setLanguageLoaded } from "@/app/redux/common.slice";
// import { addToastMessage } from "@/app/redux/common.slice";

export const SECTION_ACTIONS = {
  FETCH_CONFIGURATION: "fetchConfiguration",
  FETCH_INITIALIZATION_FORM_LIST: "fetchInitilizationFormList",
  FETCH_DOCUMENTS: "fetchDocuments",
  FETCH_DOWNLOAD_DOCUMENTS: "fetchDownloadDocuments",
  FETCH_OPTIONS: "fetchOptions",
  FETCH_DOCUMENT_WORKFLOW: "fetchDocumentWorkflow",
  FETCH_DOCUMENT_DETAILS: "fetchDocumentDetails",
  FETCH_DOCUMENT_ATTACHMENT: "fetchDocumentAttachment",
  FETCH_DOCUMENT_ATTACHMENT_DOWNLOAD: "fetchDocumentAttachmentDownload",
  FETCH_DOCUMENT_ATTACHMENT_UPLOAD: "fetchDocumentAttachmentUpload",
  FETCH_DOCUMENT_ATTACHMENT_EDIT: "fetchDocumentAttachmentEdit",
  FETCH_DOCUMENT_ATTACHMENT_DELETE: "fetchDocumentAttachmentDelete",
  FETCH_BUMP_STATUS: "fetchBumpStatus",
  FETCH_GET_OTP: "fetchGetOtp",
  FETCH_GET_DOCUMENT_HISTORY: "fetchGetDocumentHistory",
  FETCH_GET_CERTIFICATES: "fetchGetCertificates",
  FETCH_GET_COMMENTS: "fetchGetComments",
  FETCH_CREATE_COMMENTS: "fetchCreateComments",
  FETCH_EDIT_DOCUMENT_DETAIL: "fetchEditDocumentDetail",
  FETCH_GET_DOCUMENT_PLACEHOLDERS: "fetchGetDocumentPlaceholders",
  FETCH_CREATE_DOCUMENT_PLACEHOLDERS: "fetchCreateDocumentPlaceholders",
  FETCH_DELETE_DOCUMENT_PLACEHOLDERS: "fetchDeleteDocumentPlaceholders",
  FETCH_EDIT_DOCUMENT_PLACEHOLDERS: "fetchEditDocumentPlaceholders",
  FETCH_CREATE_DOCUMENT_INIT: "fetchCreateDocumentInit",
  FETCH_GET_AUTH_GROUP_USERS: "fetchGetAuthGroupUsers",
};

export const fetchGetConfigurationThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_CONFIGURATION}`,
  async ({
    companyId,
    sectionId,
  }: {
    companyId: string;
    sectionId: string;
  }) => {
    const response: any = await getConfiguration(companyId, sectionId);
    return response;
  }
);

export const fetchGetInitializationFormListThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_INITIALIZATION_FORM_LIST}`,
  async ({
    companyId,
    sectionId,
  }: {
    companyId: string;
    sectionId: string;
  }) => {
    const response: any = await getInitializationFormList(companyId, sectionId);
    return response;
  }
);

export const fetchGetDocumentsThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENTS}`,
  async (params: Record<string, any>, { dispatch }) => {
    const response: any = await getDocuments(params);
    // setTimeout(() => {
    //   dispatch(setLanguageLoaded(false));
    // }, 500);
    return response;
  }
);

export const fetchGetDownloadDocumentsThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOWNLOAD_DOCUMENTS}`,
  async (params: Record<string, any>) => {
    const response: any = await getDocuments(params);
    return response;
  }
);

export const fetchGetValuesThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_OPTIONS}`,
  async ({ callback, ...params }: Record<string, any>) => {
    const response: any = await getValues(params);
    callback(response);
    return response;
  }
);

export const fetchGetDocumentWorkflowThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_WORKFLOW}`,
  async (params: Record<string, string>) => {
    const response: any = await getDocumentWorkflow(params);
    return response;
  }
);

export const fetchGetDocumentDetailsThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_DETAILS}`,
  async (
    {
      documentId,
      companyId,
      sectionId,
    }: {
      documentId: string;
      companyId: string;
      sectionId: string;
    },
    { dispatch }
  ) => {
    const response: any = await getDocumentDetails(
      documentId,
      companyId,
      sectionId
    );
    setTimeout(() => {
      dispatch(setLanguageLoaded(false));
    }, 500);
    return response;
  }
);

export const fetchGetDocumentAttachmentThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_ATTACHMENT}`,

  async ({
    documentId,
    companyId,
    sectionId,
  }: {
    documentId: string;
    companyId: string;
    sectionId: string;
  }) => {
    const response: any = await getDocumentAttachment(
      documentId,
      companyId,
      sectionId
    );
    return response;
  }
);

export const fetchGetDocumentAttachmentDownloadThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_ATTACHMENT_DOWNLOAD}`,
  async ({
    attachmentId,
    document_id,
    company_id,
    section_id,
  }: {
    attachmentId: string;
    document_id: string;
    company_id: string;
    section_id: string;
  }) => {
    const response: any = await getDocuentAttachmentDownload(
      attachmentId,
      document_id,
      company_id,
      section_id
    );

    return response;
  }
);

export const fetchDocumentAttachmentUploadThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_ATTACHMENT_UPLOAD}`,
  async (formData: any) => {
    const response: any = await uploadAttachment(formData);
    return response;
  }
);
export const fetchDocumentAttachmentEditThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_ATTACHMENT_EDIT}`,
  async (formData: any) => {
    const response: any = await editAttachment(formData);
    return response;
  }
);
export const fetchDocumentAttachmentDeleteThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DOCUMENT_ATTACHMENT_DELETE}`,
  async (params: Record<string, string>) => {
    const response: any = await deleteAttachment(params);
    return response;
  }
);

export const fetchBumpStatusThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_BUMP_STATUS}`,
  async (params: Record<string, any>) => {
    const res = await bumpStatus(params);
    return res;
  }
);

export const fetchGetOtpThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_GET_OTP}`,
  async (param: Record<string, string>) => {
    const response: any = await getOtp(param.pin, param.partitionName);
    return response;
  }
);

export const fetchGetDocumentHistoryThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_GET_DOCUMENT_HISTORY}`,
  async (params: {
    documentId: string;
    companyId: string;
    sectionId: string;
  }) => {
    const response: any = await getDocumentHistory(params);
    return response;
  }
);

export const fetchGetCertificatesThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_GET_CERTIFICATES}`,
  async () => {
    const response: any = await getCertificates();
    return response;
  }
);
export const fetchGetCommentsThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_GET_COMMENTS}`,
  async (params: Record<string, string>) => {
    const response: any = await getComments(params);
    return response;
  }
);
export const fetchCreateCommentThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_CREATE_COMMENTS}`,
  async (params: Record<string, string>) => {
    const response: any = await createComment(params);
    return response;
  }
);
export const fetchEditDocumentDetailThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_EDIT_DOCUMENT_DETAIL}`,
  async (params: { documentId: string; fields: Record<string, string>[] }) => {
    const response: any = await editDocumentDetail(params);
    return response;
  }
);

export const fetchGetDocumentPlaceholderslThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_GET_DOCUMENT_PLACEHOLDERS}`,
  async (params: Record<string, string>) => {
    const response: any = await getDocumentPlaceholders(params);
    return response;
  }
);

export const fetchCreateDocumentPlaceholderslThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_CREATE_DOCUMENT_PLACEHOLDERS}`,
  async (params: Record<string, any>) => {
    const response: any = await createDocumentPlaceholders(params);
    return response;
  }
);

export const fetchEditDocumentPlaceholderslThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_EDIT_DOCUMENT_PLACEHOLDERS}`,
  async (params: Record<string, any>) => {
    const response: any = await editDocumentPlaceholders(params);
    return response;
  }
);

export const fetchDeleteDocumentPlaceholderslThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_DELETE_DOCUMENT_PLACEHOLDERS}`,
  async (params: Record<string, any>) => {
    const response: any = await deleteDocumentPlaceholders(params);
    return response;
  }
);
export const fetchCreateDocumentInitlThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_CREATE_DOCUMENT_INIT}`,
  async (body: DocumentInitBodyRequest) => {
    const response: any = await createDocumentInit(body);
    return response;
  }
);
export const fetchGetAuthGroupUsersThunk = createAsyncThunk(
  `${SECTION_NAMESPACE}/${SECTION_ACTIONS.FETCH_GET_AUTH_GROUP_USERS}`,
  async (params: { companyId: string; authGroupId: string }) => {
    const response: any = await getAuthGroupUsers(params);
    return response;
  }
);
