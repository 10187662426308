import {
  Field,
  FilterBody,
  FilterMap,
  FilterTypeEnum,
} from "@/components/Section/redux/section.models";
import Input from "../library/Input/Input";
import Dropdown from "../library/Dropdown/Dropdown";
import Datepicker from "../library/Datepicker/Datepicker";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchGetValuesThunk } from "@/components/Section/redux/section.thunk";
import { selectSelectedCompany } from "@/app/redux/common.selector";
import MultiSelect from "../library/MultiSelect/MultiSelect";
import Autocomplete from "../library/Autocomplete/Autocomplete";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import {
  selectDocFilters,
  selectDocumentIntanceCode,
} from "./redux/section.selector";
import { getTranslation } from "@/app/i18n.utils";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const booleaOptions = [
  {
    label: "Tutti",
    value: "",
  },
  {
    label: "Si",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const useFilterComponentHook = (options?: any) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const company = useAppSelector(selectSelectedCompany);
  const documentInstanceCode = useAppSelector(selectDocumentIntanceCode);
  const [filterMap, setFilterMap] = useState<FilterMap>({});
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});
  const [formInvalid, setFormInvalid] = useState<boolean>(false);
  const docFilters = useAppSelector(selectDocFilters);

  const onFilterChange = (filterBody: FilterBody) => {
    setFilterMap((value) => ({
      ...value,
      [filterBody.field_column_name]: {
        ...value[filterBody.field_column_name],
        ...filterBody.value,
        operation: filterBody.operation,
        value: typeof filterBody.value === "string" && filterBody.value,
      },
    }));
  };

  const getValue = (field: Field) => {
    if (!filterMap[field.field_column_name]) {
      setFilterMap((value) => ({
        ...(typeof value !== "string" ? value : {}),
        [field.field_column_name]: {
          field_id: field.id,
          filter_id: field.filter?.id || "",
          filter_type: field.filter?.form_type || "",
          value:
            !!searchParams.get("documentCode") &&
            field.field_column_name === "instance_code"
              ? searchParams.get("documentCode")
              : "",
          multiselect: [],
          from: "",
          to: "",
          custom_value: field.filter.custom_value,
          options:
            field.filter?.data_value.indexOf("ARRAY") > -1
              ? JSON.parse(
                  field.filter?.data_value.split("ARRAY")[1].replace(/'/g, '"')
                )
              : // .map((o: string) => ({ label: o, code: o }))
                [],
        },
      }));
    }
    return filterMap[field.field_column_name];
  };

  const onLazyLoad = (field: Field, search?: string) => {
    if (
      !options &&
      !!filterMap[field.field_column_name].options?.length &&
      !search
    )
      return;
    if (
      !!options &&
      !!options.valueMap &&
      !!options.valueMap[field.field_column_name].options?.length &&
      !search
    )
      return;
    setLoadingMap((value) => ({
      ...value,
      [field.code]: true,
    }));
    dispatch(
      fetchGetValuesThunk({
        company_id: company?.companyId || "",
        section_id: window.location.pathname.split("/")[2] || "",
        filter_code: field.filter?.data_value || "",
        partial_search: search || null,
        document_instance: documentInstanceCode,
        callback: (opts: any[]) => {
          if (!!options?.setValueMap) {
            options.setValueMap((value: any) => ({
              ...value,
              [field.field_column_name]: {
                ...value[field.field_column_name],
                options: opts,
              },
            }));
            setLoadingMap((value) => ({
              ...value,
              [field.code]: false,
            }));
            return;
          }
          setFilterMap((value) => ({
            ...value,
            [field.field_column_name]: {
              ...value[field.field_column_name],
              options: opts,
            },
          }));
          setLoadingMap((value) => ({
            ...value,
            [field.code]: false,
          }));
        },
      })
    );
  };

  useEffect(() => {
    if (!docFilters.length) {
      setFilterMap({});
      !!options?.setHasFilters && options?.setHasFilters(false);
    }
    // eslint-disable-next-line
  }, [docFilters]);

  const getFilterComponent = (field: Field) => {
    switch (field.filter?.form_type) {
      case FilterTypeEnum.AUTOCOMPLETE_TEXT_INPUT:
        return (
          <Autocomplete
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_WRITE_HERE"),
            }}
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field)?.value
                : getValue(field)?.value
            }
            completeMethod={(e: AutoCompleteCompleteEvent) => {
              if (e.query.length > 2) onLazyLoad(field, e.query);
            }}
            suggestions={
              !!options?.getValue
                ? options.getValue(field)?.options
                : getValue(field)?.options
            }
            field="label"
            onChangeCallback={(value) => {
              setFormInvalid(value.length > 0 && value.length < 3);
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value: (value as any).code || (value as any).label || value,
                operation: "eq",
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
          />
        );
      case FilterTypeEnum.TEXT_INPUT:
        return (
          <Input
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_WRITE_HERE"),
            }}
            iconLeft={
              !options?.hideLabel && !options?.hideIcons ? "search" : ""
            }
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field)?.value
                : getValue(field)?.value
            }
            onChangeCallback={(value) => {
              setFormInvalid(value.length > 0 && value.length < 2);
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
          />
        );
      case FilterTypeEnum.SELECT_VALUE:
        return (
          <Dropdown
            options={
              (options?.valueMap || filterMap)[field.field_column_name]?.options
            }
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_SELECT"),
            }}
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field)?.value
                : getValue(field)?.value
            }
            // optionLabel="label"
            // optionValue="code"
            onChangeCallback={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              //   operation: "eq",
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
                operation: "eq",
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
          />
        );
      case FilterTypeEnum.MULTISELECT_VALUE:
        return (
          <MultiSelect
            options={
              (options?.valueMap || filterMap)[field.field_column_name]?.options
            }
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_SELECT"),
            }}
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field).multiselect
                : getValue(field)?.multiselect
            }
            // optionLabel="label"
            // optionValue="code"
            onChangeCallback={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value: { multiselect: value },
              //   operation: "in",
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value: { multiselect: value },
                operation: "in",
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
          />
        );
      case FilterTypeEnum.BOOLEAN:
        return (
          <Dropdown
            options={booleaOptions}
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_SELECT"),
            }}
            optionLabel="label"
            optionValue="value"
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field)?.value
                : getValue(field)?.value
            }
            onChangeCallback={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              //   operation: "is",
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
                operation: "is",
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
          />
        );
      case FilterTypeEnum.SELECT:
        return (
          <Dropdown
            options={
              (options?.valueMap || filterMap)[field.field_column_name]?.options
            }
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_SELECT"),
            }}
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field)?.value
                : getValue(field)?.value
            }
            optionValue="code"
            optionLabel="label"
            onChangeCallback={(value) => {
              //   onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              //   operation: "eq",
              // })
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
                operation: "eq",
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
            dropdownIcon={
              loadingMap[field.code]
                ? "pi pi-spin pi-spinner"
                : "pi pi-chevron-down"
            }
            onFocus={() => onLazyLoad(field)}
          />
        );
      case FilterTypeEnum.MULTISELECT:
        return (
          <MultiSelect
            options={filterMap[field.field_column_name]?.options}
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_SELECT"),
            }}
            key={field.code}
            value={getValue(field)?.multiselect}
            optionValue="code"
            optionLabel="label"
            onChangeCallback={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value: { multiselect: value },
              //   operation: "in",
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value: { multiselect: value },
                operation: "in",
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
            dropdownIcon={
              loadingMap[field.code]
                ? "pi pi-spin pi-spinner"
                : "pi pi-chevron-down"
            }
            onFocus={() => onLazyLoad(field)}
          />
        );
      case FilterTypeEnum.NUMBER_FROM_TO:
        return (
          <Input
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: "",
            }}
            onChangeCallbackFromTo={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
            isFromTo={{
              inputFrom: {
                placeholder: t("UI_FILTERS_PLACEHOLDER_FROM_NUMBER"),
                value: !!options?.getValue
                  ? options.getValue(field).from
                  : getValue(field)?.from,
                type: "number",
              },
              inputTo: {
                placeholder: t("UI_FILTERS_PLACEHOLDER_TO_NUMBER"),
                value: !!options?.getValue
                  ? options.getValue(field).to
                  : getValue(field)?.to,
                type: "number",
              },
            }}
            key={field.code}
          />
        );
      case FilterTypeEnum.DATE_FROM_TO:
        return (
          <Datepicker
            label={
              !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : ""
            }
            onChangeCallbackFromTo={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
            isFromTo={{
              pickerFrom: {
                dateFormat: "dd/mm/yy",
                placeholder: t("UI_FILTERS_PLACEHOLDER_FROM_DATE"),
                value: !!getValue(field)?.from
                  ? new Date(getValue(field)?.from)
                  : !!options?.getValue
                  ? new Date(options.getValue(field).from)
                  : null,
              },
              pickerTo: {
                dateFormat: "dd/mm/yy",
                placeholder: t("UI_FILTERS_PLACEHOLDER_TO_DATE"),
                value: !!getValue(field)?.to
                  ? new Date(getValue(field)?.to)
                  : !!options?.getValue
                  ? new Date(options.getValue(field).to)
                  : null,
              },
            }}
            key={field.code}
          />
        );
      case FilterTypeEnum.NUMBER_INPUT:
        return (
          <Input
            locale={{
              label: !options?.hideLabel
                ? getTranslation(t, field.code, field.label, i18n)
                : "",
              placeholder: t("UI_FILTERS_PLACEHOLDER_WRITE_HERE"),
            }}
            type="number"
            key={field.code}
            value={
              !!options?.getValue
                ? options.getValue(field)?.value
                : getValue(field)?.value
            }
            onChangeCallback={(value) => {
              // onFilterChange({
              //   filter_id: field.filter?.id || "",
              //   field_column_name: field.field_column_name,
              //   value,
              // });
              const params = {
                filter_id: field.filter?.id || "",
                field_column_name: field.field_column_name,
                value,
              };
              !!options?.onValueChange
                ? options.onValueChange(params)
                : onFilterChange(params);
            }}
          />
        );
    }
  };

  return { filterMap, formInvalid, setFilterMap, getFilterComponent };
};
