import {
  Field,
  FieldTypeEnum,
} from "@/components/Section/redux/section.models";
import IconCell from "../library/Table/custom-components/IconCell";
import BoldCell from "../library/Table/custom-components/BoldCell";
import { useEffect, useState } from "react";
import { TableColumnProps } from "../library/Table/Table";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  resetColumnTranslationsMap,
  setColumnTranslationsMap,
  setPaginator,
  setSelectedDocument,
} from "@/components/Section/redux/section.slice";
import {
  setIsFilterExpanded,
  setLanguageLoaded,
  setShowPanelDetails,
} from "@/app/redux/common.slice";
import {
  selectCurrentLanguage,
  selectLanguageLoaded,
  selectMenuActivityCount,
  selectSelectedCompany,
  selectShowPanelDetails,
} from "@/app/redux/common.selector";
import { fetchGetDocumentsThunk } from "./redux/section.thunk";
import {
  selectDocFilters,
  selectPaginator,
  selectSectionDocuments,
  selectSelectedDocument,
} from "./redux/section.selector";
import { PaginatorChange } from "../library/Paginator/Paginator";
import { Tooltip } from "primereact/tooltip";
import style from "./SectionComponent.module.scss";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";
import { PAGE_SIZE } from "./redux/section.constants";

const customColumns = [
  FieldTypeEnum.BOLD.toString(),
  FieldTypeEnum.COMMENTS.toString(),
  FieldTypeEnum.ATTACHMENTS.toString(),
  FieldTypeEnum.DATE.toString(),
  FieldTypeEnum.SAP_MESSAGE.toString(),
  FieldTypeEnum.NOTE.toString(),
  FieldTypeEnum.FORMATTED_NUMBER.toString(),
  FieldTypeEnum.BOOLEAN.toString(),
  FieldTypeEnum.AUTH_GROUP.toString(),
];

const widthColumns = [
  FieldTypeEnum.COMMENTS.toString(),
  FieldTypeEnum.ATTACHMENTS.toString(),
];

export const useTableConfigHook = (
  configuration: Field[],
  sectionId: string,
  userDocuments: boolean,
  setShowAuthGroupUsersDialog: (value: boolean) => void,
  setAuthGroupData: (value: any) => void
) => {
  const [columns, setColumns] = useState<TableColumnProps[]>([]);
  const [sortMap, setSortMap] = useState<Record<string, "ASC" | "DESC">>({});
  const [lazyState, setlazyState] = useState({
    sortField: "" ?? undefined,
    sortOrder: null,
  });
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const showPanelDetails = useAppSelector(selectShowPanelDetails);
  const company = useAppSelector(selectSelectedCompany);
  const selectedDocument = useAppSelector(selectSelectedDocument);
  const docFilters = useAppSelector(selectDocFilters);
  const paginator = useAppSelector(selectPaginator);
  const languageLoaded = useAppSelector(selectLanguageLoaded);
  const language = useAppSelector(selectCurrentLanguage);
  const data = useAppSelector(selectSectionDocuments);
  const menuActivityCount = useAppSelector(selectMenuActivityCount);

  const getColumns = (configuration: Field[]): TableColumnProps[] => {
    return configuration.map((f) => {
      dispatch(
        setColumnTranslationsMap({
          [f.code]: getTranslation(t, f.code, f.label, i18n),
        })
      );
      const column: TableColumnProps = {
        field: f.code,
        header: () => <>{getTranslation(t, f.code, f.label, i18n)}</>,
        // sortable: !!data && !!data[0] && !data[0][f.code]?.code,
        sortable: true,
        style: { width: widthColumns.indexOf(f.code) > -1 ? "40px" : "" },
        isObject: !!data && !!data[0] && !!data[0][f.code]?.code,
        template: (rowData: any) => {
          return (
            <>
              {rowData[f.code]?.code
                ? getTranslation(
                    t,
                    rowData[f.code].code,
                    rowData[f.code].label,
                    i18n
                  )
                : rowData[f.code]}
            </>
          );
        },
      };
      if (customColumns.indexOf(f.field_type.code) > -1) {
        column.template = (rowData: any) => {
          switch (f.field_type.code) {
            case FieldTypeEnum.FORMATTED_NUMBER:
              return (
                <span>
                  {new Intl.NumberFormat(language, {
                    minimumFractionDigits: 2,
                  }).format(rowData[f.code])}
                </span>
              );
            case FieldTypeEnum.BOOLEAN:
              return <span>{rowData[f.code] ? "X" : ""}</span>;
            case FieldTypeEnum.DATE:
              return (
                <span>
                  {rowData[f.code]
                    ? new Date(rowData[f.code]).toLocaleDateString()
                    : ""}
                </span>
              );
            case FieldTypeEnum.BOLD:
              return (
                <BoldCell
                  value={
                    rowData[f.code]?.code
                      ? getTranslation(
                          t,
                          rowData[f.code].code,
                          rowData[f.code].label,
                          i18n
                        )
                      : rowData[f.code]
                  }
                />
              );
            default:
              return <></>;
          }
        };
      }
      if (customColumns.indexOf(f.field_column_name) > -1) {
        column.template = (rowData: any) => {
          switch (f.field_column_name) {
            case FieldTypeEnum.COMMENTS:
              return (
                <IconCell
                  value={{ icon: "comments", highlight: rowData.comments }}
                />
              );
            case FieldTypeEnum.ATTACHMENTS:
              return (
                <IconCell
                  value={{ icon: "paperclip", highlight: rowData.attachments }}
                />
              );
            case FieldTypeEnum.SAP_MESSAGE:
            case FieldTypeEnum.NOTE:
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {rowData[f.code] && (
                    <>
                      <Tooltip
                        target=".custom-target-icon"
                        className={style.tooltip}
                      />
                      <i
                        className="custom-target-icon pi pi-info-circle"
                        data-pr-tooltip={
                          rowData[f.code]?.code
                            ? getTranslation(
                                t,
                                rowData[f.code].code,
                                rowData[f.code].label,
                                i18n
                              )
                            : rowData[f.code]
                        }
                        // data-pr-tooltip="testo prova"
                        data-pr-position="right"
                      />
                    </>
                  )}
                </div>
              );
            case FieldTypeEnum.AUTH_GROUP:
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "var(--spacing-2)",
                  }}
                >
                  {!!rowData[f.code]?.code && (
                    <i
                      className="pi pi-users"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setShowAuthGroupUsersDialog(true);
                        setAuthGroupData(rowData[f.code]?.code);
                      }}
                    />
                  )}
                  <span>{rowData[f.code]?.code}</span>
                </div>
              );
            default:
              return <></>;
          }
        };
      }
      return column;
    });
  };

  const onRowClick = (e: any) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    if (selectedDocument.id !== e.data.id) {
      dispatch(setSelectedDocument({ id: e.data.id }));
      dispatch(setShowPanelDetails(true));
      dispatch(setIsFilterExpanded(false));
      return;
    }
    dispatch(setShowPanelDetails(false));
    dispatch(setSelectedDocument({ id: "" }));
    dispatch(setIsFilterExpanded(false));
  };

  const onSort = (column: any) => {
    setlazyState(column);
    const field = column.sortField || "";
    setSortMap((value) => ({
      // ...value,
      [field]: value[field] === "ASC" ? "DESC" : "ASC",
    }));
  };

  const onPageChange = (e: PaginatorChange) => {
    if (paginator.page !== e.page + 1) {
      dispatch(
        setPaginator({
          page: e.page + 1,
          pageCount: 0,
          total: 0,
          reload: false,
          first: e.first || 1,
          size: PAGE_SIZE,
        })
      );
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      dispatch(setLanguageLoaded(true));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if ((configuration.length > 0 && languageLoaded) || !showPanelDetails) {
      const newConfiguration = [...configuration];
      dispatch(resetColumnTranslationsMap());
      setColumns(
        getColumns(
          !showPanelDetails && window.innerWidth > 480
            ? newConfiguration
                .filter((c) => c.visible)
                .sort((a, b) => a.sorting_nr - b.sorting_nr)
            : newConfiguration
                .filter((f) => customColumns.indexOf(f.field_column_name) < 0)
                .splice(0, 2)
        )
      );
      // dispatch(setLanguageLoaded(false));
    }
    // eslint-disable-next-line
  }, [configuration, showPanelDetails, languageLoaded, dispatch]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const documentCode = url.searchParams.get("documentCode");
    if (
      ((!!company?.companyId && !!sectionId) ||
        (paginator.page === 1 && !!sectionId)) &&
      !documentCode
    ) {
      dispatch(
        fetchGetDocumentsThunk({
          companyId: company?.companyId || "",
          sectionId,
          order: Object.keys(sortMap).map((f) => [f, sortMap[f]]),
          userDocuments,
          page: paginator.page,
          docFilters,
        })
      );
    }
    // eslint-disable-next-line
  }, [sectionId, sortMap, paginator.page, menuActivityCount, dispatch]);

  return {
    columns,
    lazyState,
    setColumns,
    getColumns,
    onRowClick,
    onSort,
    onPageChange,
  };
};
