import { CustomCellProps } from "../Table";
import style from "./IconCell.module.scss";

export interface IconCellProps {
  icon: string;
  highlight: boolean;
}

const IconCell = ({ value }: CustomCellProps<IconCellProps>) => {
  return (
    <div className={style.content}>
      <i
        className={`pi pi-${value.icon} ${
          value.highlight ? style.highlight : ""
        }`}
      />
    </div>
  );
};

export default IconCell;
