import { Card } from "primereact/card";
import style from "./UserSettings.module.scss";
import SecondaryTable from "../library/Table/SecondaryTable/SecondaryTable";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchCreateUserSignatureThunk,
  fetchGetUserSettingsThunk,
  fetchUpdateUserBlackListThunk,
  fetchUpdateUserSignatureThunk,
} from "./redux/user-settings.thunk";
import { selectSelectedCompany } from "@/app/redux/common.selector";
import Spinner from "../library/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";
import {
  UserSettings as IUserSettings,
  UserSettingsProcess,
  UserSettingsSignature,
} from "./redux/user-settings.models";
import Input from "../library/Input/Input";
import Button from "../library/Button/Button";
import PrimaryTable from "../library/Table/PrimaryTable/PrimaryTable";
import { ColumnBodyOptions } from "primereact/column";
import { addToastMessage } from "@/app/redux/common.slice";
import { DataTableRowEvent } from "primereact/datatable";

const UserSettings = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [userSettings, setUserSettings] = useState<IUserSettings | null>(null);
  const [rowSign, setRowSign] = useState<UserSettingsSignature>({
    line1: "",
    line2: "",
    line3: "",
  });
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any[]>([]);

  const company = useAppSelector(selectSelectedCompany);
  const { t, i18n } = useTranslation();

  const onChangeSign = async () => {
    setLoadingAction(true);
    try {
      if (!!userSettings?.signature) {
        await dispatch(
          fetchUpdateUserSignatureThunk({
            companyId: company?.companyId ?? "",
            id: userSettings?.signature.id ?? "",
            ...rowSign,
          })
        );
      } else {
        await dispatch(
          fetchCreateUserSignatureThunk({
            company_id: company?.companyId ?? "",
            ...rowSign,
          })
        );
      }
      dispatch(
        addToastMessage({
          message: t("UI_USER_SETTINGS_SUCCESS"),
          type: "success",
        })
      );
    } finally {
      setLoadingAction(false);
    }
  };
  const onChangeBlackList = async () => {
    const blackList = userSettings?.processes.map((p: UserSettingsProcess) => ({
      id: p.id,
      events: p.events.map((ev: any) => ({
        id: ev.id,
        is_blacklist: !!selectedEvents.find((e) => e.id === ev.id),
        blacklist_id: ev.blacklist_id,
      })),
    }));
    setLoadingAction(true);
    try {
      await dispatch(
        fetchUpdateUserBlackListThunk({
          company_id: company?.companyId ?? "",
          processes: blackList ?? [],
        })
      );
      dispatch(
        addToastMessage({
          message: t("UI_USER_SETTINGS_SUCCESS"),
          type: "success",
        })
      );
    } finally {
      setLoadingAction(false);
    }
  };

  const columns = [
    {
      field: "name",
      header: t("UI_USER_SETTINGS_TABLE_PROCESS"),
      template: (rowData: any) => (
        <>
          {rowData?.name} - {rowData?.description}
        </>
      ),
    },
  ];

  const eventColumns = [
    {
      field: "name",
      header: t("UI_USER_SETTINGS_TABLE_EVENT"),
      template: (rowData: any) => (
        <>{getTranslation(t, rowData?.code, rowData?.name, i18n)}</>
      ),
    },
  ];

  const onSelectionChange = (e: any) => {
    console.log(e);
    setSelectedEvents(e.value);
  };

  const onRowExpand = (e: DataTableRowEvent) => {
    console.log(e);
    setSelectedEvents(e.data.events.filter((e: any) => e.is_blacklist) ?? []);
  };

  const columnExpanderBody = (rowData: any, columnProps: ColumnBodyOptions) => {
    return columnProps?.expander?.element;
  };

  const rowExpansionTemplate = (data: UserSettingsProcess) => {
    return (
      <SecondaryTable
        data={data.events}
        columns={eventColumns}
        tableProps={{
          selectionMode: "checkbox" as any,
          selection: selectedEvents,
          onSelectionChange,
        }}
      />
    );
  };

  const onRowToggle = (e: any) => {
    setExpandedRows(e.data);
  };

  const cardTitleTemplate = (title: string, handler: () => void) => (
    <div className={style.card_title}>
      <div className={style.title}>{title}</div>
      <Button label={t("UI_INIT_SAVE")} onClick={handler} />
    </div>
  );

  useEffect(() => {
    const getUserSettings = async () => {
      setLoading(true);
      const res = await dispatch(
        fetchGetUserSettingsThunk(company?.companyId || "")
      );
      setUserSettings(res.payload);
      setRowSign({
        line1: res.payload.signature?.line1 || "",
        line2: res.payload.signature?.line2 || "",
        line3: res.payload.signature?.line3 || "",
      });
      setLoading(false);
    };
    getUserSettings();
  }, [dispatch, company?.companyId]);

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.title}>{t("UI_USER_SETTINGS_TITLE")}</div>
      </div>
      <div className={style.content}>
        {loading && <Spinner />}
        {!loading && (
          <>
            <div className={style.header}>
              <div className={style.card}>
                <Card title={t("UI_USER_SETTINGS_USER_INFO")}>
                  <div className={style.labelvalue}>
                    <div className={style.label}>
                      {t("UI_USER_SETTINGS_NAME")}
                    </div>
                    <div className={style.value}>{userSettings?.user.name}</div>
                  </div>
                  <div className={style.labelvalue}>
                    <div className={style.label}>
                      {t("UI_USER_SETTINGS_SURNAME")}
                    </div>
                    <div className={style.value}>
                      {userSettings?.user.surname}
                    </div>
                  </div>
                  <div className={style.labelvalue}>
                    <div className={style.label}>UID</div>
                    <div className={style.value}>{userSettings?.user.uid}</div>
                  </div>
                  <div className={style.labelvalue}>
                    <div className={style.label}>Email</div>
                    <div className={style.value}>
                      {userSettings?.user.email}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={style.card}>
                <Card
                  title={cardTitleTemplate(
                    t("UI_USER_SETTINGS_SIGNATURE_MANAGEMENT"),
                    onChangeSign
                  )}
                >
                  {loadingAction && (
                    <div className={style.spinner}>
                      <Spinner />
                    </div>
                  )}
                  <div className={style.labelvalue}>
                    <div className={style.value}>
                      {userSettings?.user.name} {userSettings?.user.surname}
                    </div>
                  </div>
                  <div className={style.input}>
                    <Input
                      locale={{
                        label: t("UI_USER_SETTINGS_SIGN_1_LABEL"),
                        placeholder: t("UI_USER_SETTINGS_SIGN_PLACEHOLDER"),
                      }}
                      value={rowSign.line1}
                      onChangeCallback={(line1: string) =>
                        setRowSign((rowSign) => ({
                          ...rowSign,
                          line1,
                        }))
                      }
                    />
                  </div>
                  <div className={style.input}>
                    <Input
                      locale={{
                        label: t("UI_USER_SETTINGS_SIGN_2_LABEL"),
                        placeholder: t("UI_USER_SETTINGS_SIGN_PLACEHOLDER"),
                      }}
                      value={rowSign.line2}
                      onChangeCallback={(line2: string) =>
                        setRowSign((rowSign) => ({
                          ...rowSign,
                          line2,
                        }))
                      }
                    />
                  </div>
                  <div className={style.input}>
                    <Input
                      locale={{
                        label: t("UI_USER_SETTINGS_SIGN_3_LABEL"),
                        placeholder: t("UI_USER_SETTINGS_SIGN_PLACEHOLDER"),
                      }}
                      value={rowSign.line3}
                      onChangeCallback={(line3: string) =>
                        setRowSign((rowSign) => ({
                          ...rowSign,
                          line3,
                        }))
                      }
                    />
                  </div>
                </Card>
              </div>
            </div>
            <div className={style.main}>
              {loadingAction && (
                <div className={style.spinner}>
                  <Spinner />
                </div>
              )}
              <div className={style.card}>
                <Card
                  title={cardTitleTemplate(
                    t("UI_USER_SETTINGS_NOTIFICATION_MANAGEMENT"),
                    onChangeBlackList
                  )}
                >
                  <PrimaryTable
                    columns={columns}
                    data={userSettings?.processes ?? []}
                    columnExpanderBody={columnExpanderBody}
                    tableProps={{
                      rowExpansionTemplate,
                      onRowToggle,
                      expandedRows,
                      dataKey: "id",
                      onRowExpand,
                    }}
                  />
                </Card>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserSettings;
