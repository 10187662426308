import { useAppSelector } from "@/app/hooks";
import {
  EProfileType,
  MetadataTypeEnum,
} from "@/components/Section/redux/section.models";
import {
  selectDocumentDetails,
  selectHasAttachmentsPermission,
} from "@/components/Section/redux/section.selector";
import Accordion from "../library/Accordion/Accordion";
import LabelValue from "../library/LabelValue/LabelValue";
import SecondaryTable from "../library/Table/SecondaryTable/SecondaryTable";
import style from "./DocumentInfo.module.scss";
import { useTranslation } from "react-i18next";
import { getTranslation } from "@/app/i18n.utils";
import {
  selectCurrentLanguage,
  selectProfiles,
} from "@/app/redux/common.selector";
import {Tooltip} from "primereact/tooltip";

export interface DocumentInfoProps {
  onSaveField: (code: string, value: string) => void;
}

const DocumentInfo = ({ onSaveField }: DocumentInfoProps) => {
  const details = useAppSelector(selectDocumentDetails);
  const hasAttachmentsPermission = useAppSelector(
    selectHasAttachmentsPermission
  );
  const profiles = useAppSelector(selectProfiles);
  const { t, i18n } = useTranslation();

  const language = useAppSelector(selectCurrentLanguage);

  const onSaveFieldHandler = (code: string, value: string) => {
    onSaveField(code, value);
  };

  const getTableConfig = (details: any) => {
    const columns: any[] = details.children.map((c: any) => ({
      field: c.code,
      header: getTranslation(t, c.code, c.label, i18n),
      template: (rowData: any) => {
        const cellValue = getTranslation(t, rowData[c.code]?.code, rowData[c.code], i18n)
        const isValueTooLong = !!cellValue && cellValue.length > 49;
        return (
            <>{isValueTooLong && <Tooltip
                target=".custom-target-icon"
            />}<span className="custom-target-icon"
                     data-pr-tooltip={isValueTooLong ? cellValue : undefined}
                     data-pr-position="right">{`${isValueTooLong ? cellValue.substring(0, 49) + '...' : cellValue}`}
        </span>
            </>
        )},
    }));
    return <SecondaryTable columns={columns} data={details.value} />;
  };

  const getValue = (c: any) => {
    if (c.type === "date") {
      return !!c.value ? new Date(c.value).toLocaleDateString() : "";
    }
    if (c.type === "link") {
      return c.value.label;
    }
    if (c.type === "formattedNumber") {
      return !!c.value
        ? new Intl.NumberFormat(language, {
            minimumFractionDigits: 2,
          }).format(c.value)
        : "";
    }
    if (!!c.value?.code) {
      return i18n.exists(c.value.code) ? t(c.value.code) : c.value.label;
    }
    return c.value;
  };

  const getLink = (c: any) => {
    return c.type === "link" ? c.value.code : null
  };

  return (
    <div className={style.container}>
      {[...(details.document_metadata || [])]
        ?.sort((a, b) => a.sorting_nr - b.sorting_nr)
        .map((d: any, index) => (
          <Accordion
            title={getTranslation(t, d.code, d.label, i18n)}
            key={d.id}
            isOpen={!index}
          >
            <div
              className={
                d.type !== MetadataTypeEnum.TABLE
                  ? style.mainData
                  : style.tableData
              }
            >
              {d.type !== MetadataTypeEnum.TABLE &&
                d.children.map((c: any) => (
                  <LabelValue
                    key={c.id}
                    label={getTranslation(t, c.code, c.label, i18n)}
                    value={getValue(c)}
                    link={getLink(c)}
                    editable={
                      (hasAttachmentsPermission ||
                        profiles.indexOf(EProfileType.BACKOFFICE)) &&
                      c.editable
                    }
                    field={c}
                    isEdit={
                      (hasAttachmentsPermission ||
                        profiles.indexOf(EProfileType.BACKOFFICE)) &&
                      c.editable
                    }
                    onSave={(value: any) => {
                      onSaveFieldHandler(c.id, value);
                    }}
                  />
                ))}
              {d.type === MetadataTypeEnum.TABLE && getTableConfig(d)}
            </div>
          </Accordion>
        ))}
    </div>
  );
};

export default DocumentInfo;
