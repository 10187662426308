import { DataTable, DataTableProps } from "primereact/datatable";
import { Column, ColumnBodyOptions, ColumnProps } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import style from "./Table.module.scss";

export interface CustomCellProps<T> {
  value: T;
}

export interface TableColumnProps extends ColumnProps {
  template?: (rowData: any) => ReactElement;
  isObject?: boolean
}

export interface TableProps {
  columns: TableColumnProps[];
  data: any[];
  customProps?: DataTableProps<any>;
  filter?: boolean;
  columnExpanderBody?: (data: any, options: ColumnBodyOptions) => ReactNode;
}

const Table = ({
  columns,
  data,
  columnExpanderBody,
  customProps,
  filter,
}: TableProps) => {
  const [filters, setFilters] = useState({});

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.template}
        style={col.style}
        filter={filter}
        filterField={col.isObject ? col.field + ".label" : col.field}
        headerClassName="header-column"
      />
    );
  });

  useEffect(() => {
    if (filter) {
      let newFilter: Record<string, any> = {};
      columns.forEach((c: TableColumnProps) => {
        newFilter[c.isObject ? c.field  + ".label" : c.field ?? ""] = {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        };
      });
      setFilters(newFilter);
    }
  }, [columns, filter]);

  return (
    <div className={style.container}>
      <DataTable
        value={data}
        {...customProps}
        emptyMessage="Nessun risultato"
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
      >
        {customProps?.reorderableRows && (
          <Column rowReorder className={style.reorderColumn} />
        )}
        {customProps?.selectionMode && (
          <Column
            selectionMode={
              customProps?.selectionMode === "checkbox" ? "multiple" : "single"
            }
            style={{ width: "3rem" }}
          />
        )}
        {customProps?.expandedRows && (
          <Column
            expander
            style={{ width: "3rem" }}
            body={columnExpanderBody}
          />
        )}
        {dynamicColumns}
      </DataTable>
    </div>
  );
};

export default Table;
