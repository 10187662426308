import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import Button from "../library/Button/Button";
import style from "./DocumentRejectionPopup.module.scss";
import { useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectActionDialogLoading } from "../Section/redux/section.selector";

export const DocumentRejectionPopup = (props: {
  isVisible: boolean;
  onSubmit: (note: string) => void;
  onHide: VoidFunction;
}) => {
  const [inputTextAreaValue, setInputTextAreaValue] = useState("");

  const loading = useAppSelector(selectActionDialogLoading);

  const footerContent = (
    <div className={style.customFooterContainer}>
      <div>
        <Button
          style={{ marginRight: "0px" }}
          label="Rifiuta"
          onClick={() => {
            if (props.onSubmit) props.onSubmit(inputTextAreaValue);
          }}
          isLoading={loading}
        />
      </div>
    </div>
  );

  return (
    <>
      <Dialog
        style={{ width: "40vw" }}
        className={style.container}
        headerClassName={style.header}
        header={"Inserisci una nota"}
        visible={props.isVisible}
        onHide={() => {
          if (props.onHide) {
            props.onHide();
          }
        }}
        footer={footerContent}
        closeIcon={<i className="pi pi-times-circle" />}
      >
        <div className={style.inputTextAreaContainer}>
          <InputTextarea
            autoResize
            value={inputTextAreaValue}
            onChange={(e) => {
              setInputTextAreaValue(e.currentTarget.value);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};
