import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON_NAMESPACE } from "./common.selector";
import { extraReducers } from "./common.extraReducer";
import { AreaTree } from "./common.models";

interface IToastMessage {
  id?: string;
  message: string;
  title?: string;
  type: "error" | "success";
}

export interface Company {
  companyId: string;
  companyName: string;
}

export interface CommonState {
  toastMessages: IToastMessage[];
  checkOnRouteChangeEnabled: boolean;
  targetPath: string;
  username: string;
  showMenu: boolean;
  companyList: Company[];
  companyListLoaded: boolean;
  selectedCompany: Company | null;
  menuConfiguration: AreaTree[];
  menuActivityCount: Record<string, number>;
  pageName: string;
  showPanelDetails: boolean;
  isFilterExpanded: boolean;
  forceReloadData: boolean;
  profiles: string[];
  alertDialog: {
    visible: boolean;
    closable?: boolean;
    text?: string;
    code?: string
  };
  currentLanguage: string;
  languages: any[];
  languageLoaded: boolean;
  reloadMenuTranslation: boolean;
}

const initialState: CommonState = {
  toastMessages: [],
  checkOnRouteChangeEnabled: false,
  targetPath: "",
  username: "",
  showMenu: false,
  companyList: [],
  companyListLoaded: false,
  selectedCompany: null,
  menuConfiguration: [],
  menuActivityCount: {},
  pageName: "",
  showPanelDetails: false,
  isFilterExpanded: false,
  forceReloadData: false,
  profiles: [],
  alertDialog: {
    visible: false,
    closable: false,
    text: "",
    code: ""
  },
  currentLanguage: "it",
  languages: [],
  languageLoaded: false,
  reloadMenuTranslation: false
};

export const commonSlice = createSlice({
  name: COMMON_NAMESPACE,
  initialState,
  reducers: {
    addToastMessage: (
      state: CommonState,
      action: PayloadAction<IToastMessage>
    ) => {
      const toastPayload = {
        id: new Date().toISOString(),
        ...action.payload,
      };
      state.toastMessages.push(toastPayload);
    },
    removeToastMessage: (state: CommonState, action: PayloadAction<string>) => {
      state.toastMessages = state.toastMessages.filter(
        (toast) => toast.id !== action.payload
      );
    },
    setCheckOnRouteChange: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      state.checkOnRouteChangeEnabled = action.payload;
    },
    setTargetPath: (state: CommonState, action: PayloadAction<string>) => {
      state.targetPath = action.payload;
    },
    setUsername: (state: CommonState, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setShowMenu: (state: CommonState) => {
      state.showMenu = !state.showMenu;
    },
    setSelectedCompany: (
      state: CommonState,
      action: PayloadAction<Company>
    ) => {
      state.selectedCompany = action.payload;
    },
    setPageName: (state: CommonState, action: PayloadAction<string>) => {
      state.pageName = action.payload;
    },
    setShowPanelDetails: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      state.showPanelDetails = action.payload;
      // state.languageLoaded = true;
    },
    setIsFilterExpanded: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      state.isFilterExpanded = action.payload;
    },
    setForceReloadData: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      state.forceReloadData = action.payload;
    },
    setProfiles: (state: CommonState, action: PayloadAction<string[]>) => {
      state.profiles = action.payload;
    },
    setAlertDialog: (
      state: CommonState,
      action: PayloadAction<{
        visible: boolean;
        closable?: boolean;
        text?: string;
        code?: string;
      }>
    ) => {
      state.alertDialog = { ...state.alertDialog, ...action.payload };
    },
    setCurrentLanguage: (
      state: CommonState,
      action: PayloadAction<string>
    ) => {
      state.currentLanguage = action.payload;
    },
    setLanguageLoaded: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      state.languageLoaded = action.payload;
    },
    setReloadMenuTranslation: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      state.reloadMenuTranslation = action.payload;
    },
    setMenuConfiguration: (
      state: CommonState,
      action: PayloadAction<AreaTree[]>
    ) => {
      state.menuConfiguration = action.payload;
    },
  },
  extraReducers,
});

export const {
  addToastMessage,
  removeToastMessage,
  setCheckOnRouteChange,
  setTargetPath,
  setUsername,
  setShowMenu,
  setSelectedCompany,
  setPageName,
  setShowPanelDetails,
  setIsFilterExpanded,
  setForceReloadData,
  setProfiles,
  setAlertDialog,
  setCurrentLanguage,
  setLanguageLoaded,
  setMenuConfiguration,
  setReloadMenuTranslation
} = commonSlice.actions;

export default commonSlice.reducer;
