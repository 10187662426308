import { useParams, useSearchParams } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import style from "./SectionComponent.module.scss";
import Filters from "./Filters";
import Tab from "../library/Tab/Tab";
import MyActivities from "./MyActivities";
import MyDocuments from "./MyDocuments";
import Button from "../library/Button/Button";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectLanguageLoaded,
  selectMenuConfiguration,
  selectPageName,
  selectProfiles,
  selectSelectedCompany,
  selectShowPanelDetails,
} from "@/app/redux/common.selector";
import {
  addToastMessage,
  setIsFilterExpanded,
  setPageName,
  setShowPanelDetails,
} from "@/app/redux/common.slice";
import {
  selectColumnTranslationsMap,
  selectDocFilters,
  selectHasAttachmentsPermission,
  selectLoading,
  selectMassiveActionDocuments,
  selectSectionConfig,
} from "@/components/Section/redux/section.selector";
import {
  fetchBumpStatusThunk,
  fetchGetConfigurationThunk,
  fetchGetDocumentsThunk,
  // fetchGetDocumentsThunk,
  fetchGetDownloadDocumentsThunk,
  fetchGetInitializationFormListThunk,
} from "@/components/Section/redux/section.thunk";
import Spinner from "../library/Spinner/Spinner";
import {
  setDocFilters,
  setLoading,
  setMassiveActionDocuments,
  setPaginator,
} from "./redux/section.slice";
import { DigitalSignaturePopup } from "../ActionsPopups/DigitalSignaturePopup";
import { DocumentApprovalPopup } from "../ActionsPopups/DocumentApprovalPopup";
import { utils, writeFileXLSX } from "xlsx";
import { fetchGetMenuThunk } from "@/app/redux/common.thunk";
import { useTranslation } from "react-i18next";
import { TabViewTabChangeEvent } from "primereact/tabview";
import { PAGE_SIZE } from "./redux/section.constants";
import { getTranslation } from "@/app/i18n.utils";
import { InitializationFormDialog } from "./InitializationFormDialog";
import { EProfileType } from "./redux/section.models";

const SectionComponent = () => {
  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const columnTranslationsMap = useAppSelector(selectColumnTranslationsMap);

  const [sectionId, setSectionId] = useState<string>("");
  const [showSignDialog, setShowSignDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showInitializationDialog, setShowInitializationDialog] =
    useState(false);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState(
    !!searchParams.get("documentCode") ? 1 : 0
  );
  const [actionLoading, setActionLoading] = useState<Record<string, boolean>>(
    {}
  );

  const pageName = useAppSelector(selectPageName);
  const menuConfiguration = useAppSelector(selectMenuConfiguration);
  const configuration = useAppSelector(selectSectionConfig);
  const showPanelDetails = useAppSelector(selectShowPanelDetails);
  const company = useAppSelector(selectSelectedCompany);
  const profiles = useAppSelector(selectProfiles);
  const hasAttachmentsPermission = useAppSelector(
    selectHasAttachmentsPermission
  );
  const massiveActionDocuments = useAppSelector(selectMassiveActionDocuments);
  const loading = useAppSelector(selectLoading);
  const docFilters = useAppSelector(selectDocFilters);
  const languageLoaded = useAppSelector(selectLanguageLoaded);

  const [panels, setPanels] = useState<any[]>([
    {
      element: MyActivities,
      header: t("UI_SECTION_TAB_MY_ACTIVITIES"),
      elementProps: {
        configuration,
        sectionId,
      },
      disabled: loading,
    },
    {
      element: MyDocuments,
      header: t("UI_SECTION_TAB_DOCUMENTS"),
      elementProps: {
        configuration,
        sectionId,
      },
      disabled: loading,
    },
  ]);

  useEffect(() => {
    if (languageLoaded || !!sectionId) {
      setPanels([
        {
          element: MyActivities,
          header: <>{t("UI_SECTION_TAB_MY_ACTIVITIES")}</>,
          elementProps: {
            configuration,
            sectionId,
          },
          disabled: loading,
        },
        {
          element: MyDocuments,
          header: <>{t("UI_SECTION_TAB_DOCUMENTS")}</>,
          elementProps: {
            configuration,
            sectionId,
          },
          disabled: loading,
        },
      ]);
      const areaId = searchParams.get("areaId");
      const area = menuConfiguration.find((m) => m.id === areaId);
      const section = area?.sections.find((s) => s.id === sectionId);
      dispatch(
        setPageName(
          `${getTranslation(
            t,
            area?.code || "",
            area?.name || "",
            i18n
          )} ${getTranslation(
            t,
            section?.code || "",
            section?.name || "",
            i18n
          )}`
        )
      );
    }
    // eslint-disable-next-line
  }, [languageLoaded, sectionId, configuration, t, loading]);

  const showFilters = () => {
    return configuration.filter((f) => !!f.filter).length > 0;
  };

  const bumpStatus = async (action: string, params?: string[]) => {
    setActionLoading((value) => ({
      ...value,
      [action]: true,
    }));
    return dispatch(
      fetchBumpStatusThunk({
        classCode: action,
        execParams:
          action === "BE_APPROVE"
            ? massiveActionDocuments.map((d) => d.id)
            : [massiveActionDocuments.map((d) => d.id), ...(params || [])],
      })
    )
      .then((response) => {
        dispatch(setLoading(true));
        dispatch(
          fetchGetMenuThunk({
            companyId: company?.companyId || "",
            callback: () => null,
          })
        );
        dispatch(setMassiveActionDocuments([]));
        return response;
      })
      .finally(() => {
        setActionLoading((value) => ({
          ...value,
          [action]: false,
        }));
      });
  };
  const onAddDocument = () => setShowInitializationDialog(true);

  const onApproveDocuments = async () => {
    const res = await bumpStatus("BE_APPROVE");
    setErrorMessageList(
      res.payload?.data
        ?.filter((d: any) => d.statusCode !== 200)
        .map((e: any) => `${e.id} - ${e.message}`)
    );
    res.payload?.data?.forEach((m: any) => {
      if (m.statusCode === 200) {
        dispatch(
          addToastMessage({
            title: t("UI_SECTION_OPERATION_DONE"),
            message: m.message,
            type: "success",
          })
        );
      }
    });
    setShowApproveDialog(false);
  };

  const onSignDocuments = async (params?: string[]) => {
    const res = await bumpStatus("ACT_SIGN", params);
    res.payload?.data?.forEach((m: any) =>
      dispatch(
        addToastMessage({
          title:
            m.statusCode === 200
              ? t("UI_SECTION_OPERATION_DONE")
              : t("UI_SECTION_OPERATION_FAILED"),
          message: m.message,
          type: m.statusCode === 200 ? "success" : "error",
        })
      )
    );
    setShowSignDialog(false);
  };
  const onHideSignDialog = () => setShowSignDialog(false);
  const onHideApproveDialog = () => setShowSignDialog(false);
  const onCloseErrorBox = () => setErrorMessageList([]);

  const onRefreshDocuments = async () => {
    dispatch(
      fetchGetDocumentsThunk({
        companyId: company?.companyId || "",
        sectionId: window.location.pathname.split("/")[2] || "",
        page: 1,
        docFilters: docFilters,
        userDocuments: hasAttachmentsPermission,
      })
    );
    // dispatch(setLoading(true));
    // await dispatch(
    //   fetchGetMenuThunk({
    //     companyId: company?.companyId || "",
    //     callback: () => null,
    //   })
    // );
  };

  const onTabChange = (e: TabViewTabChangeEvent) => {
    // dispatch(setDocFilters([]));
    dispatch(
      setPaginator({
        page: 1,
        pageCount: 0,
        total: 0,
        reload: false,
        first: 1,
        size: PAGE_SIZE,
      })
    );
    setActiveTab(e.index);
  };

  const onDownloadExcel = async () => {
    setActionLoading((value) => ({ ...value, download: true }));
    const res = await dispatch(
      fetchGetDownloadDocumentsThunk({
        companyId: company?.companyId || "",
        sectionId,
        userDocuments: hasAttachmentsPermission,
        docFilters,
      })
    );
    setActionLoading((value) => ({ ...value, download: false }));
    const dateFields = configuration.filter(
      (f) => f.field_type.code === "date"
    );
    const columnKeys = Object.keys(columnTranslationsMap);
    const ws = utils.json_to_sheet(
      res.payload.data.map((d: any) => {
        dateFields.forEach(
          (f) => (d[f.code] = new Date(d[f.code]).toLocaleDateString())
        );
        const data: Record<string, string> = {};
        columnKeys.forEach((ct: string) => {
          data[columnTranslationsMap[ct]] = !!d[ct]?.code
            ? getTranslation(t, d[ct].code, d[ct].label, i18n)
            : d[ct];
        });
        return data;
      })
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    const date = new Date();
    writeFileXLSX(
      wb,
      `Export_${
        hasAttachmentsPermission ? "MyActivities" : "Documents"
      }_${date.toLocaleDateString()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.xlsx`
    );
  };

  const ctaTemplate = () => (
    <>
      <Button
        label={t("UI_SECTION_CTA_REFRESH")}
        iconLeft="refresh"
        secondaryMode
        onClick={onRefreshDocuments}
        disabled={loading}
      />
      {profiles.map((p): any => {
        return ProfileTypeActionMap[p].map(
          (action) =>
            (hasAttachmentsPermission || p === EProfileType.INSERITORE) && (
              <Button
                key={action.buttonLabel}
                label={action.buttonLabel}
                onClick={action.callback}
                disabled={
                  !action.enabled && (loading || !massiveActionDocuments.length)
                }
                isLoading={actionLoading[action.actionCode]}
              />
            )
        );
      })}
    </>
  );

  const ProfileTypeActionMap = {
    [EProfileType.VISUALIZZATORE.toString()]: [],
    [EProfileType.INSERITORE.toString()]: [
      {
        buttonLabel: t("UI_SECTION_CTA_NEW"),
        actionCode: "ADD",
        enabled: true,
        callback: onAddDocument,
      },
    ],
    [EProfileType.APPROVATORE.toString()]: [
      {
        buttonLabel: t("UI_SECTION_CTA_APPROVE"),
        actionCode: "BE_APPROVE",
        enabled: false,
        callback: onApproveDocuments,
      },
    ],
    [EProfileType.PROCURATORE.toString()]: [
      // {
      //   buttonLabel: "Approva",
      //   actionCode: "BE_APPROVE",
      //   callback: onApproveDocuments,
      // },
      {
        buttonLabel: t("UI_SECTION_CTA_SIGN"),
        actionCode: "ACT_SIGN",
        enabled: false,
        callback: () => {
          if (massiveActionDocuments.length > 10) {
            dispatch(
              addToastMessage({
                message: t("UI_SECTION_SIGN_OVER_TEN_DOCUMENTS"),
                type: "success",
              })
            );
            return;
          }
          setShowSignDialog(true);
        },
      },
    ],
    [EProfileType.BACKOFFICE.toString()]: [],
  };

  useEffect(() => {
    if (menuConfiguration.length > 0 && !!pathParams.sectionId) {
      const areaId = searchParams.get("areaId");
      const sectionId = pathParams.sectionId;
      const area = menuConfiguration.find((m) => m.id === areaId);
      const section = area?.sections.find((s) => s.id === sectionId);

      setSectionId(section?.id || "");
      dispatch(setShowPanelDetails(false));
      dispatch(setIsFilterExpanded(false));
      dispatch(
        setPageName(
          `${getTranslation(
            t,
            area?.code || "",
            area?.name || "",
            i18n
          )} ${getTranslation(
            t,
            section?.code || "",
            section?.name || "",
            i18n
          )}`
        )
      );
      dispatch(setDocFilters([]));
      if (!!section?.id) {
        dispatch(
          fetchGetConfigurationThunk({
            companyId: company?.companyId || "",
            sectionId: section?.id || "",
          })
        );
      }
      dispatch(
        fetchGetInitializationFormListThunk({
          companyId: company?.companyId || "",
          sectionId,
        })
      );
    }
    // eslint-disable-next-line
  }, [company?.companyId, menuConfiguration, pathParams.sectionId, dispatch]);

  return (
    <div
      className={`${style.container} ${
        showPanelDetails ? style.withPanelDetails : ""
      }`}
    >
      <div className={style.titleContainer}>
        <div className={style.title}>{pageName}</div>
        {errorMessageList.length > 0 && (
          <div className={style.errorBox}>
            <div className={style.errorBox_header}>
              <div className={style.errorBox_header_title}>
                {t("UI_SECTION_ERROR_BOX_TITLE")}!
              </div>
              <div
                className={style.errorBox_header_close}
                onClick={onCloseErrorBox}
              >
                <i className="pi pi-times-circle" />
              </div>
            </div>
            {errorMessageList.map(
              (e) => !!e && <div className={style.errorBox_content}>{e}</div>
            )}
          </div>
        )}
        {showFilters() && (
          <Suspense fallback={<Spinner />}>
            <Filters
              configuration={configuration}
              userDocuments={hasAttachmentsPermission}
            >
              {ctaTemplate()}
            </Filters>
          </Suspense>
        )}
      </div>
      {!configuration.length && <Spinner />}
      {configuration.length > 0 && (
        <div
          className={`${style.content} ${
            showPanelDetails ? style.withPanelDetails : ""
          }`}
        >
          <div className={style.header}>
            {!showPanelDetails && (
              <div className={style.headerCta}>
                <div className={style.cta}>
                  <div className={style.download}>
                    <Button
                      label={t("UI_SECTION_CTA_DOWNLOAD")}
                      iconLeft="file-excel"
                      secondaryMode
                      onClick={onDownloadExcel}
                      disabled={loading}
                      isLoading={actionLoading.download}
                    />
                  </div>
                  <div className={style.headerCtaMobile}>{ctaTemplate()}</div>
                </div>
              </div>
            )}
            {/* {languageLoaded && ( */}
            <Tab
              panels={panels}
              tabViewNavContainerFull={showPanelDetails}
              onTabChange={onTabChange}
              activeIndex={activeTab}
            />
            {/* )} */}
            {showSignDialog && (
              <DigitalSignaturePopup
                isVisible
                title=""
                onSubmit={(pin, otp, certificate) =>
                  onSignDocuments([pin, otp, certificate])
                }
                onHide={onHideSignDialog}
              />
            )}
            {showApproveDialog && (
              <DocumentApprovalPopup
                isVisible
                description=""
                onApprove={onApproveDocuments}
                onReject={onHideApproveDialog}
                onHide={onHideApproveDialog}
              />
            )}
            {showInitializationDialog && (
              <InitializationFormDialog
                isVisible={showInitializationDialog}
                onHide={() => {
                  setShowInitializationDialog(false);
                  onRefreshDocuments();
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionComponent;
