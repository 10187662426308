import http from "@/utils/http";
import {
  UserSettings,
  UserSettingsBlacklistBodyRequest,
  UserSettingsCreateSignatureBody,
  UserSettingsSignatureBody,
} from "./user-settings.models";

export async function getUserSettings(companyId: string) {
  const response = await http.get<UserSettings>(
    `/document-manager-api/user/profile/settings/${companyId}`
  );
  return response.data;
}

export async function updateUserSignature({
  companyId,
  id,
  ...body
}: UserSettingsSignatureBody): Promise<any> {
  const response = await http.put<any>(
    `/document-manager-api/user/profile/settings/signature/${id}/update`,
    {
      ...body,
      line1: body.line1 !== "" ? body.line1 : null,
      line2: body.line2 !== "" ? body.line2 : null,
      line3: body.line3 !== "" ? body.line3 : null,
    }
  );

  return response.data;
}

export async function createUserSignature(
  body: UserSettingsCreateSignatureBody
): Promise<any> {
  const response = await http.post<any>(
    `/document-manager-api/user/profile/settings/signature/create`,
    {
      ...body,
      line1: body.line1 !== "" ? body.line1 : null,
      line2: body.line2 !== "" ? body.line2 : null,
      line3: body.line3 !== "" ? body.line3 : null,
    }
  );

  return response.data;
}

export async function updateUserBlackList(
  body: UserSettingsBlacklistBodyRequest
): Promise<any> {
  const response = await http.post<any>(
    `/document-manager-api/user/profile/settings/blacklist/update`,
    body
  );

  return response.data;
}
