export enum MetadataTypeEnum {
  SEPARATOR = "separator",
  TABLE = "table",
  LABEL = "label",
}

export enum FilterTypeEnum {
  TEXT_INPUT = "text_input",
  AUTOCOMPLETE_TEXT_INPUT = "autocomplete_text_input",
  SELECT = "select",
  SELECT_VALUE = "select-value",
  MULTISELECT = "multi-select",
  MULTISELECT_VALUE = "multi-select-value",
  DATE_FROM_TO = "date_from_to",
  NUMBER_INPUT = "number_input",
  NUMBER_FROM_TO = "number_from_to",
  BOOLEAN = "boolean",
}

export enum FieldTypeEnum {
  STRING = "string",
  BOLD = "bold",
  COMMENTS = "comments",
  ATTACHMENTS = "attachments",
  DATE = "date",
  SAP_MESSAGE = "sap_message",
  NOTE = "note",
  FORMATTED_NUMBER = "formattedNumber",
  BOOLEAN = "boolean",
  AUTH_GROUP = "auth_group_id"
}

export interface FilterItem {
  field_id: string;
  filter_id: string;
  filter_type: string;
  custom_value?: string;
  value: any;
  from?: any;
  to?: any;
  multiselect?: any[];
  operation?: string;
  options?: any[];
}

export interface FilterMap {
  [x: string]: FilterItem;
}
export interface FilterBody {
  filter_id: string;
  field_column_name: string;
  value: any;
  from?: any;
  to?: any;
  multiselect?: any[];
  operation?: string;
}

export interface Field {
  id: string;
  section_template_id: string;
  field_column_name: string;
  field_type_id: string;
  code: string;
  label: string;
  description: string;
  segregation: boolean;
  visible: boolean;
  filter_id: string;
  sorting_nr: number;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  section_template: {
    id: string;
    code: string;
    name: string;
    section_id: string;
    created_by: string;
    updated_by: string;
    deleted_by: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  field_type: {
    id: string;
    code: string;
    name: string;
    created_by: string;
    updated_by: string;
    deleted_by: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  filter: {
    id: string;
    code: string;
    description: string;
    form_type: string;
    data_value: string;
    custom_value?: string;
    type: string;
    created_by: string;
    updated_by: string;
    deleted_by: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  field_detail_type: {
    id: string;
    code: string;
    description: string;
    form_type: string;
    data_value: string;
    type: string;
    created_by: string;
    updated_by: string;
    deleted_by: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
}

export interface ICertificate {
  partitionName: string;
  dn: string;
  createdOn: string;
  expireOn: string;
  expired: boolean;
}

export interface Comment {
  document_instance_id: string;
  author: string;
  text: string;
  creation_date: string;
}

export interface IDocumentAttachment {
  folder: IAttachmentFolder;
  children?: IChildDocumentAttachment[];
}

export interface IChildDocumentAttachment {
  folder: IAttachmentFolder;
}

export interface IAttachmentFolder {
  id: string;
  process_template_id: string;
  parent_id: null | string;
  code: string;
  description: string;
  multiplicity: number;
  mandatory: boolean;
  initialization: boolean;
  sorting_nr: number;
  document_attachment_models: IAttachmentTableData[];
  editable: boolean;
  name: string;
  placeholder_folder_models: any[];
}

export interface IAttachmentTableData {
  id: string;
  link_type: string;
  document_instance_id: string;
  attachment_folder_id: string;
  title: string;
  extension: string;
  sequence_nr: number | null;
  signed: boolean;
  editable: boolean;
  hasPlaceholder: boolean;
  placeholder_attachment_models: any[];
  placeholder_attachment_signed_models: any[];
}
export interface IHistory {
  action: string;
  actionLabel: string;
  creation_date: string;
  document_instance_id: string;
  user_id: string;
  user_name: string;
  version: string;
  note: string;
}

export interface DocumentInitBodyRequest {
  company_id: string;
  process_id: string;
  fields: Record<string, string>;
  force: boolean;
  attachments: { id: string; code: string; name: string; file: string }[];
}

export enum EProfileType {
  VISUALIZZATORE = "VISUALIZZATORE",
  INSERITORE = "INSERITORE",
  APPROVATORE = "APPROVATORE",
  PROCURATORE = "PROCURATORE",
  BACKOFFICE = "BACKOFFICE",
}