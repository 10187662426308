import { Dialog } from "primereact/dialog";
import style from "./AuthGroupUsersDialog.module.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchGetAuthGroupUsersThunk } from "./redux/section.thunk";
import { useEffect, useState } from "react";
import { selectSelectedCompany } from "@/app/redux/common.selector";
import SecondaryTable from "../library/Table/SecondaryTable/SecondaryTable";
import Spinner from "../library/Spinner/Spinner";
import IconCell from "../library/Table/custom-components/IconCell";

export const AuthGroupUsersDialog = (props: {
  isVisible: boolean;
  onHide: VoidFunction;
  data: any;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const company = useAppSelector(selectSelectedCompany);
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const onAuthGroup = async () => {
      const res = await dispatch(
        fetchGetAuthGroupUsersThunk({
          companyId: company?.companyId ?? "",
          authGroupId: props.data,
        })
      );
      setUsers(res.payload);
      setLoading(false);
    };
    onAuthGroup();
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      style={{ width: "45vw" }}
      className={style.container}
      header={t("UI_SECTION_AUTH_GROUP_USERS_DIALOG_TITLE")}
      headerClassName={style.header}
      visible={props.isVisible}
      onHide={() => {
        if (props.onHide) {
          props.onHide();
        }
      }}
      closeIcon={<i className="pi pi-times-circle" />}
    >
      {loading && <Spinner />}
      {!loading && (
        <>
          <div className={style.authGroup}>{props.data}</div>
          <SecondaryTable
            columns={[
              { field: "name", header: t("UI_USER_SETTINGS_NAME") },
              { field: "surname", header: t("UI_USER_SETTINGS_SURNAME") },
              { field: "user_id", header: "UID" },
              {
                field: "backup_user",
                header: "Backup",
                template: (rowData) =>
                  rowData.backup_user ? (
                    <IconCell value={{ icon: "check", highlight: false }} />
                  ) : (
                    <></>
                  ),
              },
            ]}
            data={users}
          />
        </>
      )}
    </Dialog>
  );
};
