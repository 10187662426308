import {
  AutoCompleteChangeEvent,
  AutoCompleteProps as PRAutoCompleteProps,
  AutoComplete as PRAutocomplete,
} from "primereact/autocomplete";
import InputLabel from "../Input/InputLabel";
import inputStyle from "../Input/Input.module.scss";
import style from "./Autocomplete.module.scss";

export interface AutocompleteProps extends PRAutoCompleteProps {
  locale: {
    label?: string;
    placeholder: string;
  };
  onChangeCallback: (value: string) => void;
}

const Autocomplete = ({
  locale,
  onChangeCallback,
  ...props
}: AutocompleteProps) => {
  const onChange = (e: AutoCompleteChangeEvent) => onChangeCallback(e.value);

  return (
    <div className={inputStyle.container}>
      {!!locale?.label && <InputLabel locale={locale} />}
      <div className="p-input-icon-left">
        <i className={`${inputStyle.icon} pi pi-search`} style={{zIndex: "99"}}/>
        <PRAutocomplete
          {...props}
          placeholder={locale.placeholder}
          onChange={onChange}
          inputClassName={inputStyle.inputWrapper}
          style={{ width: "100%" }}
          panelClassName={style.panelWrapper}
        />
      </div>
    </div>
  );
};

export default Autocomplete;
