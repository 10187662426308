import { useTranslation } from "react-i18next";
import Button from "../library/Button/Button";
import style from "./AlertDialog.module.scss";

export const AlertDialogFooter = (props: { onClose: VoidFunction }) => {
  const { t } = useTranslation();
  return (
    <div className={style.footerContainer}>
      <Button
        label={t("UI_ALERT_DIALOG_CLOSE")}
        iconLeft="pi pi-times"
        onClick={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
        secondaryMode
      />
    </div>
  );
};
