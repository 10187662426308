import style from "./Input.module.scss";

export interface InputLabelProps {
  locale: {
    label?: string;
  };
  required?: boolean;
  isErrorMessage?: boolean;
}

const InputLabel = ({ locale, required, isErrorMessage }: InputLabelProps) => {
  const labelClassName = isErrorMessage
    ? `${style.label} ${style.errorLabel}`
    : style.label;
  return (
    <div className={labelClassName}>
      <span>{locale.label}</span>
      <span className={style.labelMandatory}>{required ? "*" : ""}</span>
    </div>
  );
};

export default InputLabel;
