import { useState } from "react";
import style from "./LabelValue.module.scss";
import { useFilterComponentHook } from "@/components/Section/useFilterComponentHook";
import {
  Field,
  FilterBody,
  FilterMap,
} from "@/components/Section/redux/section.models";

export interface LabelValueProps {
  label: string;
  value: string;
  link: string | null;
  field: any;
  editable?: boolean;
  isEdit?: boolean;
  onEdit?: () => void;
  onSave?: any;
}

const LabelValue = ({
  label,
  value,
  link,
  field,
  editable,
  isEdit,
  onEdit,
  onSave,
}: LabelValueProps) => {
  const [valueMap, setValueMap] = useState<FilterMap>({});

  const onValueChange = (filterBody: FilterBody) => {
    setValueMap((value) => ({
      ...value,
      [filterBody.field_column_name]: {
        ...value[filterBody.field_column_name],
        ...filterBody.value,
        operation: filterBody.operation,
        value: filterBody.value,
      },
    }));
    onSave(filterBody.value)
  };

  const getValue = (field: Field) => {
    if (!valueMap[field.field_column_name]) {
      setValueMap((valueMap) => ({
        ...valueMap,
        [field.field_column_name]: {
          field_id: field.id,
          filter_id: field.filter?.id || "",
          filter_type: field.filter?.form_type || "",
          value,
          multiselect: [],
          from: "",
          to: "",
          options:
            field.filter?.data_value?.indexOf("ARRAY") > -1
              ? JSON.parse(
                  field.filter?.data_value.split("ARRAY")[1].replace(/'/g, '"')
                )
              : [],
        },
      }));
    }
    return valueMap[field.field_column_name];
  };

  const { getFilterComponent } = useFilterComponentHook({
    valueMap,
    getValue,
    onValueChange,
    setValueMap,
    hideLabel: true,
  });

  function openTab () {
    if (!!link && link !== '')
    window.open(`${window.location.origin}/list/${link}`)
  }

  const getValueTemplate = () => {
    const editValue = getValue({
      code: field.code,
      label: field.label,
      field_column_name: field.code,
      filter: {
        form_type: field.form_type,
        data_value: field.data_value,
      },
    } as Field)?.value;
    return (
      <div
        className={`${style.value} ${
            (!!link && link !== '') ? style.value_link : (!editValue && editable && !value ? style.value_empty : "")
        }`}
      onClick={() => {
        openTab()
      }}>
        {editValue?.label ||
          editValue ||
          (editable && !value ? "Inserisci un valore" : value)}
        {editable && (
          <div className={style.icon} onClick={onEdit}>
            <i className="pi pi-pencil" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.label}>{label}</div>
      {!isEdit && getValueTemplate()}
      {isEdit && (
        <div className={style.value}>
          {getFilterComponent({
            code: field.code,
            label: field.label,
            field_column_name: field.code,
            filter: {
              form_type: field.form_type,
              data_value: field.data_value,
            },
          } as Field)}
          {/* <div
            className={style.icon}
            onClick={onSave.bind(null, valueMap[field.code]?.value)}
          >
            <i className={`pi pi-save ${style.save}`} />
          </div> */}
          {/* <div
            className={style.icon}
            onClick={onEdit}
          >
            <i className={`pi pi-times ${style.save}`} />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default LabelValue;
