import { TFunction, i18n } from "i18next";

export const getTranslation = (
  t: TFunction<"translation", undefined>,
  key: string,
  fallback: string,
  i18n: i18n
) => {
  return !i18n.exists(key) ? fallback : t(key);
};
