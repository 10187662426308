import {
  MultiSelectChangeEvent,
  MultiSelect as PRMultiSelect,
  MultiSelectProps as PRMultiSelectProps,
} from "primereact/multiselect";
import InputLabel from "../Input/InputLabel";
import style from "./MultiSelect.module.scss";

export interface MultiSelectProps extends PRMultiSelectProps {
  locale: {
    label?: string;
    placeholder: string;
  };
  onChangeCallback?: (value: any) => void;
}

const MultiSelect = ({ locale, onChangeCallback, ...props }: MultiSelectProps) => {
  const onChangeHandler = ({ value }: MultiSelectChangeEvent) => {
    onChangeCallback && onChangeCallback(value);
  };
  return (
    <div className={style.container}>
      {locale?.label && <InputLabel locale={locale} required={props.required} />}
      <PRMultiSelect
        className={style.wrapper}
        panelClassName={style.panelWrapper}
        placeholder={locale.placeholder}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  );
};

export default MultiSelect;
