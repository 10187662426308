import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { CommonState } from "./common.slice";
import {
  fetchGetBannersThunk,
  fetchGetCompaniesThunk,
  fetchGetLanguagesThunk,
  fetchGetMenuActivityCountThunk,
  fetchGetMenuThunk,
  fetchGetUserInfoThunk,
} from "./common.thunk";
import { AreaTree, Section } from "./common.models";

export const extraReducers = (
  builder: ActionReducerMapBuilder<CommonState>
) => {
  builder.addCase(fetchGetCompaniesThunk.fulfilled, (state, action) => {
    state.companyList = action.payload;
    state.companyListLoaded = true;
  });
  builder.addCase(fetchGetMenuThunk.fulfilled, (state, action) => {
    state.menuConfiguration = action.payload;
    const activityCount: Record<string, number> = {}
    action.payload.forEach((a: AreaTree) => {
      a.sections.forEach((s: Section) => {
        activityCount[s.code] = s.activityCount
      })
    })
    state.menuActivityCount = activityCount
  });
  builder.addCase(fetchGetMenuActivityCountThunk.fulfilled, (state, action) => {
    const activityCount: Record<string, number> = {}
    action.payload.forEach((a: AreaTree) => {
      a.sections.forEach((s: Section) => {
        activityCount[s.code] = s.activityCount
      })
    })
    state.menuActivityCount = activityCount
  });
  builder.addCase(fetchGetBannersThunk.fulfilled, (state, action) => {
    const alert = action.payload[0];
    state.alertDialog = {
      visible: !!alert?.banner_text,
      closable: alert?.banner_type.toUpperCase() !== "ERROR",
      text: alert?.banner_text,
      code: alert?.code,
    };
  });
  builder.addCase(fetchGetLanguagesThunk.fulfilled, (state, action) => {
    state.languages = action.payload;
    if (!action.payload.length) state.languageLoaded = true;
  });
  builder.addCase(fetchGetUserInfoThunk.fulfilled, (state, action) => {
    state.currentLanguage = action.payload.language;
  });
};
