import style from "./PrimaryTable.module.scss";
import Table, { TableProps } from "../Table";
import { DataTableProps } from "primereact/datatable";

export interface PrimaryTableProps extends TableProps {
  tableProps?: DataTableProps<any>;
}

const PrimaryTable = ({
  columns,
  data,
  tableProps,
  columnExpanderBody,
}: PrimaryTableProps) => {

  const height =
    window.innerWidth > 1024
      ? window.innerHeight - 303
      : window.innerWidth > 767
      ? window.innerHeight - 361
      : window.innerHeight - 380;
  return (
    <Table
      columns={columns}
      data={data}
      filter={true}
      columnExpanderBody={columnExpanderBody}
      customProps={{
        ...tableProps,
        className: style.wrapper,
        scrollable: true,
        scrollHeight: height + "px",
        filterDisplay: "row",
      }}
    />
  );
};

export default PrimaryTable;
