import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import style from "./DocumentPlaceholderDialog.module.scss";
import { Document, Page, pdfjs } from "react-pdf";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GlobalDndContext from "@/dnd-context";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectPlaceholderCoordMap } from "./redux/section.selector";
import {
  resetPlaceholderCoordMap,
  setPlaceholderCoordMap,
} from "./redux/section.slice";
import Button from "../library/Button/Button";
import Paginator, { PaginatorChange } from "../library/Paginator/Paginator";
import { useTranslation } from "react-i18next";
import {
  fetchCreateDocumentPlaceholderslThunk,
  fetchDeleteDocumentPlaceholderslThunk,
  fetchEditDocumentPlaceholderslThunk,
  fetchGetDocumentAttachmentDownloadThunk,
  fetchGetDocumentPlaceholderslThunk,
} from "./redux/section.thunk";
import Spinner from "../library/Spinner/Spinner";

const Shape = ({ id, position, page, isNew, onRemove }: any) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "SHAPE",
      item: { id, position, page, isNew },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [position]
  );

  return (
    <div
      ref={drag}
      className={style.placeholder}
      style={{
        opacity: isDragging ? 0.5 : 1,
        top: position.y,
        left: position.x,
      }}
    >
      <span>{t("UI_PLACEHOLDERS_SIGN")}</span>
      <i className="pi pi-times-circle" onClick={onRemove} />
    </div>
  );
};

const DroppableArea = ({ width, height, pageNumber, readOnly }: any) => {
  const dispatch = useAppDispatch();
  const placeholderCoordMap = useAppSelector(selectPlaceholderCoordMap);

  const [, drop] = useDrop({
    accept: "SHAPE",
    drop: (item: any, monitor) => {
      const delta: any = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.position.x + delta.x);
      const top = Math.round(item.position.y + delta.y);
      const newMap = { ...placeholderCoordMap };
      const newCoordList = [...placeholderCoordMap[pageNumber]];
      const newCoordIndex = newCoordList.findIndex((c) => c.id === item.id);
      const newCoord = { ...newCoordList[newCoordIndex] };
      const newPosition = { ...newCoord.position };
      newPosition.x = left > 0 && left < width ? left : 0;
      newPosition.y = top > 0 && top < height ? top : 0;
      newCoord.position = { ...newPosition };
      newCoord.isEdit = !newCoord.isNew;
      newCoordList[newCoordIndex] = newCoord;
      newMap[pageNumber] = [...newCoordList];
      dispatch(setPlaceholderCoordMap({ ...newMap }));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const onRemove = (id: number) => {
    const newMap = { ...placeholderCoordMap };
    const newCoordList = [...placeholderCoordMap[pageNumber]];
    const newCoordIndex = newCoordList.findIndex((c) => c.id === id);
    newCoordList.splice(newCoordIndex, 1);
    newMap[pageNumber] = newCoordList;
    dispatch(setPlaceholderCoordMap({ ...newMap }));
  };

  return (
    <div
      ref={!readOnly ? drop : null}
      style={{
        position: "absolute",
        width: `${width}px`,
        height: `${height}px`,
        border: "1px solid",
      }}
    >
      {!!placeholderCoordMap[pageNumber] &&
        placeholderCoordMap[pageNumber].map((shape: any, index: number) => (
          <Shape
            key={index}
            position={shape.position}
            id={shape.id}
            page={pageNumber}
            onRemove={onRemove.bind(null, shape.id)}
          />
        ))}
    </div>
  );
};

export const DocumentPlaceholderDialog = (props: {
  companyId: string;
  documentId: string;
  attachmentId: string;
  isVisible: boolean;
  readOnly?: boolean;
  onHide: VoidFunction;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [paginatorFirst, setPaginatorFirst] = useState(0);
  const [base64, setBase64] = useState("");
  const [loading, setLoading] = useState(false);
  const [placeholderList, setPlaceholderList] = useState<any[]>([]);
  const canvasPdfRef = useRef(null);
  const placeholderCoordMap = useAppSelector(selectPlaceholderCoordMap);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setTimeout(() => {
      setCanvasHeight((canvasPdfRef?.current as any)?.height);
      setCanvasWidth((canvasPdfRef?.current as any)?.width);
      getPlacehodlers();
    }, 500);
  };

  const onPageChange = (e: PaginatorChange) => {
    setPaginatorFirst(e.first ?? 0);
    setPageNumber((e.first ?? 0) + 1);
  };

  const addPlaceholder = () => {
    dispatch(
      setPlaceholderCoordMap({
        ...placeholderCoordMap,
        [pageNumber]: [
          ...(placeholderCoordMap[pageNumber] ?? []),
          {
            id: Math.floor(Math.random() * 1000000),
            isNew: true,
            position: { x: 100, y: 500 },
            page: pageNumber.toString()
          },
        ],
      })
    );
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const file2Buffer = (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();

      const readFile = function (event: any) {
        const buffer = reader.result;

        resolve(buffer);
      };

      reader.addEventListener("load", readFile);

      reader.readAsArrayBuffer(file);
    });
  };

  const savePlaceholders = async () => {
    setLoading(true);
    const newCoord: any[] = [],
      editCoord: any[] = [];
    const coord = Object.values(placeholderCoordMap).reduce(
      (acc: any[], curr: any) => {
        return [...acc, ...curr];
      },
      []
    );
    coord.forEach((c: any) => {
      const coord = {
        attachment_id: props.attachmentId,
        page: c.page,
        top: c.position.y,
        left: c.position.x,
        height: canvasHeight,
        width: canvasWidth,
      };
      if (c.isNew) {
        newCoord.push(coord);
        return;
      }
      if (c.isEdit) {
        editCoord.push({ ...coord, id: c.id });
        return;
      }
    });
    const deleteCoord: string[] = placeholderList
      .filter((p: any) => !coord.find((pc: any) => pc.id === p.id))
      .map((p) => p.id);
    if (newCoord.length > 0) {
      await dispatch(
        fetchCreateDocumentPlaceholderslThunk({
          document_id: props.documentId,
          company_id: props.companyId,
          section_id: window.location.pathname.split("/")[2] || "",
          data: newCoord,
        })
      );
    }
    if (editCoord.length > 0) {
      await dispatch(
        fetchEditDocumentPlaceholderslThunk({
          document_id: props.documentId,
          company_id: props.companyId,
          section_id: window.location.pathname.split("/")[2] || "",
          data: editCoord,
        })
      );
    }
    if (deleteCoord.length > 0) {
      await dispatch(
        fetchDeleteDocumentPlaceholderslThunk({
          document_id: props.documentId,
          company_id: props.companyId,
          section_id: window.location.pathname.split("/")[2] || "",
          ids: deleteCoord,
        })
      );
    }
    getPlacehodlers();
  };

  const getPlacehodlers = async () => {
    const response = await dispatch(
      fetchGetDocumentPlaceholderslThunk({
        attachmentId: props.attachmentId,
        document_id: props.documentId,
        company_id: props.companyId,
        section_id: window.location.pathname.split("/")[2] || "",
      })
    );
    setPlaceholderList(response.payload.data);
    const coordMap = response.payload.data.reduce(
      (acc: Record<string, any[]>, curr: any) => {
        return {
          ...acc,
          [curr.page]: [
            ...(acc[curr.page] ?? []),
            {
              id: curr.id,
              position: { x: curr.left, y: curr.top },
              page: curr.page,
            },
          ],
        };
      },
      {}
    );
    dispatch(setPlaceholderCoordMap(coordMap));
    setLoading(false);
  };

  const onHideHandler = () => {
    dispatch(resetPlaceholderCoordMap());
    if (props.onHide) {
      props.onHide();
    }
  };

  useEffect(() => {
    const getFile = async () => {
      const response = await dispatch(
        fetchGetDocumentAttachmentDownloadThunk({
          attachmentId: props.attachmentId,
          document_id: props.documentId,
          company_id: props.companyId,
          section_id: window.location.pathname.split("/")[2] || "",
        })
      );
      try {
        const buffer = await file2Buffer(response.payload);
        var base64 = btoa(
          new Uint8Array(buffer as ArrayBufferLike).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setBase64(base64);
      } catch (error) {
        console.log("File error");
      } finally {
        setLoading(false);
      }
    };
    getFile();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Dialog
      style={{ width: "80vw" }}
      className={style.container}
      header={() => (
        <div className={style.pdfPaginator}>
          <div className={style.pdfPaginator__left}>
            <Button
              label={t("UI_PLACEHOLDERS_ADD_SIGN")}
              onClick={addPlaceholder}
              disabled={props.readOnly}
            />
            <Button
              label={t("UI_PLACEHOLDERS_SAVE")}
              onClick={savePlaceholders}
              isLoading={loading}
              disabled={props.readOnly}
            />
          </div>
          <Paginator
            rows={1}
            totalRecords={numPages}
            first={paginatorFirst}
            onPageChange={onPageChange}
          />
        </div>
      )}
      headerClassName={style.header}
      visible={props.isVisible}
      onHide={onHideHandler}
      closeIcon={<i className="pi pi-times-circle" />}
      position="top"
      draggable={false}
    >
      {!!base64 && (
        <div className={style.pdfContainer}>
          <Document
            className={style.pdfDocument}
            file={`data:application/pdf;base64,${base64}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              className={style.pdfPage}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              canvasRef={canvasPdfRef}
            >
              <GlobalDndContext backend={HTML5Backend}>
                <DroppableArea
                  width={canvasWidth}
                  height={canvasHeight}
                  pageNumber={pageNumber}
                  readOnly={props.readOnly}
                />
                {loading && (
                  <div
                    className={style.pdfSpinner}
                    style={{
                      width: `${canvasWidth}px`,
                      height: `${canvasHeight}px`,
                    }}
                  >
                    <div className={style.pdfSpinner__content}>
                      <Spinner />
                    </div>
                  </div>
                )}
              </GlobalDndContext>
            </Page>
          </Document>
        </div>
      )}
    </Dialog>
  );
};
