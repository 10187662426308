import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SECTION_NAMESPACE } from "./section.selector";
import { extraReducers } from "./section.extraReducer";
import {
  Field,
  ICertificate,
  IDocumentAttachment,
  IHistory,
} from "./section.models";
import { PAGE_SIZE } from "./section.constants";

export interface SectionState {
  configuration: Field[];
  documents: any[];
  documentWorkflow: any[];
  selectedDocument: { id: string };
  loading: boolean;
  documentDetails: any;
  documentStepsLoading: boolean;
  documentDetailsLoading: boolean;
  documentAttachment: IDocumentAttachment[];
  paginator: {
    page: number;
    pageCount: number;
    total: number;
    reload: boolean;
    first: number;
    size: number;
  };
  docFilters: Record<string, any>[];
  actionDialogLoading: boolean;
  certificates: ICertificate[];
  documentHistory: { instanceCode: string; history: IHistory[] };
  hasAttachmentsPermission: boolean;
  loadingConfiguration: boolean;
  massiveActionDocuments: any[];
  columnTranslationsMap: Record<string, string>;
  placeholderCoordMap: Record<string, any>;
  initializationFormList: any[];
  documentInstanceCode: string
}

const initialState: SectionState = {
  configuration: [],
  documents: [],
  documentWorkflow: [],
  selectedDocument: { id: "" },
  documentDetails: {},
  documentDetailsLoading: false,
  documentStepsLoading: false,
  loading: false,
  documentAttachment: [],
  paginator: {
    page: 1,
    pageCount: 0,
    total: 0,
    reload: false,
    first: 1,
    size: PAGE_SIZE,
  },
  docFilters: [],
  actionDialogLoading: false,
  certificates: [],
  documentHistory: { instanceCode: "", history: [] },
  hasAttachmentsPermission: false,
  loadingConfiguration: false,
  massiveActionDocuments: [],
  columnTranslationsMap: {},
  placeholderCoordMap: {},
  initializationFormList: [],
  documentInstanceCode: ''
};

export const sectionSlice = createSlice({
  name: SECTION_NAMESPACE,
  initialState,
  reducers: {
    setSelectedDocument: (
      state: SectionState,
      action: PayloadAction<{ id: string }>
    ) => {
      state.selectedDocument = action.payload;
    },
    setDocumentInstanceCode: (
      state: SectionState,
      action: PayloadAction<string>
    ) => {
      state.documentInstanceCode = action.payload;
    },
    setDocumentAttachment: (
      state: SectionState,
      action: PayloadAction<IDocumentAttachment[]>
    ) => {
      state.documentAttachment = action.payload;
    },
    setDocFilters: (
      state: SectionState,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.docFilters = action.payload;
    },
    setPaginator: (
      state: SectionState,
      action: PayloadAction<{
        page: number;
        pageCount: number;
        total: number;
        reload: boolean;
        first: number;
        size: number;
      }>
    ) => {
      state.paginator = action.payload;
    },
    setActionDialogLoading: (
      state: SectionState,
      action: PayloadAction<boolean>
    ) => {
      state.actionDialogLoading = action.payload;
    },
    setHasAttachmentsPermission: (
      state: SectionState,
      action: PayloadAction<boolean>
    ) => {
      state.hasAttachmentsPermission = action.payload;
    },
    setDocumentWorkflow: (
      state: SectionState,
      action: PayloadAction<any[]>
    ) => {
      state.documentWorkflow = action.payload;
    },
    setMassiveActionDocuments: (
      state: SectionState,
      action: PayloadAction<any[]>
    ) => {
      state.massiveActionDocuments = action.payload;
    },
    setDocuments: (state: SectionState, action: PayloadAction<any[]>) => {
      state.documents = action.payload;
    },
    setColumnTranslationsMap: (
      state: SectionState,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.columnTranslationsMap = {
        ...state.columnTranslationsMap,
        ...action.payload,
      };
    },
    resetColumnTranslationsMap: (state: SectionState) => {
      state.columnTranslationsMap = {};
    },
    setPlaceholderCoordMap: (
      state: SectionState,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.placeholderCoordMap = {
        ...state.placeholderCoordMap,
        ...action.payload,
      };
    },
    resetPlaceholderCoordMap: (state: SectionState) => {
      state.placeholderCoordMap = {};
    },
    setLoading: (state: SectionState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setConfiguration: (state: SectionState, action: PayloadAction<Field[]>) => {
      state.configuration = [...action.payload];
    },
  },
  extraReducers,
});

export const {
  setSelectedDocument,
  setDocumentAttachment,
  setDocFilters,
  setPaginator,
  setActionDialogLoading,
  setHasAttachmentsPermission,
  setDocumentWorkflow,
  setMassiveActionDocuments,
  setDocuments,
  setColumnTranslationsMap,
  resetColumnTranslationsMap,
  setPlaceholderCoordMap,
  resetPlaceholderCoordMap,
  setLoading,
  setConfiguration,
  setDocumentInstanceCode
} = sectionSlice.actions;

export default sectionSlice.reducer;
