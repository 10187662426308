import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { SectionState } from "./section.slice";
import {
  fetchGetConfigurationThunk,
  fetchGetDocumentDetailsThunk,
  fetchGetDocumentsThunk,
  fetchGetDocumentWorkflowThunk,
  fetchGetDocumentAttachmentThunk,
  fetchGetCertificatesThunk,
  fetchGetDocumentHistoryThunk,
  fetchBumpStatusThunk,
  fetchEditDocumentDetailThunk,
  fetchGetInitializationFormListThunk,
} from "./section.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<SectionState>
) => {
  builder
    .addCase(fetchGetConfigurationThunk.pending, (state) => {
      state.loadingConfiguration = true;
      state.configuration = [];
    })
    .addCase(fetchGetConfigurationThunk.fulfilled, (state, action) => {
      state.configuration = action.payload;
      state.loadingConfiguration = false;
    })
    .addCase(fetchGetConfigurationThunk.rejected, (state) => {
      state.loadingConfiguration = false;
    })
    .addCase(fetchGetInitializationFormListThunk.fulfilled, (state, action) => {
      state.initializationFormList = action.payload;
      state.loadingConfiguration = false;
    });
  builder
    .addCase(fetchGetDocumentsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchGetDocumentsThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(fetchGetDocumentsThunk.fulfilled, (state, action) => {
      state.documents = action.payload.data;
      state.paginator = {
        page: action.payload.page,
        pageCount: action.payload.pageCount,
        total: action.payload.total,
        reload: false,
        first: state.paginator.size * (action.payload.page - 1) || 1,
        size: state.paginator.size
      };
      state.loading = false;
    });
  builder.addCase(fetchGetDocumentWorkflowThunk.pending, (state) => {
    state.documentStepsLoading = true;
  });
  builder.addCase(fetchGetDocumentWorkflowThunk.fulfilled, (state, action) => {
    state.documentWorkflow = action.payload;
    state.documentStepsLoading = false;
  });
  builder.addCase(fetchBumpStatusThunk.pending, (state) => {
    state.documentDetailsLoading = true;
  });
  builder.addCase(fetchGetDocumentDetailsThunk.pending, (state) => {
    state.documentDetailsLoading = true;
  });
  builder.addCase(fetchEditDocumentDetailThunk.pending, (state) => {
    state.documentDetailsLoading = true;
  });
  builder.addCase(fetchGetDocumentDetailsThunk.fulfilled, (state, action) => {
    state.documentDetails = action.payload;
    state.documentInstanceCode = action.payload.document_metadata[0].children[0].value
    state.documentDetailsLoading = false;
  });
  builder.addCase(fetchBumpStatusThunk.fulfilled, (state) => {
    state.documentDetailsLoading = false;
  });
  builder.addCase(fetchBumpStatusThunk.rejected, (state) => {
    state.documentDetailsLoading = false;
  });
  builder.addCase(
    fetchGetDocumentAttachmentThunk.fulfilled,
    (state, action) => {
      state.documentAttachment = action.payload;
    }
  );
  builder.addCase(fetchGetCertificatesThunk.fulfilled, (state, action) => {
    state.certificates = action.payload;
  });
  builder.addCase(fetchGetDocumentHistoryThunk.fulfilled, (state, action) => {
    state.documentHistory = action.payload;
  });
};
