import { Dialog, DialogProps } from "primereact/dialog";
import style from "./DocumentAttachmentsUploadDialog.module.scss";
import Dropdown from "../library/Dropdown/Dropdown";
import { useState } from "react";
import InputLabel from "../library/Input/InputLabel";
import Button from "../library/Button/Button";

export interface DocumentAttachmentsUploadDialogProps extends DialogProps {
  categories: any[];
  onUpload: ({
    e,
    attachmentFolderId,
  }: {
    e: any;
    attachmentFolderId: string;
  }) => Promise<any>;
}

export const DocumentAttachmentsUploadDialog = ({
  categories,
  onUpload,
  ...props
}: DocumentAttachmentsUploadDialogProps) => {
  const [category, setCategory] = useState("");
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);

  const customFooter = (
    <div className={style.customFooterContainer}>
      <div>
        <Button label={"Annulla"} onClick={props.onHide} secondaryMode />
      </div>
      <div>
        <Button
          style={{ marginRight: "0px" }}
          label={"Carica file"}
          onClick={async () => {
            setLoading(true);
            await onUpload({ e: file, attachmentFolderId: category });
            setLoading(false);
          }}
          isLoading={loading}
          disabled={!category || !file}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      style={{ width: "45vw" }}
      className={style.container}
      headerClassName={style.header}
      {...props}
      header="Allega documento"
      footer={customFooter}
      closeIcon={<i className="pi pi-times-circle" />}
    >
      <div className={style.inputWrapper}>
        <Dropdown
          locale={{ label: "Seleziona categoria", placeholder: "Seleziona..." }}
          options={categories}
          value={category}
          onChangeCallback={setCategory}
          optionLabel="label"
          optionValue="value"
          optionDisabled={(value) => !value.editable}
        />
      </div>
      <div className={style.inputWrapper}>
        <InputLabel locale={{ label: "Scegli file" }} />
        <div style={{ marginTop: "8px" }}>
          <label className={style.inputFile} htmlFor="attachment">
            <i className="pi pi-upload" />
            <span>{file ? file?.target.files[0].name : "Scegli file..."}</span>
          </label>
        </div>
        <input
          id="attachment"
          style={{ visibility: "hidden" }}
          type="file"
          onChange={(e) => setFile(e)}
        />
      </div>
    </Dialog>
  );
};
