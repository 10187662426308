import { useTranslation } from "react-i18next";
import style from "./HistoryCard.module.scss";

export const HistoryCard = (props: {
  leftActionLabel: string;
  actionTypeLabel: string;
  executionerLabel: string;
  endDate: string;
  versionLabel: string;
  note: string;
  onVersion: VoidFunction;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.container}>
        {/* <div className={style.leftContainer}>
          <p>{props.leftActionLabel}</p>
        </div> */}
        <div className={style.centerContainer}>
          {/* <div className={style.divider}></div> */}
          <div className={style.infoContainer}>
            <p className={style.subtitle}>{t("UI_HISTORY_ACTION")}:</p>
            <p className={style.infoValue}>{props.actionTypeLabel}</p>
          </div>
          <div className={style.infoContainer}>
            <p className={style.subtitle}>{t("UI_HISTORY_EXECUTOR")}:</p>
            <p className={style.infoValue}>{props.executionerLabel}</p>
          </div>
          <div className={style.infoContainer}>
            <p className={style.subtitle}>{t("UI_HISTORY_DATE")}:</p>
            <p className={style.infoValue}>{`${new Date(
              props.endDate
            ).toLocaleDateString()} ${new Date(
              props.endDate
            ).getHours()}:${new Date(
              props.endDate
            ).getMinutes()}:${new Date(props.endDate).getSeconds()}`}</p>
          </div>
          {!!props.note && (
            <div className={style.infoContainer}>
              <p className={style.subtitle}>{t("UI_HISTORY_NOTE")}:</p>
              <p className={style.infoValue}>{props.note}</p>
            </div>
          )}
        </div>

        <div className={style.rightContainer}>
          <div className={style.buttonContainer}>
            <button onClick={props.onVersion}>
              <p>V{props.versionLabel}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
